import React, { useState, useEffect } from 'react'
import { Grid, Box, IconButton, Button, Modal } from '@mui/material'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
// import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp'
// import RemoveCircleSharpIcon from '@mui/icons-material/RemoveCircleSharp'
// import ArrowUpwardSharpIcon from '@mui/icons-material/ArrowUpwardSharp'
// import ArrowDownwardSharpIcon from '@mui/icons-material/ArrowDownwardSharp'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import { invalidName, cancelAction } from '../functions/general'
import { getDomains, getCollections } from '../functions/getData'
import {
   patchCollectionSettings, patchCollectionRecommendations,
   patchCollectionSections
} from '../functions/patchData'
import { deleteSection } from '../functions/deleteData'
import { gradeOptions } from '../functions/general'
import { showWarningSwal } from '../functions/alert'
// import { getBase64 } from '../functions/upload'
import { showNotification } from '../functions/snackbar'
import { afterEdit, populateCollection, deepCopy, isUnchanged } from '../functions/edit'
import AddTaskToSectionModal from '../components/Collections/AddTaskToSectionModal'
import DeleteModal from '../components/modal/DeleteModal'
import NunitoText from '../components/general/NunitoText'
import ReusableButton from '../components/general/ReusableButton'
import ReusableTextField from '../components/general/ReusableTextField'
// import ReusableDatePicker from '../components/general/ReusableDatePicker'
import ReusableToggle from '../components/general/ReusableToggle'
import ReusableAutocompleteWithID from '../components/general/ReusableAutocompleteWithID'
// import ReusableCheckbox from '../components/general/ReusableCheckbox'
import TitleBar from '../components/general/TitleBar'
// import Solution from '../components/Solution'
import DescriptionEditor from '../components/DescriptionEditor'
import AddPageWrapper from '../components/general/AddPageWrapper'
import InputContainer from '../components/general/InputContainer'
import Loader from '../components/general/Loader'
import NoData from '../components/general/NoData'
import LightTooltip from '../components/general/LightTooltip'
import { useSnackbar } from 'notistack'
const collectionContainerStyle = { backgroundColor: '#F3F0F4', borderRadius: '0 0 12px 12px', paddingBottom: 20 }
const difficultyOptions = ['Easy', 'Intermediate', 'Hard', 'Advanced'].map((d, i) => ({ id: d, name: d }))
const recommendationContainerStyle = index => ({
   marginTop: index ? 10 : 40, marginBottom: 10
})
const iconWrapperStyle = { display: 'flex', alignItems: 'center', justifyContent: 'center' }
const iconStyle = { fontSize: 36, color: '#000', cursor: 'pointer' }
const containerStyle = { backgroundColor: '#FFF', border: '1px solid #707070', borderRadius: 16, paddingBlock: 20 }
const gradeOrDifficultyWrapperStyle = {
   display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingInline: '30px 60px'
}
const buttonContainerStyle = { marginRight: 20, marginBlock: '10px 20px' }
const GroupList = SortableContainer(({ tasks, setOnAddTask, updateSection, count, setGroupCount, setTaskCount }) => {
   const divStyle = { backgroundColor: 'lightgray', display: 'contents', width: 1460, border: '1px solid red' }
   return (
      <div style={divStyle}>
         {tasks.map((task, index) => (
            <Group key={index} index={index} value={task} count={count} groupIndex={index}
               setOnAddTask={setOnAddTask} setGroupCount={setGroupCount}
               setTaskCount={setTaskCount} updateSection={updateSection} />
         ))}
      </div>
   )
})
const Group = SortableElement(({ value, count, groupIndex, setOnAddTask, setGroupCount, setTaskCount, updateSection }) => {
   const wrapperStyle = { display: 'flex', alignItems: 'center', marginTop: 10, marginRight: 10, height: 80 }
   const divStyle = {
      border: '1px solid gray', borderRadius: 12, boxShadow: '0 0 3px #9E9E9E',
      backgroundColor: '#F2F2F2', display: 'flex'
   }
   const cancelStyle = {
      fontSize: 28, color: '#FFF', backgroundColor: '#E83D4D', cursor: 'pointer', borderRadius: 16,
      border: '1px solid gray', padding: 3,
      marginLeft: -12, position: 'relative', bottom: value.length > 1 ? 30 : 20
   }
   return (
      <div style={wrapperStyle}>
         <div style={value.length > 1 ? divStyle : { display: 'flex' }}>
            <ItemList task={value} axis='x' pressDelay={200} groupIndex={groupIndex}
               setOnAddTask={setOnAddTask} updateSection={updateSection} count={count}
               setGroupCount={setGroupCount} setTaskCount={setTaskCount}
               onSortEnd={(e) => updateSection([count, groupIndex], 'sortTask', e)} />
         </div>
         <CloseIcon onMouseDown={e => e.stopPropagation()} style={cancelStyle}
            onClick={() => updateSection(count, 'deleteTaskGroup', groupIndex)}
         />
         <IconButton style={{ height: 24, width: 24 }} onClick={() => {
            setGroupCount(groupIndex)
            setOnAddTask(true)
         }}>
            <AddCircleIcon style={{ fontSize: 28 }} onMouseDown={e => e.stopPropagation()} />
         </IconButton>
      </div>
   )
})
const ItemList = SortableContainer(({ task, groupIndex, setOnAddTask, updateSection, count, setGroupCount, setTaskCount }) => {
   const containerStyle = { display: 'flex', alignItems: 'center' }
   return (
      <div style={containerStyle}>
         {task.map((value, index) =>
            <Item key={`item-${index}`} index={index} value={value}
               groupIndex={groupIndex} taskIndex={index} length={task.length} setOnAddTask={setOnAddTask}
               updateSection={updateSection} count={count} setGroupCount={setGroupCount} setTaskCount={setTaskCount} />
         )}
      </div>
   )
})
const Item = SortableElement(({ value, groupIndex, taskIndex, length, setOnAddTask, updateSection, count, setGroupCount, setTaskCount }) => {
   const taskBtnStyle = {
      borderRadius: 10, height: 50, textTransform: 'none', backgroundColor: '#A9ABD3', color: '#FFF',
      marginBlock: 10, marginInline: length > 1 && `${!taskIndex ? '15px' : '0'} 15px`, paddingInline: 20
   }
   return (
      <LightTooltip title={<Link to={`/edittask?id=${value.id}`} target='_blank'>{value.task_title}</Link>} placement='bottom-start'>
         <Button style={taskBtnStyle}
            endIcon={
               <>
                  {taskIndex > 0 && <IconButton style={{ width: 26 }} onClick={() =>
                     updateSection([count, groupIndex], 'deleteTaskFromGroup', taskIndex)}>
                     <RemoveCircleIcon style={{ color: '#FFF' }} onMouseDown={e => e.stopPropagation()} />
                  </IconButton>}
                  <IconButton style={{ width: 26 }} onClick={() => {
                     setTaskCount(taskIndex)
                     setGroupCount(groupIndex)
                     setOnAddTask(true)
                  }}>
                     <AddCircleIcon style={{ color: '#FFF' }} onMouseDown={e => e.stopPropagation()} />
                  </IconButton>
               </>}>
            Task {value.id}
         </Button>
      </LightTooltip>
   )
})
const tasksContainerStyle = {
   margin: 32, backgroundColor: '#FFF', padding: '10px 32px',
   border: '1px solid gray', borderRadius: 10
}
const saveButtonContainerStyle = { margin: ' 0 30px 30px 30px' }
const Section = ({ count, original, setOriginal, collection, setCollection, updateSection, openDeleteModal }) => {
   const [expand, setExpand] = useState(false)
   const [onAddTask, setOnAddTask] = useState(false)
   const [groupCount, setGroupCount] = useState('')
   const [taskCount, setTaskCount] = useState('')
   const section = collection.sections[count]
   const { id, description, tasks, allow_skip, sort_randomly } = section
   const cancelAddTask = () => {
      setOnAddTask(false)
      setGroupCount('')
      setTaskCount('')
   }
   const removeUnsavedSection = () => {
      let newCollection = window.structuredClone(collection)
      newCollection.sections = newCollection.sections.filter((s, i) => i !== count)
      setCollection(newCollection)
   }
   const onSubmit = () => {
      let payload = {
         collection_id: collection.id,
         section_id: id,
         section: {
            groups: tasks.map(grp => ({ task_id: grp.map(t => t.id) })),
            sort_randomly,
            allow_skip,
            description
         }
      }
      console.log(payload)
   }
   return (
      <>
         <TitleBar title={`Section ${count + 1}${id === '' ? ' - unsaved' : ''}`} state={expand} setState={setExpand}>
            <ReusableButton text='Delete Section' height={46} bgColor='#E83042' fontSize={16}
               marginRight={20} onClick={() => id === '' ? removeUnsavedSection() : openDeleteModal(id)} iconType='delete' />
         </TitleBar>
         {expand && <AddPageWrapper>
            <Grid container style={tasksContainerStyle}>
               <Grid item xs={12}>
                  <ReusableButton text='Add Task' bgColor='#5E75C3' fontSize={14} iconType='add'
                     onClick={() => setOnAddTask(true)} />
               </Grid>
               <Grid container>
                  <GroupList tasks={tasks} setOnAddTask={setOnAddTask} updateSection={updateSection} count={count}
                     setGroupCount={setGroupCount} setTaskCount={setTaskCount}
                     axis='xy' pressDelay={200} onSortEnd={e => updateSection(count, 'sortTaskGroup', e)} />
               </Grid>
            </Grid>
            <InputContainer first label='Sort Randomly'
               field={<ReusableToggle type='icon' width={124} state={sort_randomly} setState={updateSection}
                  count={count} target='sort_randomly' />} />
            <InputContainer label='Skip'
               field={<ReusableToggle type='icon' width={124} state={allow_skip} setState={updateSection}
                  count={count} target='allow_skip' />} />
            <InputContainer label='Total Tasks'
               field={<ReusableTextField type='immutableDisplay' width={240} placeholder='Total Tasks'
                  state={tasks.flat().length} grayedOut readOnly />} />
            <InputContainer last label='Description'
               field={<DescriptionEditor state={description} setState={updateSection} height={400} placeholder='Description'
                  count={count} target='description' />} />
            <Grid container justifyContent="flex-end" style={saveButtonContainerStyle}>
               <ReusableButton text='Save' bgColor='#5E75C3' fontSize={16} onClick={() => onSubmit()} />
            </Grid>
         </AddPageWrapper>}
         <Modal open={onAddTask} onClose={() => cancelAddTask()}>
            <>
               <AddTaskToSectionModal collection={collection} section={section} count={count} sectionCount={count}
                  groupCount={groupCount} taskCount={taskCount} update={updateSection} cancelAddTask={cancelAddTask} edit />
            </>
         </Modal>
      </>
   )
}
const Sections = ({ original, setOriginal, collection, setCollection }) => {
   const [expand, setExpand] = useState(false)
   const [onDelete, setOnDelete] = useState(false)
   const [id, setId] = useState('')
   const cancelDelete = () => cancelAction(setOnDelete, setId)
   const openDeleteModal = id => {
      setId(id)
      setOnDelete(true)
   }
   const { sections } = collection
   const updateSection = (count, target, value) => {
      let newCollection = { ...collection }
      switch (target) {
         case 'justRemoveTask':
            for (let i = 0; i < newCollection.sections.length; i++) {
               const section = newCollection.sections[i]
               for (let j = 0; j < section.tasks.length; j++) {
                  newCollection.sections[i].tasks[j] =
                     newCollection.sections[i].tasks[j].filter(task => !value.includes(task.id))
               }
               newCollection.sections[i].tasks =
                  newCollection.sections[i].tasks.filter(tasks => tasks.length)
            }
            break;
         case 'addTaskGroup':
            value.reverse().forEach(task => {
               newCollection.sections[count[0]].tasks.splice(count[1], 0, [{ id: task.id, task_title: task.title }])
            })
            break;
         case 'addTaskToGroup':
            value.reverse().forEach(task => {
               let obj = { id: task.id, task_title: task.title }
               newCollection.sections[count[0]].tasks[count[1]].splice(count[2], 0, obj)
            })
            break;
         case 'deleteTaskGroup':
            newCollection.sections[count].tasks.splice(value, 1)
            break;
         case 'deleteTaskFromGroup':
            newCollection.sections[count[0]].tasks[count[1]].splice(value, 1)
            break;
         case 'sortTaskGroup':
            let oldTaskGrp = newCollection.sections[count].tasks[Number(value.oldIndex)]
            newCollection.sections[count].tasks[Number(value.oldIndex)] = newCollection.sections[count].tasks[Number(value.newIndex)]
            newCollection.sections[count].tasks[Number(value.newIndex)] = oldTaskGrp
            break;
         case 'sortTask':
            let oldTask = newCollection.sections[count[0]].tasks[count[1]][value.oldIndex]
            newCollection.sections[count[0]].tasks[count[1]][value.oldIndex] =
               newCollection.sections[count[0]].tasks[count[1]][value.newIndex]
            newCollection.sections[count[0]].tasks[count[1]][value.newIndex] = oldTask
            break;
         case 'add':
            newCollection.sections.splice(count + 1, 0, {
               id: '', description: '', tasks: [], allow_skip: false, sort_randomly: false
            })
            break;
         case 'remove':
            newCollection.sections.splice(count, 1)
            break;
         default: newCollection.sections[count][target] = value
      }
      setCollection(newCollection)
   }
   const { enqueueSnackbar, closeSnackbar } = useSnackbar()
   const deleteFunction = () => {
      let payload = { collection_id: collection.id, id: [id] }
      deleteSection(payload).then(d => {
         if (d.status === 200) showNotification('success', d.message, enqueueSnackbar, closeSnackbar)
      }).catch(e => console.log(e)).finally(() => {
         afterEdit(getCollections, populateCollection, setOriginal, collection, setCollection)
      })
   }
   return (
      <>
         <TitleBar title='Sections' state={expand} setState={setExpand}>
            <ReusableButton text='New Section' height={46} bgColor='#5E75C3' fontSize={16} marginRight={40}
               onClick={() => updateSection(sections.length, 'add')} iconType='add' />
         </TitleBar>
         {expand && <AddPageWrapper>
            <Grid container style={{ paddingBottom: 20 }}>
               {sections.map((section, index) => (
                  <Section key={section.id} count={index} original={original} setOriginal={setOriginal}
                     collection={collection} setCollection={setCollection} updateSection={updateSection}
                     openDeleteModal={openDeleteModal} />
               ))}
            </Grid>
         </AddPageWrapper>}
         <Modal open={onDelete} onClose={() => cancelDelete()}>
            <>
               <DeleteModal table={{ data: sections.map((s, i) => ({ ...s, name: `Section ${i + 1}` })) }} id={id} selected={[]}
                  deleteFunction={deleteFunction} cancelDelete={cancelDelete} />
            </>
         </Modal>
      </>
   )
}
const Recommendations = ({ original, setOriginal, collection, setCollection }) => {
   const [expand, setExpand] = useState(false)
   const [loading, setLoading] = useState(false)
   const { id, grade_difficulty } = collection
   const { enqueueSnackbar, closeSnackbar } = useSnackbar()
   const updateRecommendation = (count, target, value) => {
      let newCollection = { ...collection }
      if (target === 'add') {
         newCollection.grade_difficulty.splice(count + 1, 0, { grade: '', difficulty: '' })
      } else if (target === 'remove') {
         newCollection.grade_difficulty.splice(count, 1)
      } else {
         newCollection.grade_difficulty[count][target] = value
      }
      setCollection(newCollection)
   }
   const onSubmit = () => {
      if (isUnchanged(original, collection)) return showWarningSwal('Please make some changes before submitting')
      let msg = ''
      if (grade_difficulty.map(r => r.grade).includes('')) {
         msg += 'Please fill in all grades fields or remove recommended grade.<br>'
      }
      if (grade_difficulty.map(r => r.difficulty).includes('')) {
         msg += 'Please fill in all difficulty fields or remove recommended difficulty.<br>'
      }
      if (msg) return showWarningSwal(msg)
      setLoading(true)
      let payload = {
         collection_id: id,
         recommendations: grade_difficulty.map(r => ({ grade: r.grade, difficulty: r.difficulty }))
      }
      patchCollectionRecommendations(payload).then(d => {
         if (d.status === 200) showNotification('success', d.message, enqueueSnackbar, closeSnackbar)
      }).catch(e => console.log(e)).finally(() => {
         afterEdit(getCollections, populateCollection, setOriginal, collection, setCollection)
         setLoading(false)
      })
   }
   return (
      <>
         <TitleBar title='Recomendations' state={expand} setState={setExpand}>
            <ReusableButton text='Add Recommendation' fontSize={16} bgColor='#5E75C3' marginRight={40}
               onClick={() => updateRecommendation(grade_difficulty.length, 'add')} iconType='add' />
         </TitleBar>
         {expand && <AddPageWrapper>
            {loading ? <Loader height={600} /> : grade_difficulty.map(({ grade, difficulty }, index) => (
               <Grid key={index} container alignItems="center" style={recommendationContainerStyle(index)}>
                  <Grid item xs={0.5} style={iconWrapperStyle}>
                     <IconButton onClick={() => updateRecommendation(index, 'remove')}>
                        <CloseIcon style={iconStyle} />
                     </IconButton>
                  </Grid>
                  <Grid item xs={11}>
                     <Grid container style={containerStyle}>
                        <Grid item xs={6} style={gradeOrDifficultyWrapperStyle}>
                           <NunitoText value='Recommended Grade*:' fontSize={20} marginBottom={10} fontWeight={600} italic />
                           <ReusableAutocompleteWithID type='default' placeholder='Grade' width='100%' bgColor='#F2F2F2'
                              btnBgColor='#5E75C3' btnWidth={56} state={grade} setState={updateRecommendation}
                              count={index} required target='grade'
                              options={gradeOptions.map(g => ({ id: g.id, option: g.name }))} />
                        </Grid>
                        <Grid item xs={6} style={gradeOrDifficultyWrapperStyle}>
                           <NunitoText value='Recommended Difficulty*:' fontSize={20} marginBottom={10} fontWeight={600} italic />
                           <ReusableAutocompleteWithID type='default' placeholder='Difficulty' width='100%' bgColor='#F2F2F2'
                              btnBgColor='#5E75C3' btnWidth={56} state={difficulty} setState={updateRecommendation}
                              count={index} required target='difficulty'
                              options={difficultyOptions.map(d => ({ id: d.id, option: d.name }))} />
                        </Grid>
                     </Grid>
                  </Grid>
                  <Grid item xs={0.5} style={iconWrapperStyle}>
                     <IconButton onClick={() => updateRecommendation(index, 'add')}>
                        <AddIcon style={iconStyle} />
                     </IconButton>
                  </Grid>
               </Grid>
            ))}
            <Grid container justifyContent='flex-end' style={buttonContainerStyle}>
               <ReusableButton text='Save' bgColor='#5E75C3' width={140} height={50} fontSize={16} onClick={() => onSubmit()} />
            </Grid>
         </AddPageWrapper>}
      </>
   )
}
const Collection = ({ original, setOriginal, collection, setCollection, tagOptions }) => {
   const [expandCollection, setExpandCollection] = useState(false)
   const [expandSetting, setExpandSetting] = useState(false)
   const [loading, setLoading] = useState(false)
   const { id, name, identifier, time_to_solve, initial_points, tags, description } = collection
   const { enqueueSnackbar, closeSnackbar } = useSnackbar()
   const updateCollection = (target, value) => {
      let newCollection = { ...collection }
      newCollection[target] = value
      if (target === 'name') {
         newCollection.identifier = value.toLowerCase().trim().replace(/\s/g, '_')
      }
      setCollection(newCollection)
   }
   const onSubmit = () => {
      if (isUnchanged(original, collection)) return showWarningSwal('Please make some changes before submitting')
      let warningMessage = ''
      if (invalidName(name)) warningMessage += 'Invalid Collection Name.<br>'
      if (invalidName(identifier)) warningMessage += 'Invalid Collection Identifier.<br>'
      if (isNaN(time_to_solve) || !time_to_solve) {
         warningMessage += 'Invalid Time to Solve(Must be a number greater than 0).<br>'
      }
      if (isNaN(initial_points) || !initial_points.toString().length) {
         warningMessage += 'Invalid Initial Points(Must be a number).<br>'
      }
      if (warningMessage) return showWarningSwal(warningMessage)
      actuallySubmit()
   }
   const actuallySubmit = () => {
      let payload = {
         collection_id: id,
         settings: {
            time_to_solve,
            initial_points,
            tags,
            description
         }
      }
      if (name.toLowerCase() !== original.name.toLowerCase()) payload.settings.name = name
      if (identifier.toLowerCase() !== original.identifier.toLowerCase()) payload.settings.identifier = identifier
      console.log(JSON.stringify(payload))
      setLoading(true)
      patchCollectionSettings(payload).then(d => {
         console.log(d)
         if (d.status === 200) showNotification('success', d.message, enqueueSnackbar, closeSnackbar)
      }).catch(e => console.log(e)).finally(() => {
         setLoading(false)
         afterEdit(getCollections, populateCollection, setOriginal, collection, setCollection)
      })
   }
   return (
      <>
         <TitleBar title={collection.identifier} state={expandCollection} setState={setExpandCollection} main />
         {expandCollection && <Grid container style={collectionContainerStyle}>
            <TitleBar title='Settings' state={expandSetting} setState={setExpandSetting} />
            {loading ? <Loader height={600} /> : <>
               {expandSetting && <AddPageWrapper>
                  <InputContainer first label='Collection Name*'
                     field={<ReusableTextField type='default' bgColor='#F2F2F2' placeholder='Collection Name*'
                        state={name} setState={updateCollection}
                        head='settingText' target='name' required onBlur fullWidth />} />
                  <InputContainer label='Collection Identifier*'
                     field={<ReusableTextField type='default' bgColor='#F2F2F2' placeholder='Collection Identifier*'
                        state={identifier} setState={updateCollection}
                        head='settingText' target='identifier' required onBlur fullWidth />} />
                  <InputContainer label='Time to Solve(mins)*'
                     field={<ReusableTextField type='default' width={420} bgColor='#F2F2F2' placeholder='Time to Solve*'
                        state={time_to_solve} setState={updateCollection}
                        head='settingText' target='time_to_solve' required number />} />
                  <InputContainer label='Initial Points*'
                     field={<ReusableTextField type='default' width={420} bgColor='#F2F2F2' placeholder='Initial Points*'
                        state={initial_points} setState={updateCollection}
                        head='settingText' target='initial_points' required number />} />
                  <InputContainer label='Tags'
                     field={<ReusableAutocompleteWithID type='default' placeholder='Tags' width={590} multiple
                        state={tags} setState={updateCollection} head='settingMultiple' target='tags' borderColor='#707070'
                        options={tagOptions.map(d => ({ id: d.id, option: d.name }))} />} />
                  <InputContainer last label='Description'
                     field={<DescriptionEditor state={description} setState={updateCollection} height={400}
                        placeholder='Description' head='settingText' target='description' />} />
                  <Grid container justifyContent="flex-end" style={saveButtonContainerStyle}>
                     <ReusableButton text='Submit' bgColor='#5E75C3' fontSize={16} onClick={() => onSubmit()} />
                  </Grid>
               </AddPageWrapper>}
            </>}
            <Recommendations original={original} setOriginal={setOriginal}
               collection={collection} setCollection={setCollection} />
            <Sections original={original} setOriginal={setOriginal}
               collection={collection} setCollection={setCollection} />
         </Grid>}
      </>
   )
}
export default function EditCollection() {
   const [loading, setLoading] = useState(true)
   const [firstLoaded, setFirstLoaded] = useState(false)
   const [original, setOriginal] = useState()
   const [collection, setCollection] = useState()
   const [tagOptions, setTagOptions] = useState([])
   document.title = 'Edit Collection'
   let location = useLocation()
   useEffect(() => {
      const controller = new AbortController()
      const signal = controller.signal
      if (location.search.slice(0, 4) === '?id=') {
         getCollections(`?id=${location.search.slice(4)}`, signal).then(d => {
            let foundCollection = populateCollection(Object.values(d)[1])
            setOriginal(deepCopy(foundCollection))
            setCollection(foundCollection)
         }).catch(e => console.log(e)).finally(() => {
            if (!signal.aborted) {
               setLoading(false)
               setFirstLoaded(true)
            }
         })
         getDomains('?status=active&limits=50').then(d => {
            setTagOptions(Object.values(d)[1].data.filter(c => c.is_tag).map(t => ({ id: t.id, name: t.name })))
         }).catch(e => console.log(e))
      } else {
         showWarningSwal('Wrong query')
         setLoading(false)
         setFirstLoaded(true)
      }
      return () => controller.abort()
   }, [location.search])
   return (
      <Box className='wrapperBox'>
         <div className='firstRowContainer dashboardAndSelfBtnDiv'>
            <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard' iconType='home' />
            <ChevronRightIcon />
            <ReusableButton text='Collections' fontSize={14} bgColor='#F16774' height={36} br={18} to='/collection' />
         </div>
         <div className='formContainer'>
            <NunitoText value='Edit Collection' fontSize={40} fontWeight={700} italic color='#144A94' align='left' />
            {loading ? <Loader height={600} /> : collection ?
               <Collection original={original} setOriginal={setOriginal}
                  collection={collection} setCollection={setCollection} tagOptions={tagOptions} /> :
               firstLoaded && <NoData height={600} />}
         </div>
      </Box>
   )
}