import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import { useHistory } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import { getDomains } from '../../functions/getData'
import { showWarningSwal } from '../../functions/alert'
import { invalidName } from '../../functions/general'
import { addCollection as addCollectionToDatabase } from '../../functions/postData'
import { showNotification } from '../../functions/snackbar'
import Sections from './Sections'
import {
    addCollection, deleteCollection, duplicateCollection, updateCollection, resetCollection
} from '../../actions'
import DescriptionEditor from '../DescriptionEditor'
import Recommendations from '../Recommendations'
import TitleBar from '../general/TitleBar'
import AddPageWrapper from '../general/AddPageWrapper'
import InputContainer from '../general/InputContainer'
import ReusableTextField from '../general/ReusableTextField'
import ReusableAutocompleteWithID from '../general/ReusableAutocompleteWithID'
import ReusableButton from '../general/ReusableButton'
import { useSnackbar } from 'notistack'
const collectionContainerStyle = { backgroundColor: '#F3F0F4', borderRadius: '0 0 12px 12px', paddingBottom: 20 }
const Collection = ({ count, collection, competition, tagOptions }) => {
    const dispatch = useDispatch()
    const [expandCollection, setExpandCollection] = useState(false)
    const [expandSetting, setExpandSetting] = useState(false)
    const { name, identifier, timeToSolve, initialPoints, tags, description, recommendations } = collection
    const update = (count, head, target, text, event) => dispatch(updateCollection(count, head, target, text, event))
    // useEffect(() => update(count, 'settingText', 'competitionName', competition), [competition])
    return (
        <Grid container>
            <TitleBar title={`Collection ${count + 1}`} state={expandCollection} setState={setExpandCollection} main>
                <div style={{ marginRight: 40 }}>
                    <ReusableButton text='New Collection' fontSize={16} bgColor='#5E75C3' marginLeft={20}
                        onClick={() => dispatch(addCollection(count + 1))} iconType='add' />
                    {Boolean(count) && <ReusableButton text='Delete Collection' fontSize={16} bgColor='#E83042'
                        marginLeft={20} onClick={() => dispatch(deleteCollection(count))} iconType='delete' />}
                    <ReusableButton text='Duplicate Collection' fontSize={16} bgColor='#F16774' marginLeft={20}
                        onClick={() => dispatch(duplicateCollection(count + 1, collection))} iconType='copy' />
                </div>
            </TitleBar>
            {expandCollection && <Grid container style={collectionContainerStyle}>
                <TitleBar title='Settings' state={expandSetting} setState={setExpandSetting} />
                {expandSetting && <AddPageWrapper>
                    <InputContainer first label='Collection Name*'
                        field={<ReusableTextField type='default' bgColor='#F2F2F2' placeholder='Collection Name*'
                            state={name} setState={update} count={count}
                            head='settingText' target='name' required onBlur fullWidth />} />
                    <InputContainer label='Collection Identifier*'
                        field={<ReusableTextField type='default' bgColor='#F2F2F2' placeholder='Collection Identifier*'
                            state={identifier} setState={update} count={count}
                            head='settingText' target='identifier' required onBlur fullWidth />} />
                    <InputContainer label='Time to Solve(mins)*'
                        field={<ReusableTextField type='default' width={420} bgColor='#F2F2F2' placeholder='Time to Solve*'
                            state={timeToSolve} setState={update} count={count}
                            head='settingText' target='timeToSolve' required number />} />
                    <InputContainer label='Initial Points*'
                        field={<ReusableTextField type='default' width={420} bgColor='#F2F2F2' placeholder='Initial Points*'
                            state={initialPoints} setState={update} count={count}
                            head='settingText' target='initialPoints' required number />} />
                    <InputContainer label='Tags'
                        field={<ReusableAutocompleteWithID type='default' placeholder='Tags' width={590} multiple
                            state={tags} setState={update} count={count} head='settingMultiple' target='tags' borderColor='#707070'
                            options={tagOptions.map(d => ({ id: d.id, option: d.name }))} />} />
                    <InputContainer last label='Description'
                        field={<DescriptionEditor state={description} setState={update} height={400}
                            placeholder='Description' count={count} head='settingText' target='description' />} />
                </AddPageWrapper>}
                <Recommendations count={count} recommendations={recommendations} update={update} />
                <Sections count={count} collection={collection} />
            </Grid>}
        </Grid>
    )
}
export default function Collections() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const collections = useSelector(state => state.collection)
    const dispatch = useDispatch()
    // const [competitionOptions, setCompetitionOptions] = useState()
    // useEffect(() => {
    //     getCompetitions('?status=active&limit=100').then(d => setCompetitionOptions(d.competitionList.data))
    // }, [])
    // const [competition, setCompetition] = useState('')
    const [tagOptions, setTagOptions] = useState([])
    let history = useHistory()
    const onSubmit = () => {
        let payload = []
        let msg = ''
        for (let i = 0; i < collections.length; i++) {
            const { name, identifier, timeToSolve, initialPoints, tags, description, recommendations, sections } = collections[i]
            let warningMessage = ''
            if (invalidName(name)) warningMessage += 'Invalid Collection Name.<br>'
            if (invalidName(identifier)) warningMessage += 'Invalid Collection Identifier.<br>'
            if (isNaN(timeToSolve) || !timeToSolve) {
                warningMessage += 'Invalid Time to Solve(Must be a number greater than 0).<br>'
            }
            if (isNaN(initialPoints) || !initialPoints.toString().length) {
                warningMessage += 'Invalid Initial Points(Must be a number).<br>'
            }
            if (recommendations.map(r => r.grade).includes('')) {
                warningMessage += 'Please fill in all grades fields or remove recommended grade.<br>'
            }
            if (recommendations.map(r => r.difficulty).includes('')) {
                warningMessage += 'Please fill in all difficulty fields or remove recommended difficulty.<br>'
            }
            for (let j = 0; j < sections.length; j++) {
                const { tasks } = sections[j]
                if (!tasks.flat().length) {
                    warningMessage += `<b>Section ${j + 1}</b> has no task at all. Add task(s) to the section`
                    if (j) warningMessage += ' or remove the section'
                    warningMessage += '.<br>'
                }
            }
            if (warningMessage) {
                msg += '<div>'
                msg += `<div style="text-align:center; font-weight: 800">Error in Collection ${i + 1}</div>`
                msg += `<div style="text-align:left">${warningMessage}</div>`
                msg += '</div>'
            } else {
                payload.push({
                    settings: {
                        name: name, identifier: identifier,
                        time_to_solve: timeToSolve, initial_points: initialPoints,
                        tags: tags, description: description
                    },
                    recommendations: recommendations.map(r => ({ grade: `${i + 1}${r.grade}`, difficulty: r.difficulty })),
                    sections: sections.map(s => ({
                        groups: s.tasks.map(tasks => ({
                            task_id: tasks.map(t => t.id)
                        })),
                        sort_randomly: Number(s.sortRandomly),
                        allow_skip: Number(s.allowSkip),
                        description: s.description
                    }))
                })
            }
        }
        if (msg) return showWarningSwal(msg)
        console.log(JSON.stringify(payload))
        console.log(payload)
        addCollectionToDatabase(payload).then(d => {
            console.log(d)
            if (d.status === 201) {
                showNotification('success', d.message, enqueueSnackbar, closeSnackbar)
                dispatch(resetCollection())
                history.push('./collection')
            }
        }).catch(e => console.log(e))
    }
    useEffect(() => {
        const controller = new AbortController()
        const signal = controller.signal
        getDomains('?status=active&limits=50', signal).then(d => {
            setTagOptions(Object.values(d)[1].data.filter(c => c.is_tag).map(t => ({ id: t.id, name: t.name })))
        }).catch(e => console.log(e))
        return () => controller.abort()
    }, [])
    return <>
        {/* <Grid container alignItems='center' style={{ marginBottom: 20 }}>
            <NunitoText value='Competition Name*' fontSize={20} fontWeight={600} italic />
            <ReusableAutocompleteWithID type='default' width={450} height={55} btnWidth={63} btnBgColor='#144A94'
                borderColor='#707070' placeholder='Competition' state={competition} setState={setCompetition}
                options={competitionOptions.map(c => ({ id: c.id, option: c.name }))} marginLeft={200} />
        </Grid> */}
        {collections.map((collection, index) => (
            <Collection key={index} count={index} collection={collection} competition={null} tagOptions={tagOptions} />
        ))}
        <Grid container justifyContent="flex-end">
            <ReusableButton text='Submit' bgColor='#5E75C3' fontSize={16} onClick={() => onSubmit()} />
        </Grid>
    </>
}

// For Edit later on??
// const Owo = () => <Grid container style={{
//     margin: 12, backgroundColor: '#f3f0f4',
//     border: '1px solid', borderRadius: 10
// }}>
//     <div style={{ margin: 24 }}>
//         <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//             <h3 style={{ color: '#5e75c3', borderBottom: '2px solid black', width: 76 }}>Remarks</h3>
//             <ReusableButton text='View in new tab' height={40} bgColor='#F16774' fontSize={14} marginRight={20}
//                 iconType='copy' />
//         </div>
//         <div style={{ display: 'flex', alignItems: 'center' }}>
//             <h3 style={{ color: '#5e75c3', fontWeight: 'lighter', margin: 0 }}>Test Name(Chief Examiner)</h3>
//             <h5 style={{ color: '#f16774', fontWeight: 'lighter', margin: 0, marginLeft: 10 }}>(22/10/2021 14:00)</h5>
//         </div>
//         <p style={{ margin: 0, marginTop: 2 }}>
//             Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
//             labore et dolore magna aliqua. Rhoncus dolor purus non enim praesent elementum facilisis leo
//             vel. Risus at ultrices mi tempus imperdiet. Semper risus in hendrerit gravida rutrum quisque
//             non tellus. Convallis convallis tellus id interdum velit laoreet id donec ultrices. Odio morbi
//             quis commodo odio aenean sed adipiscing. Amet nisl suscipit adipiscing bibendum est ultricies
//             integer quis. Cursus euismod quis viverra nibh cras. Metus vulputate eu scelerisque felis
//             imperdiet proin fermentum leo. Mauris commodo quis imperdiet massa tincidunt.
//         </p>
//     </div>
// </Grid>