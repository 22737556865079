import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { IconButton } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
// import FileCopyIcon from '@mui/icons-material/FileCopy'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import BuildIcon from '@mui/icons-material/Build'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DeleteIcon from '@mui/icons-material/Delete'
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash'
import CheckIcon from '@mui/icons-material/Check'
import SpellcheckIcon from '@mui/icons-material/Spellcheck'
import LanguageIcon from '@mui/icons-material/Language'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { FiEdit, FiPlus } from 'react-icons/fi'
import { IoPersonAdd } from 'react-icons/io5'
import { IoIosCopy } from 'react-icons/io'
import { FaUserSlash, FaUserCheck, FaWindowClose, FaCloudUploadAlt, FaPen } from 'react-icons/fa'
import {HiDocumentReport} from 'react-icons/hi'
import { getDateDiff } from '../../functions/general'
import { isAdmin, isAdminOrPartner, isAdminOrPartnerOrAssistant, isAssistant, isPartnerOrAssistant } from '../../functions/checkrole'
import LightTooltip from '../general/LightTooltip'
const BtnLinks = (link, icon, blank) => (
    <Link style={{ color: '#000', height: 24 }} to={link} target={blank && '_blank'}>{icon}</Link>
)
export default function ActionButtons({ title, row, collection, section, taskInSection, checkTask, getters,
    setId, setOnDelete, setOnApprove, setOnReject, setOnRestore, setOnEdit, setOnModerateLanguages,
    setOnManagePermission, setOnAddUser, setOnAddParticipant, setOnEnable, setOnDisable, setOnAdd, setOnUpload }) {
    const openRowModal = (id, setOpenModal) => {
        setOpenModal(true)
        setId(id)
    }
    const user = useSelector(state => state.user)
    let icons = []
    switch (title) {
        case 'schools':
            icons = [
                // Need add in a list of user.allowed when isPartnerOrAssistant()
                row.status !== 'deleted' && { title: 'Edit', item: <FiEdit />, fn: () => openRowModal(row.id, setOnEdit) },
                ((row.status !== 'deleted' && isAdmin()) || (['pending', 'rejected'].includes(row.status) && isPartnerOrAssistant())) &&
                { title: 'Delete', item: <DeleteIcon />, fn: () => openRowModal(row.id, setOnDelete) },
                isAdmin() && row.status === 'deleted' &&
                { title: 'Restore', item: <RestoreFromTrashIcon />, fn: () => openRowModal(row.id, setOnRestore) },
                isAdmin() && row.status === 'pending' && user.user_id !== row.created_by_userid &&
                { title: 'Approve', item: <CheckIcon />, fn: () => openRowModal(row.id, setOnApprove) },
                row.status === 'active' && isAdminOrPartnerOrAssistant() &&
                { title: 'Add Teacher', item: <AddIcon />, fn: () => openRowModal(row.id, setOnAddUser) },
                row.status === 'active' &&
                { title: 'Add Participant', item: <IoPersonAdd />, fn: () => openRowModal(row.id, setOnAddParticipant) },
                isAdmin() && row.status === 'pending' && user.user_id !== row.created_by_userid &&
                { title: 'Reject', item: <FaWindowClose />, fn: () => openRowModal(row.id, setOnReject) }
                // row.status !== 'Pending' && { title: 'Merge', item: <MergeTypeIcon onClick={() => setOnMerge(true)} /> }
            ]
            break;
        case 'participants':
            icons = [
                { title: 'Edit', item: <FiEdit />, fn: () => openRowModal(row.id, setOnEdit) },
                (isAdmin() || (!isAdmin() && getDateDiff(new Date(), new Date(row.created_at)) <= 7)) &&
                { title: 'Delete', item: <DeleteIcon />, fn: () => openRowModal(row.id, setOnDelete) },
                // { title: 'Duplicate', item: <IoIosCopy /> }, { title: 'Edit Answers', item: <EditIcon /> }
            ]
            break;
        case 'difficulty':
        case 'domain':
            icons = [
                { title: 'Edit', item: <FiEdit />, fn: () => openRowModal(row.id, setOnEdit) },
                { title: 'Delete', item: <DeleteIcon />, fn: () => openRowModal(row.id, setOnDelete) },
                row.status !== 'active' && { title: 'Approve', item: <CheckIcon /> }
            ]
            break;
        case 'task':
        case 'translation':
            icons = [{ title: 'Edit', item: <FiEdit />, fn: () => window.open(`/edittask?id=${row.id}`) },
            { title: 'Delete', item: <DeleteIcon />, fn: () => openRowModal(row.id, setOnDelete) },
            { title: 'Duplicate', item: <IoIosCopy /> },
            // { title: 'Verify', item: <CheckCircleIcon /> },
            // { title: 'Assign Permission', item: <BuildIcon /> },
            { title: 'Moderate Languages', item: <LanguageIcon />, fn: () => openRowModal(row.id, setOnModerateLanguages) },
                // {
                //     title: 'Translate Task', item: <Link to={`/translateTasks/${row[getters[0]]}`} style={{ color: '#000' }}>
                //         <img src={languageIcon} alt='Language Icon' /></Link>
                // }
            ]
            break;
        case 'competitions':
            icons = [
                isAdminOrPartner() && row.status !== 'closed' &&
                { title: 'Edit', item: BtnLinks(`editcompetition?id=${row.id}`, <FiEdit />) },
                isAdmin() && { title: 'Delete', item: <DeleteIcon />, fn: () => openRowModal(row.id, setOnDelete) },
                isAdmin() &&
                { title: 'Duplicate', item: BtnLinks(`/addcompetition?duplicate_id=${row.id}`, <ContentCopyIcon />, true) },
                isAssistant() && row.status !== 'closed' &&
                { title: 'View Competition Details', item: BtnLinks(`/viewcompetition?id=${row.id}`, <VisibilityIcon />) },
                // { title: 'Session', item: <AccountBoxOutlinedIcon /> },
                isAdmin() && { title: 'Marking Preparation', item: BtnLinks(`/markingpreparation?id=${row.id}`, <EditIcon />) },
                isAdmin() && { title: 'Marking Overview', item: BtnLinks(`/markingoverview?id=${row.id}`, <FaPen />) },
                isAdmin() && new Date(row.competition_end_date).getTime() > new Date().getTime() &&
                { title: 'Add Country Partner', item: <AddIcon />, fn: () => openRowModal(row.id, setOnAddUser) },
                isAdmin() && !!row.rounds.length &&
                { title: 'Upload Answers', item: <FaCloudUploadAlt />, fn: () => openRowModal(row.id, setOnUpload) },
                {title: 'Generate Report' , item :<HiDocumentReport/> , fn:()=>{}}
                // { title: 'View Tasks', item: <FaTasks /> },
                // { title: 'View Collections', item: <CollectionsBookmarkIcon /> }
            ]
            break;
        case 'pending partners competition date':
            icons = [
                { title: 'Approve', item: <CheckIcon />, fn: () => openRowModal(row.id, setOnApprove) },
                { title: 'Reject', item: <FaWindowClose />, fn: () => openRowModal(row.id, setOnReject) }
            ]
            break;
        case 'taskModeration':
            icons = [{
                title: 'Moderate Task', item: <Link to={{ pathname: `/moderateTask`, state: [row[getters[0]]] }}
                    style={{ color: '#000' }}> <SpellcheckIcon /></Link>
            }]
            break;
        case 'roles':
            icons = [{ title: 'Delete', item: <DeleteIcon />, fn: () => openRowModal(row.id, setOnDelete) }]
            break;
        case 'collection':
            icons = [
                { title: 'Edit', item: BtnLinks(`/editcollection?id=${row.id}`, <FiEdit />) },
                { title: 'Delete', item: <DeleteIcon />, fn: () => openRowModal(row.id, setOnDelete) },
                { title: 'Duplicate', item: <IoIosCopy />, fn: () => window.open(`/addcollection?duplicate_id=${row.id}`) },
                { title: 'Assign Permision', item: <BuildIcon />, fn: () => openRowModal(row.id, setOnManagePermission) },
                row['status'] === 'pending' && { title: 'Approve', item: <CheckIcon /> },
                { title: 'Verify', item: <CheckCircleIcon /> }
            ]
            break;
        case 'users':
            let confirmCan = true
            switch (user.role_id) {
                case 0: confirmCan = [1, 2, 3, 4, 5].includes(row.role_id); break;
                case 1: confirmCan = [2, 3, 4, 5].includes(row.role_id); break;
                case 2: confirmCan = [3, 4, 5].includes(row.role_id); break;
                case 3: confirmCan = false; break;
                case 4: confirmCan = [3, 5].includes(row.role_id); break;
                case 5: confirmCan = [3].includes(row.role_id); break;
                default:
            }
            let canEditOtherUser = (user.user_id === 1 || confirmCan) && user.user_id !== row.id && row.status !== 'deleted'
            icons = canEditOtherUser ? [
                { title: 'Edit User', item: <FiEdit />, fn: () => openRowModal(row.id, setOnEdit) },
                isAdmin() && { title: 'Delete', item: <DeleteIcon />, fn: () => openRowModal(row.id, setOnDelete) },
                row['status'] === 'active' && isAdmin() ? {
                    title: 'Disable User', item: <FaUserSlash />, fn: () => openRowModal(row.id, setOnDisable)
                } : row.status === 'disabled' && isAdmin() ? {
                    title: 'Enable User', item: <FaUserCheck />, fn: () => openRowModal(row.id, setOnEnable)
                } : null,
                // isAdmin() && { title: 'View User Permissions', item: <Link to={`/userpermissions/${row.id}`} style={{ color: '#000' }}><VisibilityIcon /></Link> }
            ] : []
            break;
        case 'add task to collection':
            icons = [
                checkTask(collection, row.id) === -1 ? { title: 'Add to section', item: <FiPlus />, fn: () => openRowModal(row.id, setOnAdd) } :
                    taskInSection(section, row.id) === -1 ? null :
                        { title: 'Delete', item: <DeleteIcon />, fn: () => openRowModal(row.id, setOnDelete) }
            ]
            break;
        case 'organization':
            icons = row.status === 'active' ? [
                { title: 'Edit', item: <FiEdit />, fn: () => openRowModal(row.id, setOnEdit) },
                { title: 'Delete', item: <DeleteIcon />, fn: () => openRowModal(row.id, setOnDelete) }
            ] : []
            break;
        default:
    }
    return (
        <div>
            {icons.map((icon, index) => (
                icon && <LightTooltip key={`${row.id}-${index}`} title={icon.title} type='Button'
                    rollover={0} topic={0} hide={0}>
                    <IconButton onClick={() => icon.fn && icon.fn()} style={{ color: '#000' }}>{icon.item}</IconButton>
                </LightTooltip>
            ))}
        </div>
    )
}