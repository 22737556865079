import React, { useState, useEffect } from 'react'
import { Checkbox, Table, TableRow, Grid, TableCell, TableContainer, TableBody, Paper } from '@mui/material'
import { getComparator, stableSort } from '../../functions/table'
import EnhancedTableHead from './EnhancedTableHead'
import ReusableTextField from '../general/ReusableTextField'
import ReusableAutocomplete from '../general/ReusableAutocomplete'
import useWindowDimensions from '../../hooks/WindowDimensions'
const containerStyle = width => ({
   marginBlock: '2%', width: (0.89 * width)
})
const paperStyle = width => ({
   boxShadow: '0 0 3px #9E9E9E', minWidth: 400, maxWidth: 3200, width: (0.89 * width) - 60
})
const checkBoxDivStyle = {
   display: 'flex', flexDirection: 'column', width: 60
}
const selectAllCheckBoxStyle = {
   color: "#000", height: 61
}
const checkBoxStyle = {
   color: "#000", height: 80
}
const divStyle = {
   whiteSpace: "nowrap", textOverflow: "ellipsis",
   display: "block", overflow: "hidden", minWidth: 150, maxWidth: 300
}
const Cell = ({ count, row, getter, setState, color, isItemSelected, widths, fixed, difficultyOptions }) => {
   const tableCellStyle = {
      paddingLeft: 15, backgroundColor: isItemSelected ? '#EDF4FB' : color, backgroundClip: 'padding-box'
   }
   const fixedTableCellStyle = getter => {
      let index = widths.findIndex(w => w.id === getter)
      let w = widths[index - 1]?.totalWidth || 0
      return { ...tableCellStyle, position: 'sticky', left: w, zIndex: 999 }
   }
   let output = row[getter] || '-'
   if (getter === 'answer_structure') {
      output = output === 'open' ? 'Yes' : 'No'
   }
   let owo = <div style={divStyle}>{output}</div>
   if (['task_difficulty'].includes(getter)) {
      owo = <ReusableAutocomplete type='table' width={300} height={48} placeholder='Difficulty' freeSolo
         state={row[getter]} setState={setState} borderColor='#707070' count={count} target={getter}
         options={difficultyOptions.map(d => ({ id: d.id, option: d.name }))} />
   }
   if (row.answer_structure === 'open') {
      if (['min_marks', 'marks'].includes(getter)) {
         owo = <ReusableTextField type='table' height={48} width={120} bgColor='#F2F2F2' target={getter}
            placeholder='Points' count={[count, 0]} state={row.task_marks[0][getter]} setState={setState} number />
      } else if (['task_marks', 'task_wrong', 'task_blank'].includes(getter)) {
         owo = <div style={divStyle}>-</div>
      }
   } else {
      if (['task_wrong', 'task_blank'].includes(getter)) {
         owo = <ReusableTextField type='table' height={48} width={120} bgColor='#F2F2F2' target={getter}
            placeholder='Points' count={count} state={row[getter]} setState={setState} number />
      } else if (['task_marks'].includes(getter)) {
         owo = <div style={{ display: 'flex' }}>
            {row[getter].map((task_mark, i) => (
               <ReusableTextField key={i} type='table' height={48} width={120} bgColor='#F2F2F2' target='marks'
                  placeholder='Points' count={[count, i]} state={task_mark.marks} setState={setState} number />
            ))}
         </div>
      }
   }
   return (
      <TableCell align='left' component="th" scope="row" style={fixed.includes(getter) ? fixedTableCellStyle(getter) : tableCellStyle}>
         {owo}
      </TableCell>
   )
}
const Row = ({ count, row, getters, setState, isItemSelected, widths, fixed, difficultyOptions }) => {
   const [color, setColor] = useState('#FFF')
   const tableRowStyle = {
      height: 80, overFlow: 'auto'
   }
   return (
      <TableRow selected={isItemSelected} style={tableRowStyle}
         onMouseEnter={() => setColor('#F6F6F6')} onMouseLeave={() => setColor('#FFF')}>
         {getters.map((getter, i) => (
            <Cell key={`${getter}-${i}`} count={count} row={row} getter={getter} setState={setState} color={color}
               isItemSelected={isItemSelected} widths={widths} fixed={fixed} difficultyOptions={difficultyOptions} />
         ))}
      </TableRow>
   )
}
export default function AssignMarksTable({ headers, data, error,
   search, sections, selected, setSelected, setState, difficultyOptions,
   checkForErrorInRow, fixed }) {
   const getters = headers.map(h => h.id)
   const [order, setOrder] = useState('asc')
   const [orderBy, setOrderBy] = useState('id')
   const [widths, setWidths] = useState(headers.map(h => ({ id: h.id, width: 0, totalWidth: 0 })))
   const handleRequestSort = property => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
   };
   const handleSelectAllClick = (event, ids) => {
      if (event.target.checked) {
         const newSelecteds = ids;
         setSelected(newSelecteds)
         return;
      }
      setSelected([])
   };
   const handleClick = id => {
      const selectedIndex = selected.indexOf(id);
      let newSelected = [];
      if (selectedIndex === -1) {
         newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
         newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
         newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
         newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
         );
      }
      setSelected(newSelected)
   };
   const isSelected = (id) => selected.indexOf(id) !== -1
   const { width } = useWindowDimensions()
   const showedTasks = data.filter(row => row.name.toLowerCase().includes(search.toLowerCase()) && sections.find(s => row.section === s))
   const usedTasks = stableSort(showedTasks, getComparator(order, orderBy))
   const indeterminateCondition = selected.length > 0 && selected.length < showedTasks.length
   const checkedCondition = showedTasks.length > 0 && selected.length === showedTasks.length
   useEffect(() => {
      setSelected(selected.filter(s => usedTasks.map(t => t.id).includes(s)))
   }, [search, sections])
   return (
      <Grid container alignItems='flex-start' style={containerStyle(width)}>
         <div style={checkBoxDivStyle}>
            {!!showedTasks.length
               && <Checkbox style={selectAllCheckBoxStyle} indeterminate={indeterminateCondition}
                  checked={checkedCondition}
                  onChange={e => handleSelectAllClick(e, usedTasks.map(d => d.id))} />}
            {usedTasks.map((row, i) => (
               <Checkbox key={i} style={checkBoxStyle} onClick={e => handleClick(row.id)}
                  checked={isSelected(row.id)} />
            ))}
         </div>
         <Paper style={paperStyle(width)}>
            <TableContainer>
               <Table size='small'>
                  <EnhancedTableHead handleRequestSort={handleRequestSort} headers={headers}
                     widths={widths} setWidths={setWidths} fixed={fixed} />
                  <TableBody>
                     {usedTasks.map((row, i) => (
                        <Row key={i} count={data.findIndex(d => d.id === row.id)} row={row} getters={getters}
                           setState={setState} isItemSelected={isSelected(row.id)} widths={widths} fixed={fixed}
                           difficultyOptions={difficultyOptions} />
                     ))}
                  </TableBody>
               </Table>
            </TableContainer>
         </Paper>
      </Grid>
   )
}