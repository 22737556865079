import React, { useState, useEffect } from 'react'
import { TextField, IconButton } from '@mui/material'
import ReusableButton from './ReusableButton'
import SearchIcon from '@mui/icons-material/Search'
export default function ReusableTextField({ type, width, height, bgColor, borderColor,
    marginLeft, marginRight, marginTop, marginBottom,
    placeholder, state, setState, required, readOnly, grayedOut, disabled, errorFunction,
    count, head, target, onClick,
    // For ReusableTextField
    multiline, onBlur, number, prefix, fullWidth, justSearch }) {
    const [tempState, setTempState] = useState(state)
    useEffect(() => setTempState(state), [state])
    const [error, setError] = useState()
    //Some TextField uses this tempState to change first
    //In these cases, the actual update of the state passed in occurs onBlur
    const checkError = () => errorFunction && errorFunction(state)
    //Set border
    let defaultBorder = borderColor ? `1px solid ${borderColor}` : bgColor ? `1px solid ${bgColor}` : 'none'
    let border = (checkError() || (required && (onBlur ? tempState : state) === '')) ? '1px solid #E83D4D' : defaultBorder
    const searchIconStyle = {
        fontSize: 30,
        color: 'rgb(112, 112, 112, 0.5)'
    }
    const withLabelDivStyle = {
        display: 'flex', flexDirection: 'column', alignItems: 'flex-start',
        marginLeft, marginRight, marginTop, marginBottom, minWidth: width,
    }
    if (fullWidth) withLabelDivStyle.width = '100%'
    const textFieldMargin = type === 'withLabel' ? {} : { marginLeft, marginRight, marginTop, marginBottom }
    const textFieldStyle = {
        ...textFieldMargin,
        width, height,
        borderRadius: 12, border,
        backgroundColor: grayedOut ? '#707070' : bgColor
    }
    const inputStyle = type === 'search' ? { marginLeft: 20 } : {
        textAlign: 'center',
        width: '100%',
        color: grayedOut && '#FFF',
        cursor: readOnly && 'context-menu'
    }
    const searchButtonStyle = {
        color: '#FFF', backgroundColor: '#5E75C3',
        borderRadius: '0 10px 10px 0',
        height, width: 64,
    }
    const labelStyle = { color: '#000', fontSize: 16, fontFamily: 'Nunito', fontWeight: 600, fontStyle: 'italic' }
    const onChangeState = value => {
        let newValue = value
        let allow = true
        if (number) {
            if (!/^[\d]*$/.test(newValue)) allow = false
            else newValue = Number(newValue)
        }
        if (!allow) return setError('Only numeric characters')
        setError()
        if (count !== undefined && head !== undefined && target !== undefined) {
            // For redux textfields
            return setState(count, head, target, newValue)
        }
        if (count !== undefined && target !== undefined) {
            // For states in an array of objects
            return setState(count, target, newValue)
        }
        // For states in an array only, object only or by itself
        if (count !== undefined) setState(count, newValue)
        else if (target !== undefined) setState(target, newValue)
        else setState(newValue)
    }
    const textFieldAttributes = {
        variant: 'filled', placeholder, disabled,
        value: onBlur ? tempState : state,
        onChange: e => onBlur ? setTempState(e.target.value) : onChangeState(e.target.value),
        onBlur: () => type !== 'search' && onBlur && onChangeState(tempState),
        onClick: () => onClick && onClick(),
        fullWidth
    }
    if (multiline !== undefined) {
        textFieldAttributes.fullWidth = true
        textFieldAttributes.multiline = true
        textFieldAttributes.rows = multiline
    }
    const textFieldInputProps = {
        style: textFieldStyle,
        disableUnderline: true,
        hiddenLabel: true,
        readOnly
    }
    const onEnter = e => {
        if (e.key === 'Enter') onChangeState(tempState)
    }
    if (type === 'search') {
        textFieldAttributes.onKeyDown = onEnter
        if (!justSearch) {
            textFieldAttributes.value = tempState
            textFieldAttributes.onChange = e => setTempState(e.target.value)
        }
        textFieldInputProps.startAdornment = <SearchIcon style={searchIconStyle} />
        textFieldInputProps.endAdornment = <IconButton style={searchButtonStyle} onClick={() => onChangeState(tempState)} edge='end'>
            <SearchIcon style={{ fontSize: 36 }} />
        </IconButton>
    }
    if (type === 'immutableDisplay') {
        inputStyle.color = '#D4D2D2'
        inputStyle.fontWeight = 400
    }
    const textFieldinputProps = {
        style: inputStyle
    }
    const imagePlaceholderDivStyle = {
        display: 'flex', alignItems: 'center', justifyContent: 'center',
        width, height, border, borderRadius: 12,
        backgroundColor: bgColor, color: '#B8B8B8', fontSize: 24
    }
    if (number) {
        textFieldAttributes.type = 'number'
        textFieldinputProps.min = 0
        // if (prefix) textFieldInputProps.startAdornment = prefix
    }
    switch (type) {
        case 'immutableDisplay':
        case 'search':
        case 'default':
            return (
                <TextField {...textFieldAttributes}
                    InputProps={textFieldInputProps}
                    inputProps={textFieldinputProps}
                />
            )
        case 'withLabel':
            return (
                <div style={withLabelDivStyle}>
                    <div style={{ display: 'flex' }}>
                        <label style={labelStyle}>{placeholder}</label>
                        {error && <label style={{ color: 'red', marginLeft: 10 }}>{error}</label>}
                    </div>
                    <TextField {...textFieldAttributes}
                        InputProps={textFieldInputProps}
                        inputProps={textFieldinputProps}
                    />
                </div>
            )
        case 'clickOnly':
            return (
                <TextField variant='filled' onClick={() => onClick()} disabled={disabled}
                    InputProps={{
                        ...textFieldInputProps,
                        endAdornment: <div style={{ position: 'relative', left: 14 }}>
                            <ReusableButton text='Browse' bgColor='#5E75C3' fontSize={15} width={120} height={60} />
                        </div>
                    }}
                />
            )
        case 'table':
            return (
                <TextField {...textFieldAttributes} fullWidth
                    InputProps={textFieldInputProps}
                />
            )
        case 'imagePlaceholder': return <div style={imagePlaceholderDivStyle}>{placeholder}</div>
        default: return null
    }
}