import React, { useState, useEffect, useMemo, useRef } from 'react'
import { Box, Grid, Modal } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { showWarningSwal } from '../functions/alert'
import { getCompetitions, getMarking, getMarkingPreparation } from '../functions/getData'
import { patchMarkingPreparation } from '../functions/patchData'
import { showNotification } from '../functions/snackbar'
import { cancelAction } from '../functions/general'
import CloseIcon from '@mui/icons-material/Close'
import MarkingOverviewTable from '../components/table/MarkingOverviewTable'
import NunitoText from '../components/general/NunitoText'
import ReusableButton from '../components/general/ReusableButton'
import Loader from '../components/general/Loader'
import NoData from '../components/general/NoData'
import Widget from '../components/general/Widget'
import TitleBar from '../components/general/TitleBar'
import AddPageWrapper from '../components/general/AddPageWrapper'
import LoadingBackdrop from '../components/general/LoadingBackdrop'
import { useSnackbar } from 'notistack'
import axios from 'axios'
const containerStyle = { paddingInline: 30 }
const widgetContainerStyle = { paddingTop: 24 }
const headers = [
   { id: 'level', label: 'Level' },
   { id: 'countries', label: 'Country' },
   { id: 'marked', label: 'Marked / Total Participants' },
   { id: 'absentees', label: 'Absentee' },
   { id: 'progress', label: 'Progress' }
]
export default function MarkingOverview() {
   document.title = 'Marking Overview'
   const isMounted = useRef(false)
   const [loading, setLoading] = useState(false)
   const [loadingData, setLoadingData] = useState(false)
   const [firstLoaded, setFirstLoaded] = useState(false)
   const [data, setData] = useState([])
   const [competitionName, setCompetitionName] = useState('')
   const controller = useMemo(() => new AbortController(), [])
   const signal = controller.signal
   let location = useLocation()
   // let res = [
   //    {
   //       "id": 4, //for re-compute and edit use when redirect to the respective page
   //       "level": 2,
   //       "countries": [
   //          "singapore",
   //          "malaysia", "taiwan", "japan","singapore"
   //       ],
   //       "marked": 20,
   //       "total_attended_participants": 50,
   //       "absentees": [
   //          "ken",
   //          "ryu",
   //          "chun li"
   //       ]
   //    }
   // ]
   // axios.get('https://api.debug.simccms.org/api/marking?competition_id=1').then(res=>{
   //       console.log(res.data)
   //    })
   useEffect(() => {
      isMounted.current = true
      const controller = new AbortController()
      const signal = controller.signal
      if (location.search.slice(0, 4) === '?id=') {
         setLoadingData(true)
         getMarking(`?competition_id=${location.search.slice(4)}`,signal).then(res=>{
            setFirstLoaded(true)
        
         setCompetitionName(res.competition_name)
         let data = []
         if(res){

         for (const round in res.rounds) {
            let singleRound = [];
           
            res.rounds[round].map((round , index)=>{
            
               round.groups.map((el,index)=>{
                  let isactive= true
                  let iscomputed = true
                  if(el.status!=="active"&&el.status!=="computed"){
                     isactive = false
                  }
                  if(el.status!=="computed"){
                     iscomputed = false
                  }
                  let roundlvl = {
                     round:index+1,
                     iscomputed:iscomputed,
                     isactive:isactive,
                     levelReady:round.level_ready,
                     id:el.id,
                     level:round.name,
                     countries:el.country_name,
                     marked:el.marked,
                     total_attended_participants:el.total_participants,
                     absentees:el.absentee
                  }
                  if(roundlvl.countries.length>0){
                  singleRound.push(roundlvl)}
               })
              
               
              
                
            })
            
            data.push(singleRound)
          }}
         setData(data)
         setLoadingData(false)
         })
      } else {
         showWarningSwal('Wrong query')
      }
      return () => {
         controller.abort()
         isMounted.current = false
      }
   }, [location.search, controller, signal])
   return (
      
      <Box className='wrapperBox'>
         <LoadingBackdrop loading={loading} />
         <div className='firstRowContainer dashboardAndSelfBtnDiv'>
            <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard' iconType='home' />
            <ChevronRightIcon />
            <ReusableButton text='Competitions' fontSize={14} bgColor='#F16774' height={36} br={18} to='/competitions' />
         </div>
         
         
         <Grid className='tableContainer' container>
            <NunitoText value='Marking Overview' fontSize={40} fontWeight={700} italic color='#144A94' />
            <TitleBar fixed main>
               <ReusableButton text='Mark Overall' fontSize={15} bgColor='#5E75C3' height={46}
                  marginRight={20} onClick={() => { }} iconType='mark' />
               <ReusableButton text='Compute All' fontSize={15} bgColor='#5E75C3' height={46}
                  marginRight={60} onClick={() => { }} iconType='compute' />
            </TitleBar>
            <AddPageWrapper main>
              
               <Grid container style={containerStyle}>
                  {loadingData && <Loader height={110} />}
               
                  <Grid container style={widgetContainerStyle}>
                     {competitionName && <Widget title='Competition' content={competitionName} marginRight={28} />}
                  </Grid>
                
                  <>
              
                  {!loadingData && firstLoaded && data.length &&
                  data.map((el,index)=>{
                     return   <>
                     <h2 style={{'lineHeight':'5px'}}>Round:{index+1}</h2>
                     
                     <MarkingOverviewTable
                     setloading = {setLoadingData}
                     headers={headers} data={el} fixed={['level', 'countries']} /></>
                  })
                }
                       </>
               </Grid>
               
            </AddPageWrapper>
         </Grid>
        
      </Box >
   )
}