import React from 'react'
import ReusableSelect from './general/ReusableSelect'
import ReusableButton from './general/ReusableButton'
const containerStyle = { backgroundColor: '#F5F5F5', padding: '0 30px 15px 30px' }
const h3Style = { marginBottom: 0 }
const dropDownWrapperStyle = {
    marginBottom: 30, display: 'flex', flexDirection: 'column', alignItems: 'flex-start'
}
const dropDownStyle = { marginTop: 20 }
export function Dropdown({ header, label, state, multiple, options, filtered, onChangeFiltered }) {
    const filtering = (target, value) => {
        let newFiltered = window.structuredClone(filtered)
        let index = newFiltered.findIndex(f => f.header === target)
        //Select single
        if (typeof newFiltered[index].state === 'string') newFiltered[index].state = value
        //Multiple
        else newFiltered[index].state = typeof value === 'string' ? value.split(',') : value
        onChangeFiltered(newFiltered)
    }
    return <ReusableSelect type={multiple ? 'multipleFilter' : 'filter'} label={label} width={200} marginRight={40}
        bgColor='#FFF' state={state} setState={filtering} target={header}
        options={options.map(o => ({ value: o.id, option: o.option }))} />
}
export default function Filter({ filterOptions, filtered, onChangeFiltered }) {
    const reset = () => {
        let newFiltered = window.structuredClone(filtered)
        newFiltered.forEach(f => Array.isArray(f.state) ? f.state = [] : f.state = '')
        onChangeFiltered(newFiltered)
        // onChangeFiltered(filtered.map(f => ({ ...f, state: Array.isArray(f.state) ? [] : '' })))
    }
    return (
        <div style={containerStyle}>
            <div style={dropDownWrapperStyle}>
                <h3 style={h3Style}>Filter and Sort</h3>
                <div style={dropDownStyle}>
                    {filtered.map(({ label, state, header }, index) => (
                        <Dropdown key={index} header={header} label={label} state={state} multiple={typeof state === 'object'}
                            options={filterOptions[index]} filtered={filtered} onChangeFiltered={onChangeFiltered} />
                    ))}
                </div>
            </div>
            <ReusableButton text='Reset filter' bgColor='#F16774' fontSize={16} br={8} onClick={reset} iconType='reset' />
        </div>
    )
}