import React, { useState, useEffect, useMemo } from 'react'
import { Box, Grid, Collapse, Modal } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ViewOption from '../components/ViewOption'
import Filter from '../components/Filter'
import HeavyTable from '../components/table/HeavyTable'
import DeleteModal from '../components/modal/DeleteModal'
import AddUserModal from '../components/User/AddUserModal'
import EditUserModal from '../components/User/EditUserModal'
import EnableUserModal from '../components/User/EnableUserModal'
import DisableUserModal from '../components/User/DisableUserModal'
import { useLocation } from 'react-router-dom'
import {
    makeSureIsArray, makeOptions, checkIfAllSelectedRowsMatchStatus, resetAll,
    changeViewOptions, changeRowsPerPage, changeFiltered, changeSearch, loadData, controlView, controlFilter, cancelAction
} from '../functions/general'
import { showWarningSwal } from '../functions/alert'
import { getUsers } from '../functions/getData'
import { enableUser, disableUser } from '../functions/patchData'
import { deleteUser } from '../functions/deleteData'
import { showNotification } from '../functions/snackbar'
import NunitoText from '../components/general/NunitoText'
import ReusableTextField from '../components/general/ReusableTextField'
import ReusableButton from '../components/general/ReusableButton'
import ViewAndFilterButton from '../components/ViewAndFilterButton'
import LoadingBackdrop from '../components/general/LoadingBackdrop'
import NoData from '../components/general/NoData'
import Loader from '../components/general/Loader'
import { useSnackbar } from 'notistack'
import { isAdmin, isAdminOrPartnerOrAssistantOrManager } from '../functions/checkrole'
const userHeader = () => ([
    { id: 'name', label: 'Name' },
    { id: 'username', label: 'Username' },
    { id: 'role_name', label: 'Role' },
    { id: 'status', label: 'Status' },
    isAdmin() && { id: 'organization_name', label: 'Organization' },
    // { id: 'permissionOverriden', label: 'Permission Overridden?' },
    { id: 'email', label: 'Email' },
    { id: 'phone', label: 'Phone Number' },
    isAdmin() && { id: 'country_name', label: 'Country' },
    { id: 'created_by_username', label: 'Created By' },
    { id: 'modified_by_username', label: 'Last Modified By' },
    { id: 'last_login', label: 'Last Login' }
].filter(Boolean))
const initialParams = '?limits=10'
export default function Users() {
    document.title = 'Users'
    let location = useLocation()
    const [header, setHeader] = useState(userHeader())
    const [userTable, setUserTable] = useState()
    const [filtered, setFiltered] = useState([
        isAdmin() && { label: 'Organization', state: '', key: 'organization_id', header: 'organization' },
        { label: 'Status', state: '', key: 'status', header: 'status', },
        isAdmin() && { label: 'Country', state: '', key: 'country_id', header: 'countries' },
        { label: 'Role', state: '', key: 'role_id', header: 'role' },
        { label: 'School', state: '', key: 'school_id', header: 'schools' }
    ].filter(Boolean))
    const [filterOptions, setFilterOptions] = useState()
    const [viewOptions, setViewOptions] = useState([
        // { label: "Permission Overridden?", state: true, key: 'created_by_username' },
        { label: 'E-Mail', state: true, key: 'email' },
        isAdmin() && { label: "Country", state: true, key: 'country_name' },
        { label: 'Created By', state: true, key: 'created_by_username' },
        { label: "Modified By", state: true, key: 'modified_by_username' },
        { label: 'Last Login', state: true, key: 'last_login' },
        { label: 'Status', state: true, key: 'status' }
    ].filter(Boolean))
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [search, setSearch] = useState('')
    const [params, setParams] = useState(initialParams)
    const [loading, setLoading] = useState(false)
    const [loadingTable, setLoadingTable] = useState(false)
    const [firstLoaded, setFirstLoaded] = useState(false)
    const [viewing, setViewing] = useState(false)
    const [filtering, setFiltering] = useState(false)
    const [id, setId] = useState('')
    const [selected, setSelected] = useState([])
    const [selecting, setSelecting] = useState([])
    const [onDelete, setOnDelete] = useState(false)
    const [onAddUser, setOnAddUser] = useState(false)
    const [onEditUser, setOnEditUser] = useState(false)
    const [onEnable, setOnEnable] = useState(false)
    const [onDisable, setOnDisable] = useState(false)
    const handleView = () => controlView(viewing, setViewing, setFiltering)
    const handleFilter = () => controlFilter(filtering, setFiltering, setViewing)
    const cancelDelete = () => cancelAction(setOnDelete, setId)
    const cancelEdit = () => cancelAction(setOnEditUser, setId)
    const cancelEnable = () => cancelAction(setOnEnable, setId)
    const cancelDisable = () => cancelAction(setOnDisable, setId)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const afterFunction = (cancel, data) => {
        if ([200, 201].includes(data.status)) {
            resetAll(initialParams, filtered, setParams, setSearch, setFiltered)
            getData(initialParams, signal)
            cancel()
            showNotification('success', data.message, enqueueSnackbar, closeSnackbar)
            setSelected([])
            setSelecting([])
        }
    }
    const deleteFunction = () => {
        deleteUser({ id: id === '' ? selected : [id] }).then(d => {
            afterFunction(cancelDelete, d)
        }).catch(e => console.log(e))
    }
    const enableFunction = () => {
        enableUser({ id: id === '' ? selected : [id] }).then(d => {
            afterFunction(cancelEnable, d)
        }).catch(e => console.log(e))
    }
    const disableFunction = () => {
        disableUser({ id: id === '' ? selected : [id] }).then(d => {
            afterFunction(cancelDisable, d)
        }).catch(e => console.log(e))
    }
    const getData = (params, signal) => {
        setSelected([])
        setSelecting([])
        loadData(setLoadingTable, setFirstLoaded, getUsers, params, setUserTable, setFilterOptions, false, signal)
    }
    const onChangeViewOptions = value => changeViewOptions(userHeader(), setViewOptions, setHeader, value)
    const onChangeRowsPerPage = value => {
        changeRowsPerPage(value, rowsPerPage, filtered, search, signal, setRowsPerPage, setParams, getData)
    }
    const onChangeFiltered = value => {
        changeFiltered(value, filtered, search, rowsPerPage, signal, setFiltered, setParams, getData)
    }
    const onChangeSearch = value => {
        changeSearch(value, search, filtered, rowsPerPage, signal, setSearch, setParams, getData)
    }
    const onChangePage = pageNo => getData(`${params}&page=${pageNo}`, signal)
    const controller = useMemo(() => new AbortController(), [])
    const signal = controller.signal
    useEffect(() => {
        if (['status', 'role_id'].every(el => location.search.includes(el))) {
            // If came to this page from clicking the hyperlink on Schools page or Competitions page
            let newFiltered = window.structuredClone(filtered)
            location.search.replace('?', '').split('&').forEach(l => {
                let head = l.split('=')[0]
                if (['status', 'country_id', 'role_id', 'school_id'].includes(head)) {
                    newFiltered.find(n => n.key === head).state = l.split('=')[1]
                }
            })
            setFiltered(newFiltered)
            changeFiltered(newFiltered, filtered, search, rowsPerPage, setFiltered, setParams, getData)
        } else {
            getData(initialParams, signal)
        }
        return () => controller.abort()
    }, [controller, signal])
    const onClickMassEnable = () => {
        return userTable && checkIfAllSelectedRowsMatchStatus(selected, userTable.data, ['disabled'])
            ? setOnEnable(true)
            : showWarningSwal('Please select disabled users only to mass enable')
    }
    const onClickMassDisable = () => {
        return userTable && checkIfAllSelectedRowsMatchStatus(selected, userTable.data, ['active'])
            ? setOnDisable(true)
            : showWarningSwal('Please select active users only to mass disable')
    }
    const onClickMassDelete = () => {
        return selected.length ? setOnDelete(true) : showWarningSwal('Please select at least one user to mass delete')
    }
    return (
        <Box className='wrapperBox'>
            <LoadingBackdrop loading={loading} />
            <Collapse in={viewing}>
                <ViewOption viewOptions={viewOptions} rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={onChangeRowsPerPage} onChangeViewOptions={onChangeViewOptions} />
            </Collapse>
            <Collapse in={filtering}>
                {filterOptions && <Filter type='collapse' filterOptions={filtered.map(f => makeOptions(filterOptions, f.header))}
                    filtered={filtered} onChangeFiltered={onChangeFiltered} />}
            </Collapse>
            <Grid className='firstRowContainer' container justifyContent="space-between">
                <div className='dashboardAndSelfBtnDiv'>
                    <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard' iconType='home' />
                    <ChevronRightIcon />
                    <ReusableButton text='Users' fontSize={14} bgColor='#F16774' height={36} br={18} />
                </div>
                <div className='viewAndFilterBtnDiv'>
                    <ViewAndFilterButton text='View Options' state={viewing} fontSize={14} height={42}
                        onClick={handleView} marginRight={10} />
                    <ViewAndFilterButton text='Filter' state={filtering} fontSize={14} height={42}
                        onClick={handleFilter} />
                </div>
            </Grid>
            {/* {JSON.stringify(params)}<br />
            {JSON.stringify(location.search)}<br />
            {location.search.replace('?', '').split('&').map(l => (
                <p>{JSON.stringify(l.split('=')[1])}</p>
            ))}<br /> */}
            <Grid className='tableContainer' container>
                <NunitoText value='Users' fontSize={40} fontWeight={700} italic color='#144A94' />
                <Grid className='searchAndBtnContainer' container alignItems="center" justifyContent="space-between">
                    <ReusableTextField type='search' width={500} height={60} bgColor='#F2F2F2' placeholder='Search using keyword'
                        state={search} setState={onChangeSearch} onBlur />
                    <div className='endBtnDiv'>
                        {isAdmin() && <ReusableButton text='Mass Enable User' fontSize={15} bgColor='#5E75C3' height={46}
                            marginRight={20} onClick={() => onClickMassEnable()} iconType='enable' />}
                        {isAdmin() && <ReusableButton text='Mass Disable User' fontSize={15} bgColor='#F16774' height={46}
                            marginRight={20} onClick={() => onClickMassDisable()} iconType='disable' />}
                        {isAdmin() && <ReusableButton text='Mass Delete' fontSize={15} bgColor='#E83042' height={46}
                            marginRight={20} onClick={() => onClickMassDelete()} iconType='delete' />}
                        {isAdminOrPartnerOrAssistantOrManager() && <ReusableButton text='Add New User' fontSize={15} bgColor='#5E75C3'
                            height={46} marginRight={20} onClick={() => setOnAddUser(true)} iconType='add' />}
                    </div>
                </Grid>
                <NunitoText value='Search only in "Name" and "Email" column'
                    fontSize={20} fontWeight={400} italic color='#144A94' />
                {loadingTable && <Loader height={600} />}
                {!loadingTable && userTable && makeSureIsArray(userTable.data).length &&
                    <HeavyTable headers={header} list={userTable} title='users' rowsPerPage={rowsPerPage}
                        setOnDelete={setOnDelete} onChangePage={onChangePage} fixed={['name', 'username', 'role_name']}
                        selecting={selecting} setSelecting={setSelecting}
                        selected={selected} setSelected={setSelected} setId={setId} setOnEdit={setOnEditUser}
                        setOnEnable={setOnEnable} setOnDisable={setOnDisable}
                    />}
                {!loadingTable && firstLoaded && userTable && !makeSureIsArray(userTable.data).length && <NoData height={600} />}
            </Grid>
            <Modal open={onDelete} onClose={() => cancelDelete()}>
                <>
                    {Boolean(userTable) && <DeleteModal table={userTable} id={id} selected={selected}
                        deleteFunction={deleteFunction} cancelDelete={cancelDelete} />}
                </>
            </Modal>
            <Modal open={onAddUser} onClose={() => setOnAddUser(false)}>
                <>
                    <AddUserModal setLoading={setLoading} setOnAddUser={setOnAddUser} afterAddingUser={afterFunction} />
                </>
            </Modal>
            <Modal open={onEditUser} onClose={() => cancelEdit()}>
                <>
                    {userTable && <EditUserModal setLoading={setLoading} table={userTable} id={id}
                        afterEditingUser={afterFunction} cancelEdit={cancelEdit} />}
                </>
            </Modal>
            <Modal open={onEnable} onClose={() => cancelEnable()}>
                <>
                    {userTable && <EnableUserModal table={userTable} id={id} selected={selected}
                        enableFunction={enableFunction} cancelEnable={cancelEnable} />}
                </>
            </Modal>
            <Modal open={onDisable} onClose={() => cancelDisable()}>
                <>
                    {userTable && <DisableUserModal table={userTable} id={id} selected={selected}
                        disableFunction={disableFunction} cancelDisable={cancelDisable} />}
                </>
            </Modal>
        </Box >
    )
}