import React, { useState, useEffect, useMemo, useRef } from 'react'
import { Box, Grid, Collapse, Modal } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ViewOption from '../components/ViewOption'
import Filter from '../components/Filter'
import HeavyTable from '../components/table/HeavyTable'
import { useSelector } from 'react-redux'
import {
    makeSureIsArray, makeOptions, checkIfAllSelectedRowsMatchStatus, checkIfAnySelectedRowIs, resetAll,
    changeViewOptions, changeRowsPerPage, changeFiltered, changeSearch, loadData, controlView, controlFilter, cancelAction
} from '../functions/general'
import { getEditRound, getSchools } from '../functions/getData'
import { useLocation } from 'react-router-dom'

import { showNotification } from '../functions/snackbar'
import NunitoText from '../components/general/NunitoText'
import ReusableTextField from '../components/general/ReusableTextField'
import ReusableButton from '../components/general/ReusableButton'
import ViewAndFilterButton from '../components/ViewAndFilterButton'
import LoadingBackdrop from '../components/general/LoadingBackdrop'
import NoData from '../components/general/NoData'
import Loader from '../components/general/Loader'
import { useSnackbar } from 'notistack'
import { isAdmin, isAdminOrPartner, isPartnerOrAssistant, isAdminOrPartnerOrAssistant } from '../functions/checkrole'
import Widget from '../components/general/Widget'
import EnhancedPagination from '../components/table/EnhancedPagination'
import { Save } from '@mui/icons-material'
import { patchEditMarkRound } from '../functions/patchData'
const schoolHeader = () => ([
    { id: 'index_no', label: 'Index' },
    isAdmin() && { id: 'name', label: 'Name' },
    { id: 'country', label: 'Contry' },
    { id: 'school', label: 'School/Tuition Center' },
    isAdminOrPartner() && { id: 'grade', label: 'Grade' },
    { id: 'points', label: 'Points' },
    { id: 'reference_award', label: 'reference award' },
    { id: 'assigned_award_id', label: 'Award Given' },
   
].filter(Boolean))
const initialParams = '?limits=10'
const res ={
    competition_name: "xxx",
    round: "round 1",
    level: "level 1",
    level_id: "11",
    award_type: "percentage",
    competition_Awards:[
        {
            id: 1,
            name: "gold",
            cutoff : 69
        },
        {
            id: 2,
            name: "silver",
            cutoff:61
        }
    ],
    results: [
        {
            index_no: 2121212,
            name: "david off",
            country: "united states of america",
            school: " Harvard (tuition)",
            grade: 6,
            points: 50,
            reference_award: "gold",
            assigned_award_id: 1
        },
        {
            index_no: 154897,
            name: "jhon",
            country: "united kingdom",
            school: " Harvard (tuition)",
            grade: 4,
            points: 80,
            reference_award: "gold",
            assigned_award_id: 1
        }, {
            index_no: 789789,
            name: "hassan",
            country: "egypt",
            school: " cairo unvirestey",
            grade: 6,
            points: 70,
            reference_award: "silver",
            assigned_award_id: 2
        },
        {
            index_no: 456456,
            name: "smith",
            country: "united states of america",
            school: " Harvard (tuition)",
            grade: 5,
            points: 90,
            reference_award: "gold",
            assigned_award_id: 1
        }, {
            index_no: 123123,
            name: "mark",
            country: "canada",
            school: "(tuition)",
            grade: 4,
            points: 60,
            reference_award: "silver",
            assigned_award_id: 2
        }, {
            index_no: 951753,
            name: "kristina",
            country: "australia",
            school: "something",
            grade: 6,
            points: 55,
            reference_award: "gold",
            assigned_award_id: 1
        }, {
            index_no: 159753,
            name: "carla ",
            country: "united states of america",
            school: " stanford (tuition)",
            grade: 7,
            points: 85,
            reference_award: "gold",
            assigned_award_id: 1
        }, {
            index_no: 183927,
            name: "martha",
            country: "spain",
            school: " (tuition)",
            grade: 6,
            points: 82,
            reference_award: "gold",
            assigned_award_id: 1
        }, {
            index_no: 2121212,
            name: "david off",
            country: "united states of america",
            school: " Harvard (tuition)",
            grade: 6,
            points: 50,
            reference_award: "gold",
            assigned_award_id: 1
        }, {
            index_no: 2121212,
            name: "david off",
            country: "united states of america",
            school: " Harvard (tuition)",
            grade: 6,
            points: 50,
            reference_award: "gold",
            assigned_award_id: 1
        }
       
    ]
} 


/*

var currentAwardId = extract the first item award_id in the array for results[0]['award_id'], for example is = 1
var currentMarks; 
var awardCutOff = [];

results.forEach(function (result,index){
  if(result['award_id'] != currentAward || count(results) === index) {
     awardCutOff[currentAward] = currentMarks;
     currentAward = result['award_id'];
  }
  currentMarks = result['points'];
});
*/ 

export default function EditRoundMarking() {
    document.title = 'Moderate Results'
    const isMounted = useRef(false)
    const user = useSelector(state => state.user)
    const [header, setHeader] = useState(schoolHeader())
    const [awardType , setAwardType] = useState('gold')
    const [errorMsg , setErrorMsg] = useState('')
    const [pts , setPts] = useState(79)
    const [schoolTable, setSchoolTable] = useState()
    const [rewards , setRewards] = useState([])
    const [filtered, setFiltered] = useState([
        { label: 'Country', state: '', key: 'status', header: 'status' },
        { label: "School/Tuition", state: '', key: 'private', header: 'schooltype' },
        isAdmin() && { label: 'Reference Award', state: '', key: 'country_id', header: 'countries' },
        { label: 'Award Given', state: '', key: 'status', header: 'status' }
    ].filter(Boolean))
    const [filterOptions, setFilterOptions] = useState()
    const [viewOptions, setViewOptions] = useState([
        { label: "Country", state: true, key: 'country' },
        { label: "School/Tuition Center", state: true, key: 'school' },
        { label: "Grade", state: true, key: 'grade' },
        { label: "Reference Points", state: true, key: 'points' },
        { label: "Reference Award", state: true, key: 'reference_award' },
        { label: 'Percentile', state: true, key: 'approved_by_username' },
    
    ])
    const [rowsPerPage, setRowsPerPage] = useState(20)
    const [loading, setLoading] = useState(false)
    const [loadingTable, setLoadingTable] = useState(false)
    const [firstLoaded, setFirstLoaded] = useState(false)
    const [viewing, setViewing] = useState(false)
    const [filtering, setFiltering] = useState(false)
    const [allData , setAllData] = useState([])
    const [id, setId] = useState('')
    const [selected, setSelected] = useState([])
    const [selecting, setSelecting] = useState([])
    const handleView = () => controlView(viewing, setViewing, setFiltering)
    const handleFilter = () => controlFilter(filtering, setFiltering, setViewing)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    let location = useLocation()
    const controller = useMemo(() => new AbortController(), [])
    const signal = controller.signal
    // const afterFunction = (cancel, data) => {
    //     if ([200, 201].includes(data.status)) {
    //         resetAll(initialParams, filtered, setParams, setSearch, setFiltered)
    //         getData(initialParams, signal)
    //         cancel()
    //         showNotification('success', data.message, enqueueSnackbar, closeSnackbar)
    //         setSelected([])
    //         setSelecting([])
    //     }
    // }
  
   
    // const getData = (params, signal) => {
    //     setSelected([])
    //     setSelecting([])
    //     loadData(setLoadingTable, setFirstLoaded, getSchools, params, setSchoolTable, setFilterOptions, false, signal)
    // }
    
   
    const onChangeViewOptions = value => changeViewOptions(schoolHeader(), setViewOptions, setHeader, value)
    const onChangeRowsPerPage = value => {
        // changeRowsPerPage(value, rowsPerPage, filtered, search, signal, setRowsPerPage, setParams, getData)
    }
    const onChangeFiltered = value => {
        // changeFiltered(value, filtered, search, rowsPerPage, signal, setFiltered, setParams, getData)
    }
    const getAwardId=(name)=>{
        let id = allData.awards.filter((el)=>{
            return el.name===name
        })
        return id[0].id
    }
    const reset = ()=>{
        if(allData.results.length>0){
        let newresult = []
       
        allData.results.map((el)=>{
            let obj = {
                index_no: el.participant_index,
                name : el.participant,
                private:el.private,
                country:el.country,
                school:el.school,
                grade: el.grade,
                points: el.points,
                reference_award: el.ref_award_id,
                assigned_award_id: el.ref_award_id!==null?el.ref_award_id:el.award_id
            }
            newresult.push(obj)
        }) 
        const sampleData = {
            competition_name:allData.competition_name,
            level : allData.Level,
            competition_Awards:rewards,
            current_page : 1,
            data:newresult,
            first_page_url: "https://api.debug.simccms.org/api/competition?page=1",
            from: 1,
            last_page:1,
            last_page_url: "https://api.debug.simccms.org/api/competition?page=1",
            links:[],
            next_page_url: null,           
            path: "https://api.debug.simccms.org/api/competition",           
            per_page: "10",
            prev_page_url: null,            
            to: 5,
            total: 5
        }
        setSchoolTable(sampleData)}
    }
    const assingAward = (points ,rewards)=>{
        let award = '' 
        rewards.map((el,index)=>{
            if(points<rewards[rewards.length-1].cutoff){
                award = 'default'
            }else if(index>0){
                if(points>=el.cutoff && points<rewards[index-1].cutoff){
                    award = el.name
                }
            }else{
                award = el.name
            }
    })
        return award
    }
    const onChangePage = pageNo => {}
   
    const filterRowsOnEdit =(reward)=>{
        
        if(allData.results.length>0){  
            let results = []
            allData.results.map((el)=>{
                const obj = {
                    index_no: el.participant_index,
                    private:el.private,
                    name:el.participant,
                    country:el.country,
                    school:el.school,
                    grade: el.grade,
                    points: el.points,
                    reference_award: el.ref_award_id,
                    assigned_award_id:assingAward(el.points , reward)==='default'?'default':getAwardId(assingAward(el.points , reward))
                }
               
                results.push(obj)
           
            })
           
                const sampleData = {
                    competition_name:allData.competition_name,
                    level : allData.Level,
                    competition_Awards:reward,
                    current_page : 2,
                    data:results,
                    first_page_url: "https://api.debug.simccms.org/api/competition?page=1",
                    from: 1,
                    last_page:25,
                    last_page_url: "https://api.debug.simccms.org/api/competition?page=1",
                    links:[],
                    next_page_url: null,           
                    path: "https://api.debug.simccms.org/api/competition",           
                    per_page: "10",
                    prev_page_url: null,            
                    to: 5,
                    total: 5
                }
               setSchoolTable(sampleData)
           
            
        }    
    }
    const fillTable = (results , grade)=>{
      let result = []
        results.map((el)=>{
            let obj = {
                index_no: el.participant_index,
                name:el.participant,
                country: el.country,
                school: el.school,
                grade: grade,
                points: el.points,
                reference_award: el.ref_award_id,
                assigned_award_id: el.award_id
            }
            result.push(obj)
       })
        const sampleData = {
            competition_name:res.competition_name,
            level : grade,
            competition_Awards:rewards,
            current_page : 2,
            data:result,
            first_page_url: "https://api.debug.simccms.org/api/competition?page=1",
            from: 1,
            last_page:25,
            last_page_url: "https://api.debug.simccms.org/api/competition?page=1",
            links:[],
            next_page_url: null,           
            path: "https://api.debug.simccms.org/api/competition",           
            per_page: "10",
            prev_page_url: null,            
            to: 5,
            total: 5
        }
        setSchoolTable(sampleData)
    }
  
   

    const handleRewards = ()=>{
        const firstElement = rewards[0]
        const lastElement = rewards[rewards.length-1]
        const beforeLast = rewards[rewards.length-2]
        let reward =[]
            if(awardType===firstElement.name && rewards[1]!==undefined){
                if(pts<rewards[1].cutoff){
                    setErrorMsg(`Cut-Off must be higher than ${rewards[1].cutoff}`)
                }else{
                
                    rewards.map((el)=>{
                        let obj={
                            name:el.name,
                            id:el.id,
                            cutoff:el.name===firstElement.name  ?Number(pts):el.cutoff
                        }
                        reward.push(obj)
                    })
                    setRewards(reward)
                    showNotification('success', 'success', enqueueSnackbar, closeSnackbar)
                    setErrorMsg(``)

                    filterRowsOnEdit(reward)
                }
            }else if(awardType===lastElement.name){
                    if(pts>=beforeLast.cutoff){
                        setErrorMsg(`Cut-Off must be lower than ${beforeLast.cutoff}`)
                    }else{
                        
                        rewards.map((el)=>{
                            let obj={
                                name:el.name,
                                id:el.id,
                                cutoff:el.name===lastElement.name?Number(pts):el.cutoff
                            }
                            reward.push(obj)
                        })
                        setRewards(reward)
                        setErrorMsg(``)

                        showNotification('success', 'success', enqueueSnackbar, closeSnackbar)
                        filterRowsOnEdit(reward)
                    }
            }else{
                const currentobj = rewards.filter((el)=>{
                    return el.name ===awardType
                })[0]
                
                if((pts>=rewards[rewards.indexOf(currentobj)-1].cutoff || pts<=rewards[rewards.indexOf(currentobj)+1].cutoff) &&rewards[rewards.indexOf(currentobj)-1]!==undefined &&rewards[rewards.indexOf(currentobj)+1]!==undefined ){
                    setErrorMsg(`Cut-Off must be between ${rewards[rewards.indexOf(currentobj)+1].cutoff} and  ${rewards[rewards.indexOf(currentobj)-1].cutoff}`)
                }else{
                    rewards.map((el)=>{
                        let obj={
                            name:el.name,
                            id:el.id,
                            cutoff:el.name===currentobj.name?Number(pts):el.cutoff
                        }
                        reward.push(obj)
                    })
                    setRewards(reward)
                    setErrorMsg(``)

                    showNotification('success', 'success', enqueueSnackbar, closeSnackbar)
                    filterRowsOnEdit(reward)

                }
            }
          
    }
    const Save =()=>{
        let awards = []
        schoolTable.data.map((el)=>{
            let obj = {
                level_id:allData.level_id,
                index_no:el.index_no,
                award_id:el.assigned_award_id
        }
        if(!(obj.award_id==='default'||obj.award_id===null)){
            awards.push(obj)

        }
        })

        const payload = {
            group_id : Number(location.search.slice(4)),
            set_awards :awards
        }
        patchEditMarkRound(payload).then((data)=>{
                        showNotification('success', data.message, enqueueSnackbar, closeSnackbar)

        })
    }
    useEffect(() => {
        //    console.log(allData , rewards)
          
          if(rewards.length>0 ){
            
            filterRowsOnEdit(rewards)
          }
           
        }, [allData])
        console.log(rewards)
        useEffect(() => {
            const controller = new AbortController()
            const signal = controller.signal
            
            if (location.search.slice(0, 4) === '?id=') {
            getEditRound(`?group_id=${location.search.slice(4)}`, signal).then((res)=>{
                let lvl = res.level.slice(res.level.length-1 , res.level.length)
                let results = res.results
                let currentAwardId =  results[0].award_id
                let currentMarks; 
                let awardCutOff = [];
        
                results.forEach(function (result,index){
                if(result.award_id != currentAwardId || results.indexOf(results) === index) {
                    awardCutOff[currentAwardId-1] = currentMarks;
                    currentAwardId = result['award_id'];
                }
                currentMarks = result.points;
                });
                let award = []
                res.awards.map((el,index)=>{
                    let obj =  {
                        id: el.id,
                        round_id : el.round_id,
                        name: el.name,
                        cutoff : awardCutOff[index]
                    }
                    award.push(obj)
                })
                setRewards(award)
                setAllData(res)
                setRowsPerPage(res.results.length)
            
            })
        }
            isMounted.current = true
            return () => {
                controller.abort()
                isMounted.current = false
            }
        }, [controller, signal ])
    
    return (
        <Box className='wrapperBox'>
            <LoadingBackdrop loading={loading} />
            <Collapse in={viewing}>
                <ViewOption viewOptions={viewOptions} rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={onChangeRowsPerPage} onChangeViewOptions={onChangeViewOptions} />
            </Collapse>
            <Collapse in={filtering}>
                {filterOptions && <Filter type='collapse' filterOptions={filtered.map(f => makeOptions(filterOptions, f.header))}
                    filtered={filtered} onChangeFiltered={onChangeFiltered} />}
            </Collapse>
           
            <Grid className='tableContainer' container>
                <NunitoText value='Moderate Results' fontSize={40} fontWeight={700} italic color='#144A94' />
                <Grid className='firstRowContainer' container justifyContent="space-between">
                <div className='dashboardAndSelfBtnDiv' style={{width:'40%'}}>
                    <div style={{display:'flex' , 'justifyContent':'space-between' , width:'100%' }}>
                       <Widget content={`${allData.competition_name}`} title='Competition'></Widget>
                       {allData.round&& <Widget content={`${allData.round.slice(5)}`} title='Round'></Widget>}
                        <Widget content={`${allData.level_id}`} title='Level'></Widget>
                        <Widget content={`${allData.award_type}`} title = 'Award Type'></Widget>
                    </div>
                    {/* <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard' iconType='home' />
                    <ChevronRightIcon />
                    <ReusableButton text='Schools' fontSize={14} bgColor='#F16774' height={36} br={18} /> */}
                </div>
                {/* <div className='viewAndFilterBtnDiv'>
                    <ViewAndFilterButton text='View Options' state={viewing} fontSize={14} height={42}
                        onClick={handleView} marginRight={10} />
                    <ViewAndFilterButton text='Filter' state={filtering} fontSize={14} height={42}
                        onClick={handleFilter} />
                </div> */}
            </Grid>
                <Grid className='searchAndBtnContainer' container  justifyContent="space-between" alignItems="flex-end">
                
                   <div style={{'backgroundColor':'white' , 'box-shadow':'rgb(158 158 158) 0px 0px 5px' , 'width':'25%','borderRadius':'18px' , 'padding':'5px','display':'flex'
                ,'flexDirection':'column',alignItems:'center'
                }}>
                    <p style={{'fontSize':'20px'}}>Edit Cut-Off Points</p>
                    <div style={{'display':'flex' , 'justifyContent':'space-between' , 'width':'80%','height':'48px','marginBottom':'2%'}}>
                        <select style={{'width':'60%','borderRadius':'10px'}}  onChange={(e)=>{setAwardType(e.target.value)}}>
                        <option value="" selected disabled hidden>awards</option>

                            {schoolTable&&rewards.map(el=>{
                                return <option value={el.name}>{el.name}</option>
                            })}
                        </select>
                        <input value={`${pts}`} onChange={(e)=>{setPts(e.target.value)
                      
                        }} type='number' style={{'width':'30%','borderRadius':'10px', 'border':'1px solid','textAlign':'center'}}></input>
                    </div>
                    <div style={{'display':'flex' , 'justifyContent':'space-between', 'width':'90%','alignItems':'center'}}>
                        <p style={{'fontSize':'15px', 'color':'#F3797E'}}>{errorMsg}</p>
                        <button style={{'height':'30px','backgroundColor':'#B5C3FC','color':'white','border':'1px solid','borderRadius':'10px'}} onClick={()=>{
                                handleRewards()
                         
                            
                        }}>confirm edit</button>
                    </div>
                   </div>
                    {isAdminOrPartnerOrAssistant() && <div className='endBtnDiv'>
                    <ReusableButton text='Reset Default' fontSize={15} bgColor='#E83042' height={46} onClick={()=>{reset()}}
                            marginRight={20}  iconType='reset' />
                        {/* <ReusableButton text='Remark' fontSize={15} bgColor='#5E75C3' height={46}
                            marginRight={20}  iconType='add' /> */}
                        {isAdmin() && <ReusableButton text='save' fontSize={15} bgColor='#5E75C3' height={46} onClick={()=>Save()}
                            marginRight={20}  iconType='save' />}
                       
                    </div>}
                </Grid>
                {/* <NunitoText value='Search only in "School/Tuition", "Address", "Postal Code", "Province" and "Phone Number" column'
                    fontSize={20} fontWeight={400} italic color='#144A94' /> */}
                {loadingTable && <Loader height={600} />}
                {!loadingTable && schoolTable && makeSureIsArray(schoolTable.data).length &&
                   <> <HeavyTable headers={header} list={schoolTable}  rowsPerPage={rowsPerPage}
                        onChangePage={onChangePage} fixed={['index_no', 'name']}
                        selecting={selecting} setSelecting={setSelecting} selected={selected} setSelected={setSelected}
                        setId={setId}  title="editround" 
                        
                    />
                    <EnhancedPagination list={schoolTable} rowsPerPage={rowsPerPage} onChangePage={onChangePage} />
                    </>
                    }
                    

                {!loadingTable && firstLoaded && schoolTable && !makeSureIsArray(schoolTable.data).length && <NoData height={600} />}
            </Grid>
            
        </Box>
    )
}