import React, { useState } from 'react'
import { Box, Grid } from '@mui/material'
import NunitoText from '../general/NunitoText'
import ReusableTextField from '../general/ReusableTextField'
import ReusableButton from '../general/ReusableButton'
import { makeSureIsArray } from '../../functions/general'
export default function RejectModal({ table, id, rejectFunction, cancelReject }) {
    const selectedRow = makeSureIsArray(table.data).find(s => s.id === id)
    const [reason, setReason] = useState('')
    return (
        <Box className='popUpModal' style={{ width: 880, minHeight: 630 }}>
            <NunitoText value='CONFIRM REJECT' fontSize={30} fontWeight={600} color='#F16774' marginTop={20} />
            <NunitoText value='Please enter your reason for rejecting' fontSize={20} fontWeight={600} />
            <NunitoText value={selectedRow.name || selectedRow.competition_name} fontSize={20} fontWeight={500} marginTop={20} />
            <div style={{ paddingInline: 30 }}>
                <ReusableTextField type='default' placeholder='Please enter your reason for rejection'
                    value={reason} setState={setReason} multiline={8} marginTop={40} />
            </div>
            <Grid container justifyContent="space-evenly" style={{ marginBlock: "30px 20px" }}>
                <ReusableButton text='Cancel' bgColor='#8D8D8D' fontSize={25} height={63} width={280}
                    onClick={() => cancelReject()} />
                <ReusableButton text='Confirm' bgColor='#E83042' fontSize={25} height={63} width={280}
                    onClick={() => rejectFunction(reason)} />
            </Grid>
        </Box>
    )
}