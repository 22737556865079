import React, { useState, useEffect, useRef } from 'react'
import { Box, Grid, RadioGroup, Radio, FormControlLabel } from '@mui/material'
import { CSVLink } from 'react-csv'
import { gradeOptions as allGradeOptions } from '../../functions/general'
import { makeSureIsArray } from '../../functions/general'
import { getParticipants } from '../../functions/getData'
import { useSelector } from 'react-redux'
import { isAdmin } from '../../functions/checkrole'
import ReusableAutocompleteWithID from '../general/ReusableAutocompleteWithID'
import ReusableButton from '../general/ReusableButton'
import NunitoText from '../general/NunitoText'
import { showWarningSwal } from '../../functions/alert'
import Loader from '../general/Loader'
const boxStyle = { width: 1200, minHeight: 400, maxHeight: '90%' }
const exportTypeDivStyle = { display: 'flex', alignItems: 'center', marginLeft: 28 }
const radioGroupStyle = { marginInline: '20px 50px' }
const radioStyle = { color: '#000' }
const buttonsContainerStyle = { marginBlock: 30, paddingInline: 100 }
export default function ExportParticipantModal({ selected, setSelected, setSelecting, cancelExport }) {
    const isMounted = useRef(false)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState('')
    const [type, setType] = useState('2')
    const [organization, setOrganization] = useState('')
    const [competition, setCompetition] = useState('')
    const [country, setCountry] = useState('')
    const [grade, setGrade] = useState('')
    const [status, setStatus] = useState('')
    const [isPrivate, setIsPrivate] = useState('')

    const [organizationOptions, setOrganizationOptions] = useState([])
    const [competitionOptions, setCompetitionOptions] = useState([])
    const allCountryOptions = useSelector(state => state.countryOptions)
    const [countryOptions, setCountryOptions] = useState(allCountryOptions)
    const [gradeOptions, setGradeOptions] = useState(allGradeOptions)
    const [statusOptions, setStatusOptions] = useState([])
    const [privateOptions, setPrivateOptions] = useState([])
    const forOCS = participants => {
        let newData = 'FirstName,LastName,DateOfBirth,Gender,Index'
        participants.forEach(participant => {
            newData += `\n${participant.name}, ,2001-08-13,M,${participant.index_no}${participant.competition_alias}`
        })
        setData(newData)
    }
    const retrieveAndExport = query => {
        setLoading(true)
        getParticipants(query).then(p => {
            if (p.participantList.total === 0) return alert(`Participants not found`)
            let participants = selected.length
                ? makeSureIsArray(p.participantList.data).filter(f => selected.includes(f.id))
                : makeSureIsArray(p.participantList.data)
            type === '0' ? setData(participants) : forOCS(participants)
            document.getElementById('btn').click()
            cancelExport()
            setSelected([])
            setSelecting([])
        }).catch(e => console.log(e)).finally(() => setLoading(false))
    }
    useEffect(() => {
        isMounted.current = true
        const controller = new AbortController()
        const signal = controller.signal
        if (selected.length) return
        getParticipants('?limits=1', signal).then(p => {
            let options = p.filterOptions
            setCountryOptions(allCountryOptions.filter(c => options.countries.find(o => o.id === c.id)))
            setOrganizationOptions(options.organization)
            setCompetitionOptions(options.competition)
            setGradeOptions(allGradeOptions.filter(g => options.grade.includes(g.id)))
            setStatusOptions(options.status.map(s => ({ id: s, name: s })))
            setPrivateOptions(options.private.map(pr => ({ id: pr, name: pr ? 'Private' : 'School' })))
        }).catch(e => console.log(e))
        return () => {
            controller.abort()
            isMounted.current = false
        }
    }, [selected, allCountryOptions])
    const onSubmit = () => {
        if (!['0', '1'].includes(type)) return showWarningSwal('Please select Export Type')
        if (selected.length) return retrieveAndExport('?limits=0')
        let query = '?limits=0'
        if (organization !== '') query += `&organization_id=${organization}`
        if (competition !== '') query += `&competition_id=${competition}`
        if (country !== '') query += `&country_id=${country}`
        if (grade !== '') query += `&grade=${grade}`
        if (status !== '') query += `&status=${status}`
        if (isPrivate !== '') query += `&private=${isPrivate}`
        retrieveAndExport(query)
    }
    return (
        <Box className='popUpModal horizontalScrollable' style={boxStyle}>
            <NunitoText value='Export To CSV' fontSize={30} fontWeight={600} color='#5E75C3' marginTop={20} />
            <CSVLink id='btn' filename='participants' data={data} />
            {loading ? <Loader height={selected.length ? 186 : 414} /> : <>
                <div>
                    <div style={exportTypeDivStyle}>
                        <NunitoText value='Export Type: ' fontSize={20} fontWeight={600} />
                        <RadioGroup value={type} style={radioGroupStyle} onChange={e => setType(e.target.value)}>
                            <Grid container>
                                <FormControlLabel value='0' control={<Radio style={radioStyle} />} label="Raw Data" />
                                <FormControlLabel value='1' control={<Radio style={radioStyle} />} label="For OCS" />
                            </Grid>
                        </RadioGroup>
                    </div>
                    {/* {JSON.stringify({ type, organization, country, competition, grade, status, isPrivate })} */}
                    {Boolean(selected.length) ? `You have selected ${selected.length} participants` : <>
                        {isAdmin() && <Grid container justifyContent='space-evenly'>
                            <ReusableAutocompleteWithID type='withLabel' width={550} height={60} placeholder='Organization'
                                state={organization} setState={setOrganization} btnBgColor='#144A94' borderColor='#707070'
                                options={organizationOptions.map(o => ({ id: o.id, option: o.name }))} />
                            <ReusableAutocompleteWithID type='withLabel' width={550} height={60} placeholder='Country'
                                state={country} setState={setCountry} btnBgColor='#144A94' borderColor='#707070'
                                options={countryOptions.map(o => ({ id: o.id, option: o.display_name }))} />
                        </Grid>}
                        <Grid container justifyContent='space-evenly'>
                            <ReusableAutocompleteWithID type='withLabel' width={550} height={60} placeholder='Competition'
                                state={competition} setState={setCompetition} btnBgColor='#144A94' borderColor='#707070'
                                options={competitionOptions.map(o => ({ id: o.id, option: o.name }))} />
                            <ReusableAutocompleteWithID type='withLabel' width={550} height={60} placeholder='Grade'
                                state={grade} setState={setGrade} btnBgColor='#144A94' borderColor='#707070'
                                options={gradeOptions.map(o => ({ id: o.id, option: o.name }))} />
                        </Grid>
                        <Grid container justifyContent='space-evenly'>
                            <ReusableAutocompleteWithID type='withLabel' width={550} height={60} placeholder='Status'
                                state={status} setState={setStatus} btnBgColor='#144A94' borderColor='#707070'
                                options={statusOptions.map(o => ({ id: o.id, option: o.name }))} />
                            <ReusableAutocompleteWithID type='withLabel' width={550} height={60} placeholder='Private'
                                state={isPrivate} setState={setIsPrivate} btnBgColor='#144A94' borderColor='#707070'
                                options={privateOptions.map(o => ({ id: o.id, option: o.name }))} />
                        </Grid>
                    </>}
                </div>
                <Grid container justifyContent='space-evenly' style={buttonsContainerStyle}>
                    <ReusableButton text='Cancel' fontSize={22} height={60} width={300} bgColor='#8D8D8D'
                        onClick={() => cancelExport()} />
                    <ReusableButton text='Submit' fontSize={22} height={60} width={300} bgColor='#5E75C3'
                        onClick={() => onSubmit()} />
                </Grid>
            </>}
        </Box>
    )
}