import React, { useState } from 'react'
import { Grid } from '@mui/material'
import { getTasks } from '../../functions/getData'
import { patchTaskContent } from '../../functions/patchData'
import { showWarningSwal } from '../../functions/alert'
import { showNotification } from '../../functions/snackbar'
import { afterEdit, isUnchanged, populateTask } from '../../functions/edit'
import TitleBar from '../general/TitleBar'
import AddPageWrapper from '../general/AddPageWrapper'
import MainContent from './MainContent'
import TranslatedContent from './TranslatedContent'
import Loader from '../general/Loader'
import ReusableCheckbox from '../general/ReusableCheckbox'
import ReusableButton from '../general/ReusableButton'
import { useSnackbar } from 'notistack'
const buttonsContainerStyle = { marginRight: 20, marginBottom: 20 }
export default function Contents({ original, setOriginal, task, setTask }) {
    const [expand, setExpand] = useState(false)
    const [loading, setLoading] = useState(false)
    const [remoderate, setRemoderate] = useState(false)
    const { id, languages } = task
    const mainCount = languages.findIndex(l => l.name === 'English')
    const mainLanguage = languages.find(l => l.name === 'English')
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const updateLanguages = (count, identifier, value) => {
        let newTask = window.structuredClone(task)
        newTask.languages[count][identifier] = value
        setTask(newTask)
    }
    const onSubmit = () => {
        if (isUnchanged(original.languages, languages)) return showWarningSwal('Please make some changes before submitting')
        let payload = {
            id,
            taskContents: [],
            "re-moderate": Number(remoderate)
        }
        for (const language of languages) {
            let originalLanguage = original.languages.find(l => l.id === language.id)
            if (JSON.stringify(language) !== JSON.stringify(originalLanguage)) {
                payload.taskContents.push({
                    lang_id: language.id,
                    title: language.task_title,
                    content: language.content
                })
            }
        }
        setLoading(true)
        patchTaskContent(payload).then(d => {
            if (d.status === 200) showNotification('success', d.message, enqueueSnackbar, closeSnackbar)
        }).catch(e => console.log(e)).finally(() => {
            afterEdit(getTasks, populateTask, setOriginal, task, setTask)
            setLoading(false)
        })
    }
    return (
        <Grid container>
            <TitleBar title='Languages' state={expand} setState={setExpand} />
            {expand && <AddPageWrapper>
                {loading ? <Loader height={600} /> : <>
                    <MainContent count={mainCount} language={mainLanguage} updateLanguages={updateLanguages} />
                    {languages.find(l => l.name !== 'English') && <TranslatedContent languages={languages} updateLanguages={updateLanguages}
                        languageOptions={languages.map(l => ({ id: l.id, name: l.name }))} />}
                    <Grid container alignItems='center' justifyContent='space-between' style={buttonsContainerStyle}>
                        <ReusableCheckbox type='default' label='Re-Moderate Other Languages' state={remoderate} setState={setRemoderate} />
                        <ReusableButton text='Save' bgColor='#5E75C3' width={140} height={50} fontSize={16}
                            onClick={() => onSubmit()} />
                    </Grid>
                </>}
            </AddPageWrapper>}
        </Grid >
    )
}