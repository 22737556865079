import React, { useState, useEffect, useRef } from 'react'
import { Grid, RadioGroup, Radio, FormControlLabel, Modal } from '@mui/material'
import { cancelAction } from '../../functions/general'
import { patchCompetitionRound } from '../../functions/patchData'
import { showWarningSwal } from '../../functions/alert'
import { showNotification } from '../../functions/snackbar'
import DeleteModal from '../modal/DeleteModal'
import AddRoundModal from './AddRoundModal'
import Level from './Level'
import NunitoText from '../general/NunitoText'
import ReusableButton from '../general/ReusableButton'
import ReusableTextField from '../general/ReusableTextField'
import ReusableToggle from '../general/ReusableToggle'
import TitleBar from '../general/TitleBar'
import AddPageWrapper from '../general/AddPageWrapper'
import LoadingBackdrop from '../general/LoadingBackdrop'
import InputContainer from '../general/InputContainer'
import { patchCompetitionRoundAward } from '../../functions/patchData'
import { deleteCompetitionRoundAward } from '../../functions/deleteData'
import ClearIcon from '@mui/icons-material/Clear'
import AddRoundAwardsModal from './AddRoundAwardsModal'
import { useSnackbar } from 'notistack'
import { isUnchanged, deepCopy } from '../../functions/edit'
const roundAwardGridStyle = {
   border: '1px solid #707070', borderRadius: 12, padding: 20,
   boxShadow: '5px 5px 12px rgba(0, 0, 0, 16%)', marginBottom: 30
}
const iconStyle = { fontSize: 30, cursor: 'pointer' }
const RoundAward = ({ count, awardCount, original, setOriginal, competition, setCompetition, setLoading, openDeleteModal, isMounted }) => {
   const { award_type, assign_award_points } = competition.rounds[count]
   const { id, name, award_points, min_points , percentage } = competition.rounds[count].rounds_awards[awardCount]
   const { enqueueSnackbar, closeSnackbar } = useSnackbar()
   const updateAward = (identifier, value) => {
      let newCompetition = window.structuredClone(competition)
      newCompetition.rounds[count].rounds_awards[awardCount][identifier] = value
      setCompetition(newCompetition)
   }
   
   const onSubmit = () => {
      let payload = {
         round_id: competition.rounds[count].id,
         award: [
            { id, name, award_points, min_marks:min_points , percentage }
         ]
      }
      console.log(payload)
      setLoading(true)
      patchCompetitionRoundAward(payload).then(d => {
         if (d.status === 200) {
            showNotification('success', d.message, enqueueSnackbar, closeSnackbar)
            if (isMounted.current) {
               let newCompetition = window.structuredClone(competition)
               setCompetition(newCompetition)
               setOriginal(deepCopy(newCompetition))

            }
         }
      }).catch(e => console.log(e)).finally(() => isMounted.current && setLoading(false))
   }
   return (
      <>
         {awardCount % 2 === 1 && <Grid item xs={0.2} />}
         <Grid item xs={5.8} style={roundAwardGridStyle}>
            <Grid container justifyContent='flex-end'>
               <ClearIcon style={iconStyle} onClick={() => openDeleteModal(id)} />
            </Grid>
            <Grid container>
               {assign_award_points ? <Grid item xs={5.8}>
                  <ReusableTextField type='withLabel' placeholder='Label*' state={name} setState={updateAward}
                     target='name' fullWidth required />
               </Grid> : <ReusableTextField type='withLabel' placeholder='Label*' state={name} setState={updateAward}
                  target='name' fullWidth required />}
               {assign_award_points && <Grid item xs={0.4} />}
               {assign_award_points && <Grid item xs={5.8}>
                  <ReusableTextField type='withLabel' placeholder='Points*' state={award_points} setState={updateAward}
                     target='award_points' fullWidth required number />
               </Grid>}
            </Grid>
            <Grid container>
               {!award_type ? <Grid item xs={5.8}>
                  <ReusableTextField type='withLabel' placeholder='Minimum Points*' state={min_points===undefined?0:min_points } setState={updateAward}
                     target='min_points' fullWidth required number />
               </Grid> : <ReusableTextField type='withLabel' placeholder='Minimum Points*' state={min_points===undefined?0:min_points } setState={updateAward}
                  target='min_points' fullWidth required number />}
               {!award_type && <Grid item xs={0.4} />}
               {!award_type && <Grid item xs={5.8}>
                  <ReusableTextField type='withLabel' placeholder='Percentile' state={percentage} setState={updateAward}
                     target='percentage' fullWidth required number />
               </Grid>}
            </Grid>
            <Grid container justifyContent='flex-end'>
               <NunitoText value={`${awardCount + 1}`} fontSize={20} />
            </Grid>
            <Grid container justifyContent='flex-end'>
               <ReusableButton text='Save' bgColor='#5E75C3' fontSize={15} onClick={() => onSubmit()} iconType='save' />
            </Grid>
         </Grid>
         {awardCount % 2 === 0 && <Grid item xs={0.2} />}
      </>
   )
}
const awardContainerStyle = { margin: 30, border: '1px solid #707070', borderRadius: 12, padding: 36 }
const titleAndMinPointsContainerStyle = { marginBottom: 10 }
const minAndUseForAllDivStyle = { display: 'flex', alignItems: 'center', border: '1px solid #707070', borderRadius: 12, padding: 15 }
const defaultContainerStyle = { marginTop: 10 }
const Award = ({ count, original, setOriginal, competition, setCompetition, setLoading }) => {
   const isMounted = useRef(false)
   const [minPoints, setMinPoints] = useState(0)
   const [onAddAward, setOnAddAward] = useState(false)
   const [onDelete, setOnDelete] = useState(false)
   const [id, setId] = useState('')
   const { rounds_awards } = competition.rounds[count]
   const cancelAddRoundAward = () => cancelAction(setOnAddAward)
   const cancelDelete = () => cancelAction(setOnDelete, setId)
   const openDeleteModal = id => {
      setId(id)
      setOnDelete(true)
   }
   const { enqueueSnackbar, closeSnackbar } = useSnackbar()
   const updateRound = (identifier, value) => {
      let newCompetition = window.structuredClone(competition)
      switch (identifier) {
         case 'applyMinimumPoints':
            for (const award of newCompetition.rounds[count].rounds_awards) {
               award.min_points = value
            }
            break;
         default: newCompetition.rounds[count][identifier] = value
      }
      setCompetition(newCompetition)
   }
   const afterAddingRoundAward = (cancel, data) => {
      if ([200, 201].includes(data.status)) {
         cancel()
         showNotification('success', data.message, enqueueSnackbar, closeSnackbar)
         if (isMounted.current) {
            let newCompetition = window.structuredClone(competition)
            for (const roundAward of data.data) {
               const { id, name, award_points, min_marks , percentage } = roundAward
               newCompetition.rounds[count].rounds_awards.push({
                  id: id,
                  name, award_points, min_marks , percentage
               })
            }
            setCompetition(newCompetition)
            setOriginal(deepCopy(newCompetition))
         }
      }
   }
   const deleteFunction = () => {
      setLoading(true)
      let payload = { id: id }
      deleteCompetitionRoundAward(payload).then(d => {
         console.log(d)
         if (d.status === 200) {
            cancelDelete()
            showNotification('success', d.message, enqueueSnackbar, closeSnackbar)
            if (isMounted.current) {
               let newCompetition = window.structuredClone(competition)
               newCompetition.rounds[count].rounds_awards = newCompetition.rounds[count].rounds_awards.filter(ra => ra.id !== id)
               setCompetition(newCompetition)
               setOriginal(deepCopy(newCompetition))
            }
         }
      }).catch(e => console.log(e)).finally(() => isMounted.current && setLoading(false))
   }
   useEffect(() => {
      isMounted.current = true
      return () => isMounted.current = false
   }, [])
   console.log(rounds_awards)
   return (
      <Grid container style={awardContainerStyle}>
         <Grid container alignItems='center' justifyContent='space-between' style={titleAndMinPointsContainerStyle}>
            <NunitoText value={`Round ${count + 1}`} fontSize={20} fontWeight={600} italic textDecoration='underline'
               marginBottom={20} />
            <div style={minAndUseForAllDivStyle}>
               <NunitoText value='Minimum points' fontSize={20} marginRight={15} />
               <ReusableTextField type='default' width={250} placeholder='Minimum Points' state={minPoints}
                  setState={setMinPoints} marginRight={20} number />
               <ReusableButton text='Apply to all' height={48} bgColor='#5E75C3' fontSize={16}
                  onClick={() => updateRound('applyMinimumPoints', minPoints)} />
            </div>
         </Grid>
         <Grid container>
            {rounds_awards&&rounds_awards.map((award, index) => (
               <RoundAward key={index} count={count} awardCount={index} original={original} setOriginal={setOriginal}
                  competition={competition} setCompetition={setCompetition}
                  setLoading={setLoading} openDeleteModal={openDeleteModal} isMounted={isMounted} />
            ))}
         </Grid>
         <Grid container justifyContent='flex-end' style={defaultContainerStyle}>
            <ReusableButton text='Add Award' height={48} bgColor='#5E75C3' fontSize={16}
               onClick={() => setOnAddAward(true)} iconType='add' />
         </Grid>
         <Modal open={onAddAward} onClose={() => cancelAddRoundAward()}>
            <>
               <AddRoundAwardsModal setLoading={setLoading} round={competition.rounds[count]}
                  cancelAddRoundAward={cancelAddRoundAward} afterAddingRoundAward={afterAddingRoundAward}
                  isMounted={isMounted} />
            </>
         </Modal>
         <Modal open={onDelete} onClose={() => cancelDelete()}>
            <>
               <DeleteModal table={{ data: rounds_awards }} id={id} selected={[]}
                  deleteFunction={deleteFunction} cancelDelete={cancelDelete} />
            </>
         </Modal>
      </Grid>
   )
}
const containerStyle = { paddingBottom: 20 }
const radioGroupContainerStyle = {
   marginTop: -180, height: 150, marginLeft: 750
}
const radioGroupStyle = {
   display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: 30,
   backgroundColor: '#FFF', width: 450, border: '1px solid', borderRadius: 16, marginLeft: 36
}
const radioStyle = { color: '#000' }
const levelsAndAwardSettingsContainerStyle = { marginTop: 40, paddingInline: 36 }
const optionContainerStyle = {
   border: '1px solid #707070', borderRadius: 12, padding: '10px 20px', marginBottom: 30,
   boxShadow: '5px 5px 12px rgba(0, 0, 0, 16%)'
}
const centerFlexStyle = { display: 'flex', alignItems: 'center' }
const defaultAwardDivStyle = { marginRight: 20 }
const saveButtonContainerStyle = { paddingRight: 36, marginBottom: 30 }
const Round = ({ count, original, setOriginal, competition, setCompetition, collectionOptions, setLoading, isMounted }) => {
   const [expand, setExpand] = useState(false)
   const [deletedLevels, setDeletedLevels] = useState([])
   const { id, name, round_type, team_setting, individual_points, levels,
      award_type, assign_award_points, default_award_name, default_award_points } = competition.rounds[count]
   const { enqueueSnackbar, closeSnackbar } = useSnackbar()
   const updateRounds = (identifier, value) => {
      let newCompetition = window.structuredClone(competition)
      switch (identifier) {
         case 'addLevel':
            newCompetition.rounds[count].levels.push({
               name: '', collection_id: '', grades: []
            })
            break;
         case 'deleteLevel':
            let levelId = newCompetition.rounds[count].levels[value].id
            if (levelId !== undefined) setDeletedLevels([...deletedLevels, levelId])
            newCompetition.rounds[count].levels.splice(value, 1)
            break;
         default:
            newCompetition.rounds[count][identifier] = value
            if (identifier === 'round_type' && !value) {
               newCompetition.rounds[count].individual_points = false
               newCompetition.rounds[count].team_setting = ''
            }
      }
      setCompetition(newCompetition)
   }
   const onSubmit = () => {
      if (isUnchanged(original.rounds[count], competition.rounds[count])) {
         return showWarningSwal('Please make some changes before submitting')
      }
      let msg = ''
      if (name === '') msg += `Round ${count + 1}'s name is empty.<br>`
      if (levels.length) {
         for (let j = 0; j < levels.length; j++) {
            const level = levels[j]
            if (level.name === '') msg += `Round ${count + 1} Level ${j + 1}'s name is empty.<br>`
            if (level.collection_id === '') msg += `Round ${count + 1} Level ${j + 1}'s collection is empty.<br>`
            if (level.grades.length === 0) msg += `Round ${count + 1} Level ${j + 1}'s grades are empty.<br>`
         }
      } else msg += `Round ${count + 1} must have at least one level.<br>`
      if (default_award_name === '') msg += `Round ${count + 1}'s default award label is empty.<br>`
      if (assign_award_points && default_award_points === '') {
         msg += `Round ${count + 1}'s default award points cannot be empty `
         msg += `if you are assigning points manually for this <br>`
      }
      if (msg) return showWarningSwal(`<div style="text-align:left">${msg}</div>`)
      actuallySubmit()
   }
   const actuallySubmit = () => {
      let payload = {
         id: id,
         name: name,
         round_type: Number(round_type),
         team_setting: Number(team_setting),
         individual_points: Number(individual_points),
         delete: deletedLevels,
         levels: levels.map(level => ({
            id: level.id,
            name: level.name,
            collection_id: level.collection_id,
            grades: level.grades
         })),
         award_type: Number(award_type),
         assign_award_points: Number(assign_award_points),
         default_award_name: default_award_name,
         default_award_points:Number(assign_award_points)
      }
      console.log(payload)

      setLoading(true)
      patchCompetitionRound(payload).then(d => {
         if (d.status === 200) {
            showNotification('success', d.message, enqueueSnackbar, closeSnackbar)
            if (isMounted.current) {
               let newCompetition = window.structuredClone(competition)
               setCompetition(newCompetition)
               setOriginal(deepCopy(newCompetition))
            }
         }
      }).catch(e => console.log(e)).finally(() => isMounted.current && setLoading(false))
   }
   return (
      <Grid container>
         <TitleBar title={name} state={expand} setState={setExpand} />
         {expand && <AddPageWrapper>
            <Grid container>
               <InputContainer first label='Round Label*'
                  field={<ReusableTextField type='default' placeholder='Round Label*' state={name} setState={updateRounds}
                     target='name' bgColor='#F2F2F2' borderColor='#707070' required onBlur fullWidth />} />
               {round_type ? <>
                  <InputContainer label='Competition Configuration'
                     field={<ReusableToggle type='text' width={270} falseValue='Individual' trueValue='Team'
                        state={round_type} setState={updateRounds} target='round_type' />} />
                  <InputContainer last label='Contribute to individual score?'
                     field={<ReusableToggle type='icon' width={124} state={individual_points}
                        setState={updateRounds} target='individual_points' />} />
               </> : <InputContainer label='Competition Configuration'
                  field={<ReusableToggle type='text' width={270} falseValue='Individual' trueValue='Team'
                     state={round_type} setState={updateRounds} target='round_type' />} />}

               {!!round_type && <Grid container style={radioGroupContainerStyle}>
                  <RadioGroup style={radioGroupStyle} value={team_setting}
                     onChange={e => updateRounds('team_setting', e.target.value)}>
                     <FormControlLabel value="0" control={<Radio style={radioStyle} />}
                        label="1 Account answers tasks" />
                     <FormControlLabel value="1" control={<Radio style={radioStyle} />}
                        label="Tasks assigned by leader" />
                     <FormControlLabel value="2" control={<Radio style={radioStyle} />}
                        label="Free For All" />
                  </RadioGroup>
               </Grid>}
               <Grid container style={levelsAndAwardSettingsContainerStyle}>
                  <Grid container>
                     <NunitoText value='Levels' fontSize={20} fontWeight={600} marginBottom={10} align='left' />
                  </Grid>
                  {levels.map((level, index) => (
                     <Level key={index} count={count} levelCount={index} competition={competition}
                        setCompetition={setCompetition} updateRounds={updateRounds} collectionOptions={collectionOptions} />
                  ))}
                  <ReusableButton text='Add Level' bgColor='#5E75C3' fontSize={15} allowSpam
                     onClick={() => updateRounds('addLevel')} iconType='add' />
               </Grid>
               <Grid container style={levelsAndAwardSettingsContainerStyle}>
                  <Grid container>
                     <NunitoText value='Award Settings' fontSize={20} fontWeight={600} marginBottom={10} align='left' />
                  </Grid>
                  <Grid container alignItems='center' justifyContent='space-between' style={optionContainerStyle}>
                     <ReusableToggle type='text' width={270} falseValue='percentile' trueValue='Position'
                        state={award_type} setState={updateRounds} target='award_type' />
                     <div style={centerFlexStyle}>
                        <NunitoText value='Assign points manually' fontSize={20} marginRight={15} />
                        <ReusableToggle type='icon' width={124} state={assign_award_points} setState={updateRounds}
                           target='assign_award_points' />
                     </div>
                     <div style={defaultAwardDivStyle}>
                        <NunitoText value='Default Award*' fontSize={20} fontWeight={600} align='left' />
                        <ReusableTextField type='default' width={280} placeholder='Label*' state={default_award_name}
                           setState={updateRounds} target='default_award_name' required onBlur />
                     </div>
                     <div style={{ visibility: !assign_award_points && 'hidden' }}>
                        <NunitoText value='Default Points*' fontSize={20} fontWeight={600} align='left' />
                        <ReusableTextField type='default' width={280} placeholder='Enter default points*' state={default_award_points}
                           setState={updateRounds} target='default_award_points' required onBlur number />
                     </div>
                  </Grid>
                  <Award key={count} count={count} original={original} setOriginal={setOriginal}
                  competition={competition} setCompetition={setCompetition} setLoading={setLoading} />
               </Grid>
               <Grid container justifyContent='flex-end' style={saveButtonContainerStyle}>
                  <ReusableButton text='Save' bgColor='#5E75C3' fontSize={15}
                     onClick={() => onSubmit()} iconType='save' />
               </Grid>
            </Grid>
         </AddPageWrapper>}
      </Grid>
   )
}
export default function Rounds({ original, setOriginal, competition, setCompetition, collectionOptions }) {
   const isMounted = useRef(false)
   const [expand, setExpand] = useState(true)
   const [loading, setLoading] = useState(false)
   const [onAddRound, setOnAddRound] = useState(false)
   const [onDelete, setOnDelete] = useState(false)
   const [id, setId] = useState('')
   const cancelAddRound = () => cancelAction(setOnAddRound)
   const cancelDelete = () => cancelAction(setOnDelete, setId)
   const openDeleteModal = id => {
      setId(id)
      setOnDelete(true)
   }
   const { enqueueSnackbar, closeSnackbar } = useSnackbar()
   const afterAdd = (cancel, data, rounds) => {
      if ([200, 201].includes(data.status)) {
         cancel()
         showNotification('success', data.message, enqueueSnackbar, closeSnackbar)
         if (isMounted.current) {
            let newCompetition = window.structuredClone(competition)
            const getRndInteger = (min, max) => Math.floor(Math.random() * (max - min)) + min
            for (const round of rounds) {
               const { name, round_type, team_setting, individual_points, levels,
                  award_type, assign_award_points, default_award_name, default_award_points } = round
               newCompetition.rounds.push({
                  // id: data.id[count],
                  id: getRndInteger(200, 500),
                  name: name,
                  round_type: round_type,
                  team_setting: team_setting,
                  individual_points: individual_points,
                  levels: levels,
                  award_type: !!award_type,
                  assign_award_points: !!assign_award_points,
                  default_award_name: default_award_name,
                  default_award_points: default_award_points,
                  awards: [] //TODO: MUST REMOVE AFTERWARDS
               })
            }
            setCompetition(newCompetition)
            setOriginal(deepCopy(newCompetition))
         }
      }
   }
   const deleteFunction = () => {

   }
   useEffect(() => {
      isMounted.current = true
      return () => isMounted.current = false
   }, [])
   return (
      <Grid container>
         <LoadingBackdrop loading={loading} />
         <TitleBar title='Rounds' state={expand} setState={setExpand}>
            <ReusableButton text='Add Round' bgColor='#5E75C3' height={46} fontSize={16} marginRight={40}
               onClick={() => setOnAddRound(true)} iconType='add' />
         </TitleBar>
         {expand && <AddPageWrapper>
            <Grid container style={containerStyle}>
               {competition.rounds.map((round, index) => (
                  <Round key={round.id} count={index} original={original} setOriginal={setOriginal}
                     competition={competition} setCompetition={setCompetition} collectionOptions={collectionOptions}
                     setLoading={setLoading} openDeleteModal={openDeleteModal} isMounted={isMounted} />
               ))}
            </Grid>
         </AddPageWrapper>}
         <Modal open={onAddRound} onClose={() => cancelAddRound()}>
            <>
               <AddRoundModal setLoading={setLoading} collectionOptions={collectionOptions} competition={competition}
                  cancelAddRound={cancelAddRound} afterAddingRound={afterAdd} />
            </>
         </Modal>
         <Modal open={onDelete} onClose={() => cancelDelete()}>
            <>
               <DeleteModal table={{ data: competition.rounds }} id={id} selected={[]}
                  deleteFunction={deleteFunction} cancelDelete={cancelDelete} />
            </>
         </Modal>
      </Grid>
   )
}