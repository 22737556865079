import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Box, Grid } from '@mui/material'
import { addMarkingPreparation } from '../../functions/postData'
import { showWarningSwal } from '../../functions/alert'
import CloseIcon from '@mui/icons-material/Close'
import NunitoText from '../general/NunitoText'
import ReusableAutocompleteWithID from '../general/ReusableAutocompleteWithID'
import ReusableButton from '../general/ReusableButton'
const boxStyle = { width: 1200, minHeight: 400, maxHeight: '90%' }
const closeIconDivStyle = { display: 'flex', justifyContent: 'flex-end', marginTop: -42 }
const closeIconStyle = { fontSize: 36, cursor: 'pointer' }
const fieldDivStyle = { display: 'flex', justifyContent: 'center' }
const buttonContainerStyle = { marginBlock: 20 }
export default function AddLevelModal({ setOnAddLevel, levels, setLevels, availableCountries, levelOptions,
   setLoading, cancelAddLevel, afterAddingLevel }) {
   const isMounted = useRef(false)
   const [level, setLevel] = useState('')
   const [countries, setCountries] = useState([])
   const countryOptions = useSelector(state => state.countryOptions)
   const controller = useMemo(() => new AbortController(), [])
   //  const signal = controller.signal
   const onChangeLevel = value => {
      setLevel(value)
      setCountries([])
   }
   const onSubmit = () => {
      let warningMessage = ''
      if (level === '') warningMessage += 'Please select a level.<br>'
      if (!countries.length) warningMessage += 'Please select at least one country.<br>'
      if (warningMessage) return showWarningSwal(warningMessage)
      let payload = {
         "level_id": level,
         "countries": countries
      }
      console.log(JSON.stringify(payload))
      setLoading(true)
      addMarkingPreparation(payload).then(d => {
         isMounted.current && setLoading(false)
         window.location.reload();
         afterAddingLevel(cancelAddLevel, d)
      }).catch(e => console.log(e)).finally(() => isMounted.current & setLoading(false))
   }
   const countriesInLevel = availableCountries.find(c => c.level_id === level)?.Countries ?
      Object.keys(availableCountries.find(c => c.level_id === level)?.Countries).map(k => Number(k)) : []
   const selectableCountries = countryOptions.filter(c =>
      countriesInLevel.includes(c.id) && !levels.filter(l => l.level_id === level).map(l => l.country_group).flat().includes(c.id)
   )
   useEffect(() => {
      isMounted.current = true
      return () => {
         controller.abort()
         isMounted.current = false
      }
   }, [controller])
   return (
      <Box className='popUpModal horizontalScrollable' style={boxStyle}>
         <div>
            <NunitoText value='Add Level' fontSize={30} fontWeight={600} marginTop={20} />
            <div style={closeIconDivStyle}>
               <CloseIcon style={closeIconStyle} onClick={() => setOnAddLevel(false)} />
            </div>
         </div>
         <div style={fieldDivStyle}>
            <ReusableAutocompleteWithID type='withLabel' width={400} bgColor='#FFF' borderColor='#707070'
               state={level} setState={onChangeLevel} placeholder='Level*'
               options={levelOptions.map(l => ({ id: l.id, option: l.name }))} required />
         </div>
         <div style={fieldDivStyle}>
            <ReusableAutocompleteWithID type='withLabel' width={400} bgColor='#FFF' borderColor='#707070'
               state={countries} setState={setCountries} placeholder='Countries*' multiple
               readOnly={!level} grayedOut={!level}
               options={selectableCountries.map(c => ({ id: c.id, option: c.display_name }))} required />
         </div>
         <Grid container justifyContent='flex-end' style={buttonContainerStyle}>
            <ReusableButton text='Save' fontSize={15} bgColor='#5E75C3' height={46}
               onClick={() => onSubmit()} iconType='save' />
         </Grid>
      </Box>
   )
}