import React, { useState, useEffect, useMemo, useRef } from 'react'
import { Box, Grid, Modal } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { showWarningSwal } from '../functions/alert'
import { getMarkingPreparation, getLevelCountries } from '../functions/getData'
import { patchMarkingPreparation } from '../functions/patchData'
import { showNotification } from '../functions/snackbar'
import { cancelAction } from '../functions/general'
import CloseIcon from '@mui/icons-material/Close'
import { FaLayerGroup } from 'react-icons/fa'
import AddLevelModal from '../components/MarkingPreparation/AddLevelModal'
import DeleteModal from '../components/modal/DeleteModal'
import NunitoText from '../components/general/NunitoText'
import ReusableAutocompleteWithID from '../components/general/ReusableAutocompleteWithID'
import ReusableButton from '../components/general/ReusableButton'
import ReusableTextField from '../components/general/ReusableTextField'
import Loader from '../components/general/Loader'
import NoData from '../components/general/NoData'
import Widget from '../components/general/Widget'
import TitleBar from '../components/general/TitleBar'
import AddPageWrapper from '../components/general/AddPageWrapper'
import LoadingBackdrop from '../components/general/LoadingBackdrop'
import { useSnackbar } from 'notistack'
const Level = ({ count, level, levels, setLevels, availableCountries, levelOptions, setLoading, openDeleteModal, isMounted }) => {
   const { id, level_id, country_group, school_participants,school_participants_answers_count,
      private_participants, private_participants_answers_count, total_participants } = level
   const countryOptions = useSelector(state => state.countryOptions)
   const gridStyle = { padding: '0 24px 24px 24px' }
   const divStyle = { borderRadius: 10, boxShadow: '0 0 5px #9E9E9E', padding: 24 }
   const flexStyle = { display: 'flex', alignItems: 'center', justifyContent: 'center' }
   const levelIconStyle = { fontSize: 30, color: '#706FA7' }
   const closeIconDivStyle = { display: 'flex', justifyContent: 'flex-end', marginTop: -42 }
   const closeIconStyle = { fontSize: 36, cursor: 'pointer' }
   const buttonContainerStyle = { marginTop: 20 }
   const onChangeCountries = value => {
      let newLevels = window.structuredClone(levels)
      newLevels[count].country_group = value
      setLevels(newLevels)
   }
   const { enqueueSnackbar, closeSnackbar } = useSnackbar()
   const onSubmit = () => {
      // let payload = {
      //    id, country_id: country_group
      // }
      setLoading(true)
      setTimeout(() => {
         showNotification('success', 'Update marking preparation successful(NO API, FRONTEND SIMULATED)', enqueueSnackbar, closeSnackbar)
         setLoading(false)
      }, 1000)
      // patchMarkingPreparation(payload).then(d => {
      //    if (d.status === 200) {
      //       showNotification('success', d.message, enqueueSnackbar, closeSnackbar)
      //    }
      // }).catch(e => console.log(e)).finally(() => isMounted.current && setLoading(false))
   }
   console.log(level)
   const countriesInLevel = availableCountries.find(c => c.level_id === level_id)?.Countries ?
      Object.keys(availableCountries.find(c => c.level_id === level_id)?.Countries).map(k => Number(k)) : []
   const selectableCountries = countryOptions.filter(c =>
      countriesInLevel.includes(c.id) &&
      !levels.filter(l => id !== l.id && l.level_id === level_id).map(l => l.country_group).flat().includes(c.id)
   )
   return (
      <Grid item xs={6} style={gridStyle}>
         <div style={divStyle}>
            <div style={flexStyle}>
               <FaLayerGroup style={levelIconStyle} />
               <NunitoText value={levelOptions.find(l => l.id === level_id)?.name} fontSize={24} marginLeft={20} />
            </div>
            <div style={closeIconDivStyle}>
               <CloseIcon style={closeIconStyle} onClick={() => openDeleteModal(id)} />
            </div>
            <Grid container>
               <Grid item xs={5.8}>
                  <ReusableTextField type='withLabel' placeholder='School Participants'
                     state={school_participants} grayedOut readOnly fullWidth />
               </Grid>
               <Grid item xs={0.4} />
               <Grid item xs={5.8}>
                  <ReusableTextField type='withLabel' placeholder='Answers Uploaded'
                     state={school_participants_answers_count} grayedOut readOnly fullWidth />
               </Grid>
            </Grid>
            <Grid container>
               <Grid item xs={5.8}>
                  <ReusableTextField type='withLabel' placeholder='Private Participants'
                     state={private_participants} grayedOut readOnly fullWidth />
               </Grid>
               <Grid item xs={0.4} />
               <Grid item xs={5.8}>
                  <ReusableTextField type='withLabel' placeholder='Answers Uploaded'
                     state={private_participants_answers_count} grayedOut readOnly fullWidth />
               </Grid>
            </Grid>
            <ReusableAutocompleteWithID type='withLabel' width='100%' bgColor='#FFF' borderColor='#707070'
               state={country_group} setState={onChangeCountries} placeholder='Countries*' multiple
               options={selectableCountries.map(c => ({ id: c.id, option: c.display_name }))} required />
            <Grid container alignItems='flex-end'>
               <Grid item xs={5.8}>
                  <ReusableTextField type='withLabel' placeholder='Total Participants'
                     state={total_participants} grayedOut readOnly fullWidth />
               </Grid>
               <Grid item xs={6.2}>
                  <NunitoText value={`Status: Completed`} fontSize={20} color='#6FC9B6' align='right' />
               </Grid>
            </Grid>
            <Grid container justifyContent='flex-end' style={buttonContainerStyle}>
               <ReusableButton text='Save' fontSize={15} bgColor='#5E75C3' width={120} height={46}
                  onClick={() => onSubmit()} iconType='save' />
            </Grid>
         </div>
      </Grid>
   )
}
const containerStyle = { paddingInline: 30 }
const widgetContainerStyle = { padding: 24 }
export default function MarkingPreparation() {
   document.title = 'Marking Preparation'
   const isMounted = useRef(false)
   const [loading, setLoading] = useState(false)
   const [data, setData] = useState(false)
   const [loadingData, setLoadingData] = useState(false)
   const [firstLoaded, setFirstLoaded] = useState(false)
   const [onAddLevel, setOnAddLevel] = useState(false)
   const [onDelete, setOnDelete] = useState(false)
   const [id, setId] = useState('')
   const [competitionName, setCompetitionName] = useState('')
   const [format, setFormat] = useState('')
   const [levels, setLevels] = useState([])
   const [levelOptions, setLevelOptions] = useState([])
   const [availableCountries, setAvailableCountries] = useState([])
   const [round, setRound] = useState('')
   const [roundOptions, setRoundOptions] = useState([])
   const cancelAddLevel = () => cancelAction(setOnAddLevel)
   const cancelDelete = () => cancelAction(setOnDelete)
   const controller = useMemo(() => new AbortController(), [])
   const signal = controller.signal
   let location = useLocation()
   const openDeleteModal = id => {
      setId(id)
      setOnDelete(true)
   }
   const { enqueueSnackbar, closeSnackbar } = useSnackbar()
   useEffect(() => {
      isMounted.current = true
      if (location.search.slice(0, 4) === '?id=') {
         setLoadingData(true)
         getMarkingPreparation(`?competition_id=${location.search.slice(4)}`, signal).then(d => {
            if (isMounted.current) {
               setFirstLoaded(true)
               if (Array.isArray(d) && !d.length) return showWarningSwal('Competition not found')
               console.log(d)
               getLevelCountries(`?competition_id=${location.search.slice(4)}`).then(c => {
                  console.log(c)
                  console.log(c.map(f => f.levels).flat())
                  setAvailableCountries(c.map(f => f.levels).flat())
               }).catch(e => console.log(e))
               setCompetitionName(d.name)
               setFormat(d.format ? 'Global' : 'Local')
               let rounds = d.rounds.map(r => ({ id: r.id, name: r.name }))
               setRoundOptions(rounds)
               setRound(rounds[0].id)
               setData(d)
               setLevelOptions(d.rounds.find(r => r.id === rounds[0].id).levels.map(l => ({ id: l.id, name: l.name })))
               setLevels(d.rounds.find(r => r.id === rounds[0].id).levels.map(l =>
                  l.groups.map(g => ({ ...g, level_id: l.id }))).flat()
               )
               setLoadingData(false)
            }
         }).catch(e => {
            if (!signal.aborted && isMounted.current) {
               setFirstLoaded(true)
               setLoadingData(false)
            }
         })
      } else {
         showWarningSwal('Wrong query')
      }
      return () => {
         controller.abort()
         isMounted.current = false
      }
   }, [location.search, controller, signal])
   const onChangeRound = value => {
      if (value !== '') {
         setRound(value)
         setLevelOptions(data.rounds.find(r => r.id === value).levels.map(l => ({ id: l.id, name: l.name })))
         setLevels(data.rounds.find(r => r.id === value).levels.map(l =>
            l.groups.map(g => ({ ...g, level_id: l.id }))).flat()
         )
      }
   }
   const afterAdd = (cancel, data) => {
      if ([200, 201].includes(data.status)) {
         cancel()
         showNotification('success', data.message, enqueueSnackbar, closeSnackbar)
         if (isMounted.current) {
            let newLevels = window.structuredClone(levels)
            for (const group of data.data) {
               newLevels.push(group)
            }
            setLevels(newLevels)
         }
      }
   }
   const deleteFunction = () => {
      setLoading(true)
      // let payload = { id: id }
      // deleteMarkingPreparation(payload).then(d => {
      //    if (d.status === 200) {
      //       cancelDelete()
      //       showNotification('success', d.message, enqueueSnackbar, closeSnackbar)
      //       if (isMounted.current) {
      //          setLevels(levels.filter(l => l.id !== id))
      //       }
      //    }
      // }).catch(e => console.log(e)).finally(() => isMounted.current && setLoading(false))

      setTimeout(() => {
         setLevels(levels.filter(l => l.id !== id))
         cancelDelete()
         showNotification('success', 'Delete marking preparation successful(NO API, FRONTEND SIMULATED)', enqueueSnackbar, closeSnackbar)
         setLoading(false)
      }, 1000)
   }
   return (
      <Box className='wrapperBox'>
         <LoadingBackdrop loading={loading} />
         <div className='firstRowContainer dashboardAndSelfBtnDiv'>
            <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard' iconType='home' />
            <ChevronRightIcon />
            <ReusableButton text='Competitions' fontSize={14} bgColor='#F16774' height={36} br={18} to='/competitions' />
         </div>
         <Grid className='tableContainer' container>
            <NunitoText value='Marking Preparation' fontSize={40} fontWeight={700} italic color='#144A94' />
            <TitleBar fixed main>
               {roundOptions.length > 1 && <ReusableAutocompleteWithID type='default' width={400} height={50} bgColor='#FFF'
                  borderColor='#707070' state={round} setState={onChangeRound} placeholder='Round' marginRight={20}
                  options={roundOptions.map(r => ({ id: r.id, option: r.name }))} required />}
               {format === 'Local' && <ReusableButton text='Add group' fontSize={15} bgColor='#5E75C3' height={46}
                  marginRight={60} onClick={() => setOnAddLevel(true)} iconType='add' />}
            </TitleBar>
            <AddPageWrapper main>
               <Grid container style={containerStyle}>
                  {loadingData && <Loader height={110} />}
                  <Grid container style={widgetContainerStyle}>
                     {competitionName && <Widget title='Competition' content={competitionName} marginRight={28} />}
                     {format && <Widget title='Format' content={format} marginRight={28} />}
                     {round !== '' && <Widget title='Round'
                        content={roundOptions.find(r => r.id === round).name} marginRight={28} />}
                  </Grid>
                  {!loadingData && firstLoaded && !levels.length &&
                     <NoData height={600} text='No levels for this round' />}
                  {levels.map((level, i) => (
                     <Level key={i} count={i} level={level} levels={levels} setLevels={setLevels}
                        availableCountries={availableCountries} levelOptions={levelOptions}
                        setLoading={setLoading} openDeleteModal={openDeleteModal} isMounted={isMounted} />
                  ))}
               </Grid>
            </AddPageWrapper>
         </Grid>
         {format === 'Local' && <Modal open={onAddLevel} onClose={() => cancelAddLevel()}>
            <>
               <AddLevelModal setOnAddLevel={setOnAddLevel} levels={levels} setLevels={setLevels}
                  availableCountries={availableCountries} levelOptions={levelOptions} setLoading={setLoading}
                  cancelAddLevel={cancelAddLevel} afterAddingLevel={afterAdd} />
            </>
         </Modal>}
         <Modal open={onDelete} onClose={() => cancelDelete()}>
            <>
               {levels && <DeleteModal table={{ data: levels.map(l => ({ ...l, name: levelOptions.find(o => o.id === l.level_id)?.name })) }}
                  id={id} selected={[]}
                  deleteFunction={deleteFunction} cancelDelete={cancelDelete} />}
            </>
         </Modal>
      </Box>
   )
}