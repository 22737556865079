import { deleteMethod } from './httpMethods'
// School
export const deleteSchool = data => {
   return new Promise((resolve, reject) => {
      deleteMethod('school', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
// Organization
export const deleteOrganization = data => {
   return new Promise((resolve, reject) => {
      deleteMethod('organizations', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
// User
export const deleteUser = data => {
   return new Promise((resolve, reject) => {
      deleteMethod('user', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
// Participant
export const deleteParticipant = data => {
   return new Promise((resolve, reject) => {
      deleteMethod('participant', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
// Domain and Tag
export const deleteDomain = data => {
   return new Promise((resolve, reject) => {
      deleteMethod('tag', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
// Competition
export const deleteCompetition = data => {
   return new Promise((resolve, reject) => {
      deleteMethod('competition', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
export const deleteCompetitionOrganization = data => {
   return new Promise((resolve, reject) => {
      deleteMethod('competition/organization', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
export const deleteCompetitionRound = data => {
   return new Promise((resolve, reject) => {
      deleteMethod('competition/rounds', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
export const deleteCompetitionRoundAward = data => {
   return new Promise((resolve, reject) => {
      deleteMethod('competition/awards', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
export const deleteCompetitionOverallAward = data => {
   return new Promise((resolve, reject) => {
      deleteMethod('competition/overall_awards', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
// Task
export const deleteTask = data => {
   return new Promise((resolve, reject) => {
      deleteMethod('task', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
// Difficulty Group
export const deleteDifficultyGroup = data => {
   return new Promise((resolve, reject) => {
      deleteMethod('taskdifficultygroup ', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
// Collection
export const deleteCollection = data => {
   return new Promise((resolve, reject) => {
      deleteMethod('collection', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
export const deleteSection = data => {
   return new Promise((resolve, reject) => {
      deleteMethod('collection/section', data).then(d => resolve(d)).catch(e => reject(e))
   })
}