import React from 'react'
import DatePicker from "react-multi-date-picker"
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import { dateHasPassed } from '../../functions/general'
export default function ReusableDatePicker({ type, width, 
    marginLeft, marginRight, marginTop, marginBottom,
    state, setState, required, disabled, 
    count, head, target,
    // For ReusableDatePicker
    range, minDate, maxDate, multiple, allowExceedMinMax, allowEmpty }) {
    let errorBorder = () => {
        if (allowExceedMinMax) return false
        if (multiple) {
            if (minDate) {
                for (let i = 0; i < state.length; i++) {
                    if (dateHasPassed(new Date(state[i]), minDate)) return true
                }
            }
            if (maxDate) {
                for (let i = 0; i < state.length; i++) {
                    if (dateHasPassed(maxDate, new Date(state[i]))) return true
                }
            }
        } else {
            if (minDate && dateHasPassed(new Date(state), minDate)) return true
            if (maxDate && dateHasPassed(maxDate, new Date(state))) return true
        }
        return false
    }
    const onChangeState = value => {
        // Force re-render so as to ensure there is at least one date for multiple date
        if (multiple && value.length === 0 && !allowEmpty) value = [...state]
        if (!multiple && allowEmpty && value === null) value = '0000-00-00 00:00:00'
        // Set all hours and minutes to 00:00:00
        if (multiple === undefined) value = new Date(value).setHours(0, 0, 0, 0)
        else value = value.map(v => new Date(v).setHours(0, 0, 0, 0))

        if (type === 'redux') setState(count, head, target, '', value)
        else {
            if (count !== undefined && target !== undefined) setState(count, target, value)
            else {
                if (count !== undefined) setState(count, value)
                else if (target !== undefined) setState(target, value)
                else setState(value)
            }
        }
    }
    const newDatePickerStyle = {
        width, marginLeft, marginRight, marginTop, marginBottom,
        paddingLeft: 16,
        height: 55,
        borderRadius: 10,
        border: `1px solid ${(required && state === null) || errorBorder() ? '#E83D4D' : '#707070'}`,
        backgroundColor: disabled ? '#707070' : '#FFF',
        color: disabled && '#FFF',
        fontSize: state !== null ? 18 : 28
    }
    const newDatePickerAttributes = {
        value: state,
        onChange: e => onChangeState(e),
        format: 'DD/MM/YYYY',
        placeholder: '--/--/----',
        style: newDatePickerStyle,
        disabled: disabled,
        editable: false,
        plugins: allowEmpty && [<DatePanel />],
        range
    }
    if (multiple) {
        newDatePickerAttributes.multiple = true
        newDatePickerAttributes.sort = true
        newDatePickerAttributes.plugins = [<DatePanel />]
    }
    if (minDate) newDatePickerAttributes.minDate = minDate.setHours(0, 0, 0, 0)
    if (maxDate) newDatePickerAttributes.maxDate = maxDate.setHours(0, 0, 0, 0)
    return <DatePicker {...newDatePickerAttributes} />
}