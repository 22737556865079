import React, { useState, useEffect, useMemo } from 'react'
import { Grid, Modal, Box } from '@mui/material'
import { showWarningSwal } from '../../functions/alert'
import { makeSureIsArray, changeSearch, changeRowsPerPage, loadData, cancelAction } from '../../functions/general'
import { getTasks } from '../../functions/getData'
import NunitoText from '../general/NunitoText'
import ReusableTextField from '../general/ReusableTextField'
import ReusableButton from '../general/ReusableButton'
import Widget from '../general/Widget'
import HeavyTable from '../table/HeavyTable'
import Loader from '../general/Loader'
import NoData from '../general/NoData'
import ConfirmAddTaskModal from '../modal/ConfirmAddTaskModal'
import DeleteTaskFromCollectionModal from '../modal/DeleteTaskFromCollectionModal'
const taskHeader = [
   { id: 'title', label: 'Task Title' },
   { id: 'status', label: 'Status' },
   { id: 'languages', label: 'No. of Language(s)' },
   { id: 'domain', label: 'Domain' },
   { id: 'tags', label: 'Tags' },
   { id: 'grade_difficulty', label: 'Recommended Difficulty' },
   { id: 'answer_type', label: 'Answer Type' },
   { id: 'answer_structure', label: 'Answer Structure' },
   { id: 'answer_sorting', label: 'Display Order' },
   { id: 'task_answers', label: 'No. of Correct Answers' }
]
const initialParams = '?limits=5'
const boxStyle = { width: 1650, height: 850, maxHeight: '90%' }
const widgetsStyle = { display: 'flex', marginTop: -30 }
const centerFlexStyle = { display: 'flex', alignItems: 'center', justifyContent: 'center' }
export default function AddTaskToSectionModal({ collection, section, count, sectionCount, groupCount, taskCount,
   update, cancelAddTask, edit }) {
   const header = taskHeader
   const [taskTable, setTaskTable] = useState()
   const [rowsPerPage, setRowsPerPage] = useState(5)
   const [copy, setCopy] = useState(rowsPerPage)
   const [search, setSearch] = useState('')
   const [params, setParams] = useState(initialParams)
   const [loading, setLoading] = useState(false)
   const [firstLoaded, setFirstLoaded] = useState(false)
   const [id, setId] = useState('')
   const [selected, setSelected] = useState([])
   const [selecting, setSelecting] = useState([])
   const [onAdd, setOnAdd] = useState(false)
   const [onDelete, setOnDelete] = useState(false)
   const cancelAdd = () => cancelAction(setOnAdd, setId)
   const cancelDelete = () => cancelAction(setOnDelete, setId)
   const getData = (params, signal) => {
      loadData(setLoading, setFirstLoaded, getTasks, params, setTaskTable, false, false, signal)
   }
   const onChangeRowsPerPage = value => {
      changeRowsPerPage(value, rowsPerPage, [], search, signal, setRowsPerPage, setParams, getData)
   }
   const onChangeSearch = value => {
      changeSearch(value, search, [], rowsPerPage, signal, setSearch, setParams, getData)
   }
   const onChangePage = pageNo => getData(`${params}&page=${pageNo}`, signal)
   const onClickAdd = () => {
      if (!selected.length) return showWarningSwal('Please select at least one task(s)')
      if (collectionAlrContainsTask(collection, selected)) {
         let msg = ''
         selected.forEach(task => {
            let index = checkTask(collection, task)
            if (index !== -1) {
               msg += `${makeSureIsArray(taskTable.data).find(t => t.id === task).title} is already in the collection, 
                        in Section ${index + 1}.<br>`
            }
         })
         return showWarningSwal(msg)
      }
      return setOnAdd(true)
   }
   const onClickMassDelete = () => {
      if (!selected.length) return showWarningSwal('Please select at least one task(s)')
      let msg = ''
      selected.forEach(task => {
         // if (checkTask(collection, task) === -1) {
         //     msg += `${makeSureIsArray(taskTable.data).find(t => t.id === task).title} is not in the collection.<br>`
         // }
         if (taskInSection(section, task) === -1) {
            msg += `${makeSureIsArray(taskTable.data).find(t => t.id === task).title} is not in the section.<br>`
         }
      })
      if (msg) return showWarningSwal(`${msg}Please select only tasks that are in the section to delete.`)
      return setOnDelete(true)
   }
   const controller = useMemo(() => new AbortController(), [])
   const signal = controller.signal
   useEffect(() => {
      getData(initialParams, signal)
      return () => controller.abort()
   }, [controller, signal])
   const collectionAlrContainsTask = (collection, tasks) => {
      let tasksInCollection = collection.sections.map(s => s.tasks).flat(2).map(t => t.id)
      // for (let l = 0; l < tasks.length; l++) {
      //     if (tasksInCollection.includes(tasks[l])) return true
      // }
      // for (let i = 0; i < collection.sections.length; i++) {
      //     for (let j = 0; j < collection.sections[i].tasks.length; j++) {
      //         for (let k = 0; k < collection.sections[i].tasks[j].length; k++) {
      //             for (let l = 0; l < tasks.length; l++) {
      //                 if (collection.sections[i].tasks[j].map(t => t.id).includes(tasks[l])) return true
      //             }
      //         }
      //     }
      // }
      return tasks.some(task => tasksInCollection.includes(task))
   }
   const checkTask = (collection, taskId) => {
      if (collection === undefined) return false
      let found = collection.sections.findIndex(s => s.tasks.find(tasks => tasks.map(t => t.id).includes(taskId)))
      return found
   }
   const taskInSection = (section, taskId) => {
      if (section === undefined) return false
      let found = section.tasks.findIndex(tasks => tasks.map(t => t.id).includes(taskId))
      return found
   }
   const addTasks = () => {
      let payload = id === ''
         ? selected.map(s => ({ id: s, title: makeSureIsArray(taskTable.data).find(t => t.id === s).title }))
         : [{ id, title: makeSureIsArray(taskTable.data).find(t => t.id === id).title }]
      if (groupCount !== '' && taskCount !== '') {
         if (edit) {
            update([sectionCount, groupCount, taskCount + 1], 'addTaskToGroup', payload)
         } else {
            update([count, sectionCount, groupCount, taskCount + 1], 'addTaskToGroup', 'tasks', payload)
         }
         setOnAdd(false)
         cancelAddTask()
      } else if (groupCount !== '') {
         if (edit) {
            update([sectionCount, groupCount + 1], 'addTaskGroup', payload)
         } else {
            update([count, sectionCount, groupCount + 1], 'addTaskGroup', 'tasks', payload)
         }
         setOnAdd(false)
         cancelAddTask()
      } else {
         if (edit) {
            update([sectionCount, section.tasks.length], 'addTaskGroup', payload)
         } else {
            update([count, sectionCount, section.tasks.length], 'addTaskGroup', 'tasks', payload)
         }
         setOnAdd(false)
         cancelAddTask()
      }
   }
   const removeTasks = () => {
      if (edit) {
         update(count, 'justRemoveTask', id === '' ? selected : [id])
      } else {
         update(count, 'justRemoveTask', 'tasks', id === '' ? selected : [id])
      }
      setOnDelete(false)
      cancelAddTask()
   }
   return (
      <Box className='popUpModal horizontalScrollable' style={boxStyle}>
         <div>
            <NunitoText value='Add Tasks to Section' fontSize={30} fontWeight={600} color='#5E75C3' marginTop={20} />
            <div style={widgetsStyle}>
               <Widget title='Section' content={sectionCount + 1} marginRight={28} />
               {groupCount !== '' && taskCount !== '' && <Widget title='Tasks Grouping' content={groupCount + 1} />}
            </div>
            <div style={centerFlexStyle}>
               <input value={copy} onChange={e => setCopy(Number(e.target.value))} />
               <button onClick={() => onChangeRowsPerPage(copy)}>Set Rows Per Page</button>
            </div>
            <Grid container alignItems='center' justifyContent='space-between'>
               <div>
                  <ReusableButton text='Add Task(s) to Section' fontSize={15} bgColor='#144A94' height={46}
                     onClick={() => onClickAdd()} iconType='add' marginRight={20} />
                  <ReusableButton text='Mass Delete' fontSize={15} bgColor='#E83042' height={46}
                     marginRight={20} onClick={() => onClickMassDelete()} iconType='delete' />
               </div>
               <ReusableTextField type='search' width={500} height={60} bgColor='#F2F2F2' placeholder='Search using keyword'
                  state={search} setState={onChangeSearch} onBlur />
            </Grid>
            <Grid container>
               {loading ? <Loader height={600} /> : taskTable && makeSureIsArray(taskTable.data).length ?
                  <HeavyTable headers={header} list={taskTable} title='add task to collection' rowsPerPage={rowsPerPage}
                     setOnDelete={setOnDelete} onChangePage={onChangePage} fixed={['identifier', 'title']}
                     selecting={selecting} setSelecting={setSelecting} selected={selected} setSelected={setSelected}
                     setId={setId} setOnAdd={setOnAdd}
                     checkTask={checkTask} taskInSection={taskInSection}
                     collection={collection} section={section} maxWidth={1600}
                  // collectionId={collectionId} sectionId={sectionId}
                  /> : firstLoaded && <NoData height={600} />}
            </Grid>
         </div>
         <Modal open={onAdd} onClose={() => cancelAdd()}>
            <>
               {Boolean(taskTable && sectionCount !== '') && <ConfirmAddTaskModal table={taskTable} id={id} selected={selected}
                  addFunction={addTasks} cancelAddTask={cancelAddTask} sectionCount={sectionCount} />}
            </>
         </Modal>
         <Modal open={onDelete} onClose={() => cancelDelete()}>
            <>
               {Boolean(taskTable && sectionCount !== '') && <DeleteTaskFromCollectionModal table={taskTable} id={id} selected={selected}
                  deleteFunction={removeTasks} cancelDelete={cancelDelete} sectionCount={sectionCount} />}
            </>
         </Modal>
      </Box>
   )
}