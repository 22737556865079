import React, { useState, useEffect } from 'react'
import { Button } from '@mui/material'
import { Link } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import CheckIcon from '@mui/icons-material/Check'
import DeleteIcon from '@mui/icons-material/Delete'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import PictureInPictureAltIcon from '@mui/icons-material/PictureInPictureAlt'
import HomeIcon from '@mui/icons-material/Home'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import CachedIcon from '@mui/icons-material/Cached'
import CloseIcon from '@mui/icons-material/Close'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import NotificationsSharpIcon from '@mui/icons-material/NotificationsSharp'
import { FaLock, FaUserSlash, FaUserCheck, FaUnlock, FaUndoAlt, FaSchool, FaPen, FaCalculator } from 'react-icons/fa'
import { FiEdit, FiDownload, FiSave } from 'react-icons/fi'
import languageIcon from '../../assets/images/Icon metro-language.png'
import fileIcon from '../../assets/images/fileIcon.png'
export default function ReusableButton({ text, color, bgColor, borderColor, height, fontSize,
   marginLeft, marginRight, marginTop, marginBottom,
   onClick, to, blank, width, br, iconType, fontWeight, allowSpam }) {
   const [disabled, setDisabled] = useState(false)
   let icon
   const iconStyle = {}
   switch (iconType) {
      case 'add': icon = <AddIcon style={iconStyle} />; break;
      case 'copy': icon = <ContentCopyIcon style={iconStyle} />; break;
      case 'check': icon = <CheckIcon style={iconStyle} />; break;
      case 'undo': icon = <FaUndoAlt style={iconStyle} />; break;
      case 'delete': icon = <DeleteIcon style={iconStyle} />; break;
      case 'lock': icon = <FaLock style={iconStyle} />; break;
      case 'unlock': icon = <FaUnlock style={iconStyle} />; break;
      case 'edit': icon = <FiEdit style={iconStyle} />; break;
      case 'home': icon = <HomeIcon style={iconStyle} />; break;
      case 'import': icon = <ArrowDownwardIcon style={iconStyle} />; break;
      case 'export': icon = <ArrowUpwardIcon style={iconStyle} />; break;
      case 'enable': icon = <FaUserCheck style={iconStyle} />; break;
      case 'disable': icon = <FaUserSlash style={iconStyle} />; break;
      case 'translate': icon = <img src={languageIcon} style={iconStyle} alt='translate' />; break;
      case 'preview': icon = <PictureInPictureAltIcon style={iconStyle} />; break;
      case 'upload': icon = <UploadFileIcon />; break;
      case 'download': icon = <FiDownload />; break;
      case 'file': icon = <img src={fileIcon} style={iconStyle} alt='file' />; break;
      case 'school': icon = <FaSchool style={iconStyle} />; break;
      case 'reset': icon = <CachedIcon style={iconStyle} />; break;
      case 'close': icon = <CloseIcon style={iconStyle} />; break;
      case 'logout': icon = <PowerSettingsNewIcon style={iconStyle} />; break;
      case 'profile': icon = <AccountCircleIcon style={iconStyle} />; break;
      case 'notification': icon = <NotificationsSharpIcon style={iconStyle} />; break;
      case 'save': icon = <FiSave style={iconStyle} />; break;
      case 'mark': icon = <FaPen style={iconStyle} />; break;
      case 'compute': icon = <FaCalculator style={iconStyle} />; break;
      default:
   }
   const btnStyle = {
      width: width,
      height: height,
      marginLeft: marginLeft,
      marginRight: marginRight,
      marginTop: marginTop,
      marginBottom: marginBottom,
      backgroundColor: bgColor,
      color: color || '#FFF',
      textTransform: 'none',
      borderRadius: br || 12,
      fontWeight: fontWeight || 'normal',
      fontSize: fontSize,
      padding: '10px 20px'
   }
   if (borderColor) btnStyle.border = `1px solid ${borderColor}`
   const onClickButton = () => {
      onClick()
      setDisabled(true)
   }
   const buttonAttributes = {
      style: btnStyle,
      startIcon: Boolean(icon) && icon,
      disabled
   }
   useEffect(() => {
      let isMounted = true
      if (disabled) {
         setTimeout(() => {
            if (isMounted) setDisabled(false)
         }, 1000)
      }
      return () => isMounted = false
   }, [disabled])
   if (onClick) buttonAttributes.onClick = () => allowSpam ? onClick() : onClickButton()
   return to ?
      <Link to={to} style={{ textDecoration: 'none' }} target={blank && '_blank'}>
         <Button {...buttonAttributes}>{text}</Button>
      </Link> :
      <Button {...buttonAttributes}>
         {text}
      </Button>
}