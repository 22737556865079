import React, { useState, useEffect } from 'react'
import { Table, TableRow, Grid, TableCell, TableContainer, TableBody, Paper, IconButton } from '@mui/material'
import { getComparator, stableSort } from '../../functions/table'
import { showNotification } from '../../functions/snackbar'
import EnhancedTableHead from './EnhancedTableHead'
import useWindowDimensions from '../../hooks/WindowDimensions'
import LightTooltip from '../general/LightTooltip'
import { FaCalculator } from 'react-icons/fa'
import { FiEdit } from 'react-icons/fi'
import NunitoText from '../general/NunitoText'
import { useSnackbar } from 'notistack'
import { Link } from 'react-router-dom'
import { patchMarkingOverview } from '../../functions/patchData'
const containerStyle = width => ({
   marginBlock: '2%', width: (0.89 * width)
})
const paperStyle = width => ({
   boxShadow: '0 0 3px #9E9E9E', minWidth: 400, maxWidth: 3200, width: (0.89 * width)
})
const divStyle = {
   whiteSpace: "nowrap", textOverflow: "ellipsis",
   display: "block", overflow: "hidden", minWidth: 150, maxWidth: 300
}
const progressDivStyle = {
   whiteSpace: "nowrap", textOverflow: "ellipsis",
   display: "block", overflow: "hidden",  width: 500
}
const ProgressBar = ({ percentage }) => <div style={{
   height: 40, width: '78%', borderRadius: 6,
   background: `linear-gradient(90deg, #A9ABD3 ${percentage}%, #D7D7D7 0%)`
}} />
const centerFlexStyle = { display: 'flex', alignItems: 'center' }
const iconButtonStyle = { marginLeft: 20, color: '#000' }
const iconStyle = { fontSize: 20 }
const CountryAndAbsentees = ({ title, data }) => {
   return Array.isArray(data) && (
      <div style={{ padding: 10 }}>
         <NunitoText value={`${title.charAt(0).toUpperCase()}${title.slice(1)}`} fontSize={20} fontWeight={600} />
         {data.map((d, i) => (
            <NunitoText key={i} value={d.name} fontSize={18} fontWeight={500} />
         ))}
         
      </div>
   )
}
const Cell = ({ row, getter, color, widths, fixed , setloading }) => {
   
   const [pressed, setPressed] = useState(false)
   const { enqueueSnackbar, closeSnackbar } = useSnackbar()
   const tableCellStyle = {
      paddingLeft: 15, backgroundColor: color, backgroundClip: 'padding-box'
   }
   const fixedTableCellStyle = getter => {
      let index = widths.findIndex(w => w.id === getter)
      let w = widths[index - 1]?.totalWidth || 0
      return { ...tableCellStyle, position: 'sticky', left: w, zIndex: 999 }
   }
   let output = row[getter] || '-'
   let hoverData = row[getter]
   if (Array.isArray(row[getter])) {
      if (getter === 'countries') {
         output = row[getter].join()
      }
      if (getter === 'absentees') {
         
         const abesntee = []
         let out = ''
         row[getter].map((el)=>{
            abesntee.push(el.name)
         })
         row[getter].map((el)=>{
       out+=`${el.name},`
        })
        hoverData = out

        output = row[getter].length
      }
 
   } else {
      if (getter === 'progress') {
         const compute = ()=>{
            showNotification('success','calculating ...', enqueueSnackbar, closeSnackbar)
            const payload = {
               id:String(row.id)
            }
            
            console.log(payload)
            patchMarkingOverview(payload).then((res)=>{
               console.log(res)
               setPressed(true)
               showNotification('success','successfully calucated', enqueueSnackbar, closeSnackbar)
            })
         }
         console.log(row)
         output = <div style={centerFlexStyle}>
            <ProgressBar percentage={((row.marked +row.absentees.length)/row.total_attended_participants) * 100}/>
            {(!pressed&&(row.levelReady&&row.isactive))&& <LightTooltip title='Compute' type='Button' rollover={0} topic={0} hide={0}>
               <IconButton style={iconButtonStyle} onClick={() => compute()}>
                  {<FaCalculator style={iconStyle} />}
               </IconButton>
            </LightTooltip>}
          {row.iscomputed &&  <LightTooltip title='moderate' type='Button' rollover={0} topic={0} hide={0}>
               <IconButton style={iconButtonStyle}>
                 <Link to={`editRoundMarking?id=${row.id}`}>{<FiEdit style={iconStyle} />}</Link>
               </IconButton>
            </LightTooltip>}
         </div>
      }
      if (getter === 'marked') {
         output = `${row.marked}/${row.total_attended_participants}`
      }
   }
   let owo = <div style={getter === 'progress' ? progressDivStyle : divStyle}>{output}</div>
   return (
      <LightTooltip title={CountryAndAbsentees({ title: getter, data: row[getter] })} rollover={typeof hoverData === 'string' ? 1 : 0}
         topic={0} hide={!['absentees'].includes(getter) ? 1 : 0}>
         <TableCell align='left' component="th" scope="row" style={fixed.includes(getter) ? fixedTableCellStyle(getter) : tableCellStyle}>
            {owo}
         </TableCell>
      </LightTooltip>
   )
}
const Row = ({ count, row, getters, widths, fixed , setloading }) => {
   const [color, setColor] = useState('#FFF')
   const tableRowStyle = {
      height: 80, overFlow: 'auto'
   }
   return (
      <TableRow style={tableRowStyle} onMouseEnter={() => setColor('#F6F6F6')} onMouseLeave={() => setColor('#FFF')}>
         {getters.map((getter, i) => (
            <Cell key={`${getter}-${i}`} row={row} getter={getter} color={color} widths={widths} fixed={fixed} setloading={setloading} />
         ))}
      </TableRow>
   )
}
export default function MarkingOverviewTable({ headers, data, fixed , setloading }) {
   const getters = headers.map(h => h.id)
   const [order, setOrder] = useState('asc')
   const [orderBy, setOrderBy] = useState('id')
   const [widths, setWidths] = useState(headers.map(h => ({ id: h.id, width: 0, totalWidth: 0 })))
   const handleRequestSort = property => {
      const isAsc = orderBy === property && order === 'asc'
      setOrder(isAsc ? 'desc' : 'asc')
      setOrderBy(property);
   }
   const { width } = useWindowDimensions()
   return (
      <Grid container alignItems='flex-start' style={containerStyle(width)}>
         <Paper style={paperStyle(width)}>
            <TableContainer>
               <Table size='small'>
                  <EnhancedTableHead handleRequestSort={handleRequestSort} headers={headers}
                     widths={widths} setWidths={setWidths} fixed={fixed} />
                  <TableBody>
                     {stableSort(data, getComparator(order, orderBy)).map((row, i) => (
                        <Row key={i} count={data.findIndex(d => d.id === row.id)} row={row} getters={getters}
                           widths={widths} fixed={fixed} setloading={setloading}/>
                     ))}
                  </TableBody>
               </Table>
            </TableContainer>
         </Paper>
      </Grid>
   )
}