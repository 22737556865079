import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import { useHistory } from 'react-router-dom'
import Solution from './Solution'
import TaskContent from './TaskContent'
import AnswerSetting from './AnswerSetting'
import { useSelector, useDispatch } from 'react-redux'
import { addTask, deleteTask, duplicateTask, updateTask, resetTask } from '../../actions'
import { addTask as addTaskToDatabase } from '../../functions/postData'
import { getDomains } from '../../functions/getData'
import { getBase64 } from '../../functions/upload'
import { isEmpty, showWarningSwal } from '../../functions/alert'
import { invalidName } from '../../functions/general'
import { showNotification } from '../../functions/snackbar'
import TitleBar from '../general/TitleBar'
import NunitoText from '../general/NunitoText'
import ReusableTextField from '../general/ReusableTextField'
import ReusableAutocompleteWithID from '../general/ReusableAutocompleteWithID'
import ReusableButton from '../general/ReusableButton'
import AddPageWrapper from '../general/AddPageWrapper'
import InputContainer from '../general/InputContainer'
import DescriptionEditor from '../DescriptionEditor'
import Recommendations from '../Recommendations'
import { useSnackbar } from 'notistack'
// For edit task later on
// const Remarks = () => {
//     const containerStyle = { margin: 20, backgroundColor: '#F3F0F4', border: '1px solid', borderRadius: 10 }
//     const divStyle = { display: 'flex', alignItems: 'center', marginTop: 20, marginInline: 20 }
//     return (
//         <Grid container flexDirection='column' style={containerStyle}>
//             <div style={{ ...divStyle, justifyContent: 'space-between' }}>
//                 <NunitoText value='Remarks' fontSize={25} fontWeight={800} color='#5e75c3' italic
//                     borderBottom='3px solid #000' />
//                 <ReusableButton text='View in new tab' bgColor='#F16774' height={43} iconType='copy' />
//             </div>
//             <div style={divStyle}>
//                 <NunitoText value='Test Name(Chief Examiner)' fontSize={24} fontWeight={300}
//                     color='#5e75c3' italic />
//                 <NunitoText value='(22/10/2021 14:00)' fontSize={15} fontWeight={300}
//                     color='#f16774' italic marginLeft={10} />
//             </div>
//             <div style={{ marginInline: 20, marginBottom: 20 }}>
//                 <NunitoText value='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
//                     ut labore et dolore magna aliqua. Rhoncus dolor purus non enim praesent elementum facilisis leo
//                     vel. Risus at ultrices mi tempus imperdiet. Semper risus in hendrerit gravida rutrum quisque
//                     non tellus. Convallis convallis tellus id interdum velit laoreet id donec ultrices. Odio morbi
//                     quis commodo odio aenean sed adipiscing. Amet nisl suscipit adipiscing bibendum est ultricies
//                     integer quis. Cursus euismod quis viverra nibh cras. Metus vulputate eu scelerisque felis
//                     imperdiet proin fermentum leo. Mauris commodo quis imperdiet massa tincidunt.' fontSize={20}
//                     marginTop={6} align='left' />
//             </div>
//         </Grid>
//     )
// }
const taskContainerStyle = { backgroundColor: '#F3F0F4', borderRadius: '0 0 12px 12px' }
const buttonsContainerStyle = { marginRight: 40 }
const centerFlexStyle = { display: 'flex', justifyContent: 'center', alignItems: 'center' }
const imageContainerStyle = { marginInline: 36 }
const imageStyle = { maxHeight: 600, maxWidth: 1500 }
const Task = ({ count, task, allTopicOptions, tagOptions }) => {
    const [expandTask, setExpandTask] = useState(true)
    const [expandSetting, setExpandSetting] = useState(false)
    const dispatch = useDispatch()
    const update = (count, head, target, text, event) => dispatch(updateTask(count, head, target, text, event))
    const handleFileInputChange = (count, file) => {
        update(count, 'taskText', 'imgName', file.name)
        getBase64(file)
            .then(result => update(count, 'taskText', 'image', result))
            .catch(err => {
                console.log(err)
                alert(`Error at handeFileInputChange: ${JSON.stringify(err)}`)
            });
    }
    const { taskTitle, taskIdentifier, domain, tags, description, imgName, image, solution, recommendations } = task
    return (
        <Grid container>
            <TitleBar title={`Task ${count + 1} - ${taskTitle}`} state={expandTask} setState={setExpandTask} main>
                <div style={buttonsContainerStyle}>
                    <ReusableButton text='New Task' fontSize={16} bgColor='#5E75C3' marginLeft={20}
                        onClick={() => dispatch(addTask(count + 1))} allowSpam iconType='add' />
                    {Boolean(count) && <ReusableButton text='Delete Task' fontSize={16} bgColor='#E83D4D' marginLeft={20}
                        onClick={() => dispatch(deleteTask(count))} iconType='delete' />}
                    <ReusableButton text='Duplicate Task' fontSize={16} bgColor='#F16774' marginLeft={20}
                        onClick={() => dispatch(duplicateTask(count + 1, task))} allowSpam iconType='copy' />
                </div>
            </TitleBar>
            {expandTask && <Grid container style={taskContainerStyle}>
                <TitleBar title='Setting' state={expandSetting} setState={setExpandSetting} />
                {expandSetting && <AddPageWrapper>
                    <InputContainer first label='Task Title*'
                        field={<ReusableTextField type='default' placeholder='Task Title' state={taskTitle} setState={update}
                            count={count} head='taskText' target='taskTitle' borderColor='#000' required onBlur fullWidth />} />
                    <InputContainer label='Task Identifier*'
                        field={<ReusableTextField type='default' placeholder='Task Identifier' state={taskIdentifier}
                            setState={update} count={count} head='taskText' target='taskIdentifier'
                            borderColor='#000' required onBlur fullWidth />} />
                    <InputContainer label='Domain and Topic'
                        field={<ReusableAutocompleteWithID type='default' placeholder='Domain and Topic' width={610}
                            multiple state={domain} setState={update}
                            count={count} head='taskMultiple' target='domain' borderColor='#707070'
                            nested options={allTopicOptions} selectAll />} />
                    <InputContainer label='Tags'
                        field={<ReusableAutocompleteWithID type='default' placeholder='Tags' width={610} multiple
                            state={tags} setState={update} count={count} head='taskMultiple' target='tags' borderColor='#707070'
                            options={tagOptions.map(t => ({ id: t.id, option: t.name }))} />} />
                    <InputContainer label='Description'
                        field={<DescriptionEditor state={description} setState={update} height={360}
                            placeholder='Description' count={count} head='taskText' target='description' />} />
                    <InputContainer last label='Select Image'
                        field={<ReusableButton text='Browse' bgColor='#5E75C3' width={140} height={50} fontSize={16}
                            onClick={() => document.getElementById(`raised-button-file-${count}`).click()} iconType='upload' />} />
                    <input id={`raised-button-file-${count}`} accept="image/*" style={{ display: 'none' }} multiple
                        type="file" onChange={(e) => handleFileInputChange(count, e.target.files[0])} />
                    <Grid container flexDirection='column' style={imageContainerStyle}>
                        <NunitoText value={image !== '' ? imgName : "Test.jpg"} fontSize={20} color='#707070' />
                        {image !== '' ? <div style={centerFlexStyle}>
                            <img src={image} alt="Task" style={imageStyle} />
                        </div> : <ReusableTextField type='imagePlaceholder' placeholder='Image placeholder' bgColor='#F2F2F2'
                            height={600} />}
                    </Grid>
                    <Solution count={count} solution={solution} update={update} />
                </AddPageWrapper>}
                <Recommendations count={count} recommendations={recommendations} update={update} />
                <TaskContent count={count} task={task} />
                <AnswerSetting count={count} task={task} />
                {/* <Grid container justifyContent="flex-end" style={{ marginBottom: 20, paddingRight: 30 }}>
                    <ReusableButton text='Submit' bgColor='#5E75C3' fontSize={16} onClick={() => onSubmit()} />
                </Grid> */}
            </Grid>}
        </Grid>
    )
}
const buttonContainerStyle = { marginBlock: 20 }
export default function Tasks() {
    const tasks = useSelector(state => state.task)
    const [allTopicOptions, setAllTopicOptions] = useState([])
    const [tagOptions, setTagOptions] = useState([])
    useEffect(() => {
        const controller = new AbortController()
        const signal = controller.signal
        getDomains('?limits=50', signal).then(d => {
            let domains = d.tagLists.data.filter(c => !c.is_tag && c.domain_name === null)
            let topics = d.tagLists.data.filter(c => !c.is_tag && c.domain_name !== null)
            let outputTopics = domains.map(d => ({ id: d.id, option: d.name, children: [] }))
            outputTopics.forEach(o => {
                topics.forEach(t => {
                    if (t.domain_id === o.id) o.children.push({ id: t.id, option: t.name })
                })
            })
            setAllTopicOptions(outputTopics.filter(o => o.children.length))
            setTagOptions(d.tagLists.data.filter(c => c.is_tag).map(t => ({ id: t.id, name: t.name })))
        }).catch(e => console.log(e))
        return () => controller.abort()
    }, [])
    const history = useHistory()
    const dispatch = useDispatch()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const onSubmit = () => {
        let payload = []
        let msg = ''
        for (let i = 0; i < tasks.length; i++) {
            const { taskTitle, taskIdentifier, domain, tags, description, language, image,
                solution, recommendations, type, structure, sorting, layout, uploadImage, answers } = tasks[i]
            let warningMessage = ''
            if (invalidName(taskTitle)) warningMessage += 'Invalid Task Title.<br>'
            if (invalidName(taskIdentifier)) warningMessage += 'Invalid Task Identifier.<br>'
            if (isEmpty([language])) warningMessage += 'Task Content cannot be empty.<br>'
            if (recommendations.map(r => r.grade).includes('')) {
                warningMessage += 'Please fill in all grades fields or remove recommended grade.<br>'
            }
            if (recommendations.map(r => r.difficulty).includes('')) {
                warningMessage += 'Please fill in all difficulty fields or remove recommended difficulty.<br>'
            }
            if (answers.length === 0) warningMessage += 'Must have at least one answer.<br>'
            if (type === 1) {
                if (answers.map(a => a.taskLabel === '').includes(true)) {
                    warningMessage += 'Answer cannot be empty if answer type is MCQ.<br>'
                }
                if ([1, 2].includes(structure) && !answers.map(a => a.correct).includes(true)) {
                    warningMessage += 'Must have at least one correct answer if answer type is MCQ and answer structure is Default or Group.<br>'
                }
            }
            if (type === 2) {
                if ([1, 2].includes(structure) && answers.map(a => a.answer === '').includes(true)) {
                    warningMessage += 'Answer cannot be empty if answer type is Input and answer structure is Default or Group.<br>'
                }
                if (structure === 4 && answers.map(a => a.taskLabel === '').includes(true)) {
                    warningMessage += 'Task Label cannot be empty if answer type is Input and answer structure is Open.<br>'
                }
            }
            if (warningMessage) {
                msg += '<div>'
                msg += `<div style="text-align:center; font-weight: 800">Error in Task ${i + 1}</div>`
                msg += `<div style="text-align:left">${warningMessage}</div>`
                msg += '</div>'
            } else {
                let obj = {
                    "title": taskTitle,
                    "identifier": taskIdentifier,
                    "tag_id": [domain, tags].flat(),
                    "content": language,
                    "description": description,
                    "solutions": solution,
                    "answer_type": type,
                    "answer_structure": structure,
                    "answer_sorting": type === 2 ? null : sorting,
                    "answer_layout": type === 2 ? null : layout,
                    "recommended_grade": recommendations.filter(r => r.grade !== '').map(r => r.grade),
                    "recommended_difficulty": recommendations.filter(r => r.difficulty !== '').map(r => r.difficulty.toLowerCase()),
                    "image_label": Number(uploadImage),
                    "image": image
                }
                if (uploadImage) obj.labels = answers.map(a => a.image)
                else obj.labels = answers.map(a => a.taskLabel)
                if (type === 1) {
                    // MCQ Sequence, parse in an array of null
                    if (structure === 3) obj.answers = answers.map(() => null)
                    // MCQ Default and Group, parse in an array of 1 if answer is correct
                    else obj.answers = answers.map(a => a.correct ? '1' : null)
                } else if (type === 2) {
                    // Input Open, parse in an array of null
                    if (structure === 4){ obj.answers = answers.map(() => null)
                    console.log(obj.answers)
                    }
                    // Input Default and Group, parse in an array of strings
                    else obj.answers = answers.map(a => a.answer)
                }
                payload.push(obj)
            }
        }
        if (msg) return showWarningSwal(msg)
        console.log(JSON.stringify(payload))
        console.log(payload)
        addTaskToDatabase(payload).then(d => {
            console.log(d)
            if ([200, 201].includes(d.status)) {
                showNotification('success', d.message, enqueueSnackbar, closeSnackbar)
                dispatch(resetTask())
                history.push('./task')
            }
        }).catch(e => console.log(e))
    }
    return <>
        {tasks.map((task, index) => (
            <Task key={index} count={index} task={task} allTopicOptions={allTopicOptions} tagOptions={tagOptions} />
        ))}
        <Grid container justifyContent="flex-end" style={buttonContainerStyle}>
            <ReusableButton text='Submit' bgColor='#5E75C3' fontSize={16} onClick={() => onSubmit()} />
        </Grid>
    </>
}