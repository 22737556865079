import { postMethod } from './httpMethods'
// School
export const addSchool = (data, signal) => {
   return new Promise((resolve, reject) => {
      postMethod('school', data, signal).then(d => resolve(d)).catch(e => reject(e))
   })
}
// Organization
export const addOrganization = data => {
   return new Promise((resolve, reject) => {
      postMethod('organizations', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
// User
export const addUser = data => {
   return new Promise((resolve, reject) => {
      postMethod('user', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
// Participant
export const addParticipant = (data, signal) => {
   return new Promise((resolve, reject) => {
      postMethod('participant', data, signal).then(d => resolve(d)).catch(e => reject(e))
   })
}
// Upload Participant Answers
export const uploadParticipantAnswers = (data, signal) => {
   return new Promise((resolve, reject) => {
      postMethod('competition/upload_answers', data, signal).then(d => resolve(d)).catch(e => reject(e))
   })
}
// Domain and Tag
export const addDomain = data => {
   return new Promise((resolve, reject) => {
      postMethod('tag', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
// Competition
export const addCompetition = data => {
   return new Promise((resolve, reject) => {
      postMethod('competition', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
export const addCompetitionOrganization = data => {
   return new Promise((resolve, reject) => {
      postMethod('competition/organization', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
export const addCompetitionRound = data => {
   return new Promise((resolve, reject) => {
      postMethod('competition/rounds', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
export const addCompetitionRoundAward = data => {
   return new Promise((resolve, reject) => {
      postMethod('competition/awards', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
export const addCompetitionOverallAward = data => {
   return new Promise((resolve, reject) => {
      postMethod('competition/overall_awards', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
export const addMarkingPreparation = (params, signal) => {
   return new Promise((resolve, reject) => {
      postMethod('marking/preparation', params, signal).then(d => resolve(d)).catch(e => reject(e))
   })
}
// Task
export const addTask = data => {
   return new Promise((resolve, reject) => {
      postMethod('tasks', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
// Difficulty Group
export const addDifficultyGroup = data => {
   return new Promise((resolve, reject) => {
      postMethod('taskdifficultygroup', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
// Collection
export const addCollection = data => {
   return new Promise((resolve, reject) => {
      postMethod('collection', data).then(d => resolve(d)).catch(e => reject(e))
   })
}