import React, { useState, useEffect, useRef } from 'react'
import { Grid, Modal, RadioGroup, Radio, FormControlLabel } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { useSelector } from 'react-redux'
import { formatDateTime, cancelAction } from '../../functions/general'
import { patchCompetitionOrganization } from '../../functions/patchData'
import { deleteCompetitionOrganization } from '../../functions/deleteData'
import { showWarningSwal } from '../../functions/alert'
import { showNotification } from '../../functions/snackbar'
import DeleteModal from '../modal/DeleteModal'
import AddParticipatingCountryModal from '../modal/AddParticipatingCountryModal'
import LightTooltip from '../general/LightTooltip'
import NunitoText from '../general/NunitoText'
import ReusableButton from '../general/ReusableButton'
import ReusableDatePicker from '../general/ReusableDatePicker'
import ReusableToggle from '../general/ReusableToggle'
import ReusableAutocompleteWithID from '../general/ReusableAutocompleteWithID'
import TitleBar from '../general/TitleBar'
import AddPageWrapper from '../general/AddPageWrapper'
import LoadingBackdrop from '../general/LoadingBackdrop'
import { useSnackbar } from 'notistack'
import { isUnchanged, getDaysArray, validateCountryPartner, deepCopy } from '../../functions/edit'
import StatusAndInfo from './StatusAndInfo'
const modes = ['Paper Based', 'Online', 'Both']
const clearIconStyle = { fontSize: 36, cursor: 'pointer', marginRight: 10, marginTop: 10 }
const fieldsContainerStyle = { paddingInline: 20 }
const startColumnFlexStyle = { display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }
const datesAndModeContainerStyle = { marginTop: 20 }
const dateDivStyle = { ...startColumnFlexStyle, marginRight: 50 }
const selectWrapperStyle = { display: 'flex', alignItems: 'center' }
const radioGroupStyle = { marginInline: '20px 50px' }
const radioStyle = { color: '#000' }
const statusSelectAndButtonStyle = { marginBlock: 20 }
const ParticipatingCountry = ({ count, original, setOriginal, competition, setCompetition, setLoading, openDeleteModal, isMounted }) => {
   const [expand, setExpand] = useState(false)
   const { global_registration_date, global_registration_end_date,
      competition_start_date, competition_end_date, format, competition_mode } = competition
   const { id, organization_id, organization_name, country_id, registration_open_date, dates,
      originalDates, allowEdit, originalStatus, status, type, translate } = competition.competition_organization[count]
   const countryOptions = useSelector(state => state.countryOptions)
   const languageOptions = useSelector(state => state.languageOptions)
   const { enqueueSnackbar, closeSnackbar } = useSnackbar()
   const updatePartner = (identifier, value) => {
      let newCompetition = window.structuredClone(competition)
      newCompetition.competition_organization[count][identifier] = value
      setCompetition(newCompetition)
   }
   const onSubmit = () => {
      if (isUnchanged(original, competition)) return showWarningSwal('Please make some changes before submitting')
      let msg = validateCountryPartner(format, global_registration_date, global_registration_end_date,
         competition_start_date, competition_end_date, registration_open_date, dates, originalDates)
      if (msg) return showWarningSwal(msg)
      actuallySubmit()
   }
   const actuallySubmit = () => {
      let payload = {
         id: id,
         organization_id: organization_id,
         registration_open_date: formatDateTime(registration_open_date, '/'),
         competition_dates: getDaysArray(dates.sort((a, b) => a - b)).filter(d => d >= new Date().setHours(0, 0, 0, 0))
            .map(d => formatDateTime(d, '/')),
         edit_sessions: Number(allowEdit),
         status: status === 'added' ? 'active' : status,
         competition_mode: Number(type),
         translate: JSON.stringify(translate)
      }
      console.log(JSON.stringify(payload))
      setLoading(true)
         console.log(dates , payload.competition_dates , type)
      patchCompetitionOrganization(payload).then(d => {
         if (d.status === 200) {
            showNotification('success', d.message, enqueueSnackbar, closeSnackbar)
            if (isMounted.current) {
               let newCompetition = window.structuredClone(competition)
               newCompetition.competition_organization[count].originalDates = dates.map(d => new Date(d).getTime())
               newCompetition.competition_organization[count].status = status === 'added' ? 'active' : status
               newCompetition.competition_organization[count].originalStatus = status === 'added' ? 'active' : status
               setCompetition(newCompetition)
               setOriginal(deepCopy(newCompetition))
            }
         }
      }).catch(e => console.log(e)).finally(() => isMounted.current && setLoading(false))
   }
   return (
      <Grid container>
         <TitleBar title={`${organization_name} - ${countryOptions.find(c => c.id === country_id).display_name}`}
            state={expand} setState={setExpand} />
         {expand && <AddPageWrapper>
            <Grid container>
               <Grid container justifyContent='flex-end'>
                  <ClearIcon style={clearIconStyle} onClick={() => openDeleteModal(id)} />
               </Grid>
               <Grid container alignItems='flex-end' justifyContent='space-between' style={fieldsContainerStyle}>
                  <ReusableAutocompleteWithID type='withLabel' placeholder='Organization*' width={300} height={55} bgColor='#FFF'
                     required btnBgColor='#144A94' btnWidth={64} state={1} borderColor='#707070'
                     options={[{ id: 1, option: organization_name }]} readOnly grayedOut />
                  <ReusableAutocompleteWithID type='withLabel' placeholder='Country*' width={300} height={55} bgColor='#FFF'
                     btnBgColor='#144A94' btnWidth={64} state={country_id} borderColor='#707070'
                     options={countryOptions.map(c => ({ id: c.id, option: c.display_name }))} readOnly grayedOut />
                  <ReusableAutocompleteWithID type='withLabel' placeholder='Translation' width={460} multiple
                     state={translate} setState={updatePartner} target='translate' borderColor='#707070'
                     options={languageOptions.map(l => ({ id: l.id, option: l.name }))} />
                  <div style={startColumnFlexStyle}>
                     <NunitoText value='Allow Session Edits By Partners' fontSize={16} fontWeight={600} italic />
                     <ReusableToggle type='text' width={160} falseValue='No' trueValue='Yes'
                        state={allowEdit} target='allowEdit' setState={updatePartner} />
                  </div>
                  <Grid container alignItems='center' style={datesAndModeContainerStyle}>
                     <div style={dateDivStyle}>
                        <NunitoText value='Country Registration Start Date' fontSize={15} />
                        <ReusableDatePicker width={210} state={registration_open_date} required
                           setState={updatePartner} target='registration_open_date' allowEmpty />
                     </div>
                     <div style={dateDivStyle}>
                        <NunitoText value='Country Competition Dates' fontSize={15} />
                        <ReusableDatePicker width={210} state={dates} multiple required
                           setState={updatePartner} target='dates' allowEmpty />
                     </div>
                     <div style={selectWrapperStyle}>
                        <NunitoText value='Mode of Competition:' fontSize={18} fontWeight={600} italic />
                        {competition_mode === 2 ? <>
                           <RadioGroup value={type} style={radioGroupStyle}
                              onChange={e => updatePartner('type', e.target.value)} row>
                              <FormControlLabel value='0' control={<Radio style={radioStyle} />} label="Paper Based" />
                              <FormControlLabel value='1' control={<Radio style={radioStyle} />} label="Online" />
                              <FormControlLabel value='2' control={<Radio style={radioStyle} />} label="Both" />
                           </RadioGroup>
                           <ReusableButton text='Clear Selection' color='#000' borderColor='#000' fontSize={16} marginRight={20}
                              onClick={() => updatePartner('type', '')} />
                        </> : <NunitoText value={modes[type]} fontSize={18} fontWeight={600} marginLeft={20} />}
                     </div>
                  </Grid>
                  <Grid container justifyContent="flex-end" alignItems='center' style={statusSelectAndButtonStyle}>
                     <StatusAndInfo state={status} target='status' setState={updatePartner}
                        originalStatus={originalStatus} />
                     <ReusableButton text='Save' bgColor='#5E75C3' fontSize={16} iconType='save' onClick={() => onSubmit()} />
                  </Grid>
               </Grid>
            </Grid>
         </AddPageWrapper>}
      </Grid>
   )
}
const statuses = ['All', 'Active', 'Ready', 'Lock', 'New']
const containerStyle = { paddingBottom: 20 }
const btnStyle = (status, show) => {
   let bgColor = ''
   switch (status) {
      case 'All': bgColor = '#5E75C3'; break;
      case 'Active': bgColor = '#6FC9B6'; break;
      case 'Lock': bgColor = '#F3A867'; break;
      case 'Ready': bgColor = '#8E7DD0'; break;
      case 'New': bgColor = 'grey'; break;
      default: bgColor = '#FFF'
   }
   return {
      width: 120, height: 50, fontSize: 20, marginInline: 10, borderRadius: 8,
      display: 'flex', justifyContent: 'center', alignItems: 'center', userSelect: 'none', cursor: 'pointer',
      backgroundColor: bgColor, opacity: status.toLowerCase() !== show && 0.4,
      color: status.toLowerCase() === show ? '#FFF' : '#000'
   }
}
const btnContainerStyle = { marginTop: 20 }
export default function ParticipatingCountries({ original, setOriginal, competition, setCompetition }) {
   const isMounted = useRef(false)
   const [expand, setExpand] = useState(true)
   const [loading, setLoading] = useState(false)
   const [show, setShow] = useState('all')
   const [onAddCompetitionOrganization, setOnAddCompetitionOrganization] = useState(false)
   const [onDelete, setOnDelete] = useState(false)
   const [id, setId] = useState('')
   const { competition_organization } = competition
   const handleChangeShow = e => setShow(e.target.innerText.toLowerCase())
   const cancelAddCompetitionOrganization = () => cancelAction(setOnAddCompetitionOrganization)
   const cancelDelete = () => cancelAction(setOnDelete, setId)
   const openDeleteModal = id => {
      setId(id)
      setOnDelete(true)
   }
   const { enqueueSnackbar, closeSnackbar } = useSnackbar()
   const afterAdd = (cancel, data, organizations) => {
      console.log(data)
      if ([200, 201].includes(data.status)) {
         cancel()
         showNotification('success', data.message, enqueueSnackbar, closeSnackbar)
         if (isMounted.current) {
            let newCompetition = window.structuredClone(competition)
            const getRndInteger = (min, max) => Math.floor(Math.random() * (max - min)) + min
            for (const organization of organizations) {
               const { organization_id, organization_name, country_id, translate, competition_mode, edit_sessions } = organization
               newCompetition.competition_organization.push({
                  // id: data.id[i],
                  id: getRndInteger(200, 500),
                  competition_id: competition.id,
                  organization_id: organization_id,
                  organization_name: organization_name,
                  country_id: country_id,
                  registration_open_date: 0,
                  originalDates: [],
                  dates: [],
                  type: competition_mode.toString(),
                  translate: JSON.parse(translate),
                  translating: !!JSON.parse(translate).length,
                  allowEdit: !!edit_sessions,
                  languagesToView: [],
                  status: 'added',
                  originalStatus: 'added'
               })
            }
            setCompetition(newCompetition)
            setOriginal(deepCopy(newCompetition))
         }
      }
   }
   const deleteFunction = () => {
      setLoading(true)
      let payload = { id: id }
      deleteCompetitionOrganization(payload).then(d => {
         if (d.status === 200) {
            cancelDelete()
            showNotification('success', d.message, enqueueSnackbar, closeSnackbar)
            if (isMounted.current) {
               let newCompetition = window.structuredClone(competition)
               newCompetition.competition_organization = newCompetition.competition_organization.filter(org => org.id !== id)
               setCompetition(newCompetition)
               setOriginal(deepCopy(newCompetition))
            }
         }
      }).catch(e => console.log(e)).finally(() => isMounted.current && setLoading(false))
   }
   useEffect(() => {
      isMounted.current = true
      return () => isMounted.current = false
   }, [])
   return (
      <Grid container>
         <LoadingBackdrop loading={loading} />
         <TitleBar title='Participating Countries' state={expand} setState={setExpand}>
            <ReusableButton text='Add Participating Country' bgColor='#5E75C3' height={46} fontSize={16} marginRight={40}
               onClick={() => setOnAddCompetitionOrganization(true)} iconType='add' />
         </TitleBar>
         {expand && <AddPageWrapper>
            <Grid container style={containerStyle}>
               <Grid container justifyContent='center' style={btnContainerStyle}>
                  {statuses.map((status, index) => (
                     <LightTooltip key={index} title={`Show ${status.toLowerCase()} partners`} rollover={1} topic={1} hide={0}>
                        <div style={btnStyle(status, show)} onClick={handleChangeShow}>{status}</div>
                     </LightTooltip>
                  ))}
               </Grid>
               {/* <Grid container alignItems='center' justifyContent='space-around' style={{ paddingInline: 20, marginTop: 30 }}>
                        <NunitoText value='Allow all partners edit sessions' fontSize={20} fontWeight={600} italic />
                        <ReusableToggle type='text' width={160} falseValue='No' trueValue='Yes'
                            state={allowPartnerEditSessions} setState={setAllowParterEditSessions} />
                    </Grid> */}
               {competition_organization.map((organization, index) => (
                  ((organization.originalStatus.toLowerCase() === show || show === 'all')
                     && organization.originalStatus.toLowerCase() !== 'deleted') &&
                  <ParticipatingCountry key={organization.id} count={index} original={original} setOriginal={setOriginal}
                     competition={competition} setCompetition={setCompetition} setLoading={setLoading}
                     openDeleteModal={openDeleteModal} isMounted={isMounted} />
               ))}
            </Grid>
         </AddPageWrapper>}
         <Modal open={onAddCompetitionOrganization} onClose={() => cancelAddCompetitionOrganization()}>
            <>
               <AddParticipatingCountryModal setLoading={setLoading} table={{ data: [competition] }} id={competition.id}
                  cancelAddCompetitionOrganization={cancelAddCompetitionOrganization}
                  afterAddingCompetitionOrganization={afterAdd} />
            </>
         </Modal>
         <Modal open={onDelete} onClose={() => cancelDelete()}>
            <>
               <DeleteModal table={{ data: competition_organization }} id={id} selected={[]}
                  deleteFunction={deleteFunction} cancelDelete={cancelDelete} />
            </>
         </Modal>
      </Grid>
   )
}