import React, { useState, useEffect, useRef, Fragment } from 'react'
import { Grid, Autocomplete, TextField, IconButton, Chip, Collapse, List, CircularProgress } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CancelIcon from '@mui/icons-material/Cancel'
import ClearIcon from '@mui/icons-material/Clear'
import NunitoText from './NunitoText'
import LightTooltip from './LightTooltip'
import useElementDimensions from '../../hooks/ElementDimensions'
const chipStyle = { backgroundColor: '#F16774', color: '#FFF' }
// For nested autocompletes for one or more multiple selections
const GroupOptions = ({ id, group, children, state, setState, allowSelectHead, selectAll, multiple, input }) => {
    const [open, setOpen] = useState(false)
    return (
        <div>
            {multiple ? <GroupHeadMultiple id={id} group={group} children={children} open={open} setOpen={setOpen} state={state}
                setState={setState} allowSelectHead={allowSelectHead} selectAll={selectAll} /> :
                <GroupHead id={id} group={group} children={children} open={open} setOpen={setOpen} setState={setState}
                    allowSelectHead={allowSelectHead} />}
            <Collapse in={open}>
                {Boolean(children.length) && <List>
                    {children.map((c, i) => (
                        multiple ? <IndividualOptionsMultiple key={i} id={c.id} child={c.option} state={state} setState={setState}
                            input={input} /> :
                            <IndividualOptions key={i} id={c.id} child={c.option} setState={setState} input={input} />
                    ))}
                </List>}
            </Collapse>
        </div>
    )
}
const OpenCloseIcon = ({ open, setOpen }) => {
    const toggle = e => {
        e.stopPropagation()
        setOpen(!open)
    }
    return (
        <IconButton onClick={toggle}>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
    )
}
const GroupHead = ({ id, group, open, setOpen, setState, allowSelectHead }) => {
    const [bgColor, setBgColor] = useState('#FFF')
    const onHover = () => setBgColor('#F2F2F2')
    const unHover = () => setBgColor('#FFF')
    const divStyle = {
        display: 'flex', alignItems: 'center', justifyContent: 'space-between',
        cursor: 'pointer',
        paddingLeft: 10,
        backgroundColor: bgColor,
        height: 40,
    }
    return (
        <div style={divStyle} onClick={() => allowSelectHead && setState(id)}
            onMouseEnter={() => onHover()} onMouseLeave={() => unHover()}>
            {group}
            <OpenCloseIcon open={open} setOpen={setOpen} />
        </div>
    )
}
const IndividualOptions = ({ id, child, setState, input }) => {
    const [bgColor, setBgColor] = useState('#FFF')
    const onHover = () => setBgColor('#F2F2F2')
    const unHover = () => setBgColor('#FFF')
    const divStyle = {
        display: 'flex', alignItems: 'center', justifyContent: 'space-between',
        cursor: 'pointer',
        paddingLeft: 30,
        backgroundColor: bgColor,
        height: 40,
    }
    return (
        child.toLowerCase().includes(input.toLowerCase()) ? <div style={divStyle}
            onClick={() => setState(id)} onMouseEnter={() => onHover()} onMouseLeave={() => unHover()}>
            {child}
        </div> : null
    )
}
const GroupHeadMultiple = ({ id, group, open, setOpen, state, setState, allowSelectHead, children, selectAll }) => {
    const [bgColor, setBgColor] = useState('#FFF')
    const onHover = () => setBgColor('#F2F2F2')
    const unHover = () => setBgColor('#FFF')
    const selectedCondition = selectAll ? children.every(el => state.includes(el.id)) && children.length : state.includes(id)
    const containerStyle = {
        cursor: 'pointer',
        backgroundColor: selectedCondition ? '#F0F8FF' : bgColor
    }
    const divStyle = { display: 'flex', alignItems: 'center' }
    const owoOnClick = () => {
        if (allowSelectHead) {
            if (selectAll) setState([id, children.map(c => c.id)].flat())
            else setState(id)
        } else {
            if (selectAll) setState(children.map(c => c.id))
            else setState(id)
        }
    }
    let uwu = allowSelectHead ?
        selectAll ? [id, children.map(c => c.id)].flat() : [id] :
        selectAll ? children.map(c => c.id) : [id]
    let someSelected = uwu.some(el => state.includes(el))
    let allSelected = uwu.every(el => state.includes(el)) && uwu.length
    return (
        <Grid container alignItems='center' justifyContent='space-between' style={containerStyle}
            onClick={() => owoOnClick()} onMouseEnter={() => onHover()} onMouseLeave={() => unHover()}>
            <div style={divStyle}>
                <CustomCheckbox isSelected={allSelected} indeterminate={someSelected && !allSelected} />
                {group}
            </div>
            <OpenCloseIcon open={open} setOpen={setOpen} />
        </Grid>
    )
}
const IndividualOptionsMultiple = ({ id, child, state, setState, input }) => {
    const [bgColor, setBgColor] = useState('#FFF')
    const onHover = () => setBgColor('#F2F2F2')
    const unHover = () => setBgColor('#FFF')
    const isSelected = state.includes(id)
    const containerStyle = {
        cursor: 'pointer', height: 42,
        paddingLeft: 30,
        backgroundColor: isSelected ? '#F0F8FF' : bgColor
    }
    return (
        child.toLowerCase().includes(input.toLowerCase()) ? <Grid container style={containerStyle} alignItems='center'
            onClick={() => setState(id)}
            // onClick={() => owoOnClick()}
            onMouseEnter={() => onHover()} onMouseLeave={() => unHover()}>
            <CustomCheckbox isSelected={isSelected} /> {child}
            {/* <Checkbox checked={isSelected} style={{ color: 'blue' }} /> {child} */}
        </Grid> : null
    )
}
// For one layer autocompletes with multiple selections
const CustomCheckbox = ({ isSelected, indeterminate }) => {
    const checkboxStyle = {
        height: 18, width: 18, borderRadius: 3, marginLeft: 10,
        backgroundColor: (isSelected || indeterminate) && '#000', border: '2px solid #000'
    }
    const checkmarkStyle = {
        position: 'relative', right: 12, bottom: 1,
        width: 6, height: 12,
        border: '2px solid white', borderWidth: '0 2px 2px 0',
        transform: 'rotate(45deg)', WebkitTransform: 'rotate(45deg)', msTransform: 'rotate(45deg)',
        visibility: !isSelected && 'hidden'
    }
    const indeterminatemarkStyle = {
        position: 'relative', right: 20,
        width: 10, height: 1,
        border: '1px solid white', borderWidth: 1,
        visibility: !indeterminate && 'hidden'
    }
    return <>
        <span style={checkboxStyle} />
        <span style={checkmarkStyle} />
        <span style={indeterminatemarkStyle} />
    </>
}
const OptionForMultiple = ({ option, name, isSelected, onChangeStateMultiple }) => {
    const [color, setColor] = useState('#FFF')
    const containerStyle = {
        height: 52, cursor: 'pointer', display: 'flex', alignItems: 'center',
        backgroundColor: isSelected ? '#F0F8FF' : color
    }
    return <div style={containerStyle} onClick={() => onChangeStateMultiple(option)}
        onMouseEnter={() => setColor('#F2F2F2')} onMouseLeave={() => setColor('#FFF')}>
        <CustomCheckbox isSelected={isSelected} />
        {name}
    </div>
}
const MultipleItems = ({ state, dimensions, passedRef, elementWidth, options, onChangeStateMultiple, placeholder }) => {
    const { width } = dimensions
    const flexStyle = { display: 'flex' }
    const itemsInMultipleDivStyle = {
        paddingLeft: 10, display: 'flex', overflow: 'hidden',
        minWidth: 0, maxWidth: width - 250
    }
    return (
        <div style={flexStyle}>
            <div style={itemsInMultipleDivStyle} ref={passedRef}>
                {state.map((s, i) => <ItemInMultiple key={i} id={s} options={options} onChangeStateMultiple={onChangeStateMultiple} />)}
            </div>
            {elementWidth >= width - 250 && <LightTooltip rollover={1} topic={0} hide={0}
                title={<BehindItems state={state} placeholder={placeholder} options={options} />}>
                <div style={{ paddingLeft: 10 }}>...</div>
            </LightTooltip>}
        </div>
    )
}
const ItemInMultiple = ({ id, options, onChangeStateMultiple }) => {
    return (
        <Chip style={{ ...chipStyle, marginRight: 10, paddingLeft: 20 }} label={options.find(t => t.id === id)?.option || ''}
            onDelete={() => onChangeStateMultiple(id)} deleteIcon={<CancelIcon style={{ color: '#FFF', position: 'absolute', left: 10 }} />} />
    )
}
const behindItemDivStyle = { minWidth: 150 }
const BehindItems = ({ state, placeholder, options }) => {
    return (
        <div style={behindItemDivStyle}>
            <NunitoText value={placeholder.replace('*', '')} fontSize={18} color='#000' />
            {state.map((s, i) => (
                <Grid container key={i} justifyContent='center'>
                    <NunitoText value={options.find(t => t.id === s)?.option} fontSize={16} />
                </Grid>
            ))}
        </div>
    )
}
export default function ReusableAutocompleteWithID({ type, width, height = 60, bgColor, borderColor,
    marginLeft, marginRight, marginTop, marginBottom,
    placeholder, state, setState, required, readOnly, grayedOut, disabled, errorFunction,
    count, head, target,
    // For ReusableAutocompleteWithID
    options, freeSolo, noClear, nested, allowSelectHead, selectAll, hide, multiple }) {
    const [input, setInput] = useState('')
    const [open, setOpen] = useState(false)
    const [actualOptions, setActualOptions] = useState([])
    const [elementWidth, setElementWidth] = useState(0)
    const selfRef = useRef()
    const passedRef = useRef()
    const dimensions = useElementDimensions(selfRef)
    const expand = () => !readOnly && setOpen(true)
    const collapse = () => !readOnly && setOpen(false)
    const toggle = () => !readOnly && setOpen(!open)
    const checkError = () => errorFunction && errorFunction(state)
    let defaultBorder = borderColor ? `1px solid ${borderColor}` : bgColor ? `1px solid ${bgColor}` : 'none'
    let border = (checkError() || (required && (multiple ? !state.length : state === ''))) ? '1px solid #E83D4D' : defaultBorder
    const searchIconStyle = {
        fontSize: 30,
        color: 'rgb(112, 112, 112, 0.5)',
        marginLeft: 12
    }
    const withLabelDivStyle = {
        display: 'flex', flexDirection: 'column', alignItems: 'flex-start',
        marginLeft, marginRight, marginTop, marginBottom, width,
        visibility: hide && 'hidden'
    }
    const autoCompleteMargin = type === 'withLabel' ? {} : { marginLeft, marginRight, marginTop, marginBottom }
    const autoCompleteStyle = {
        ...autoCompleteMargin, width, height, visibility: hide && 'hidden', pointerEvents: readOnly && 'none'
    }
    const textFieldStyle = {
        height,
        border, borderRadius: 12,
        backgroundColor: grayedOut ? '#707070' : bgColor
    }
    const inputStyle = {
        marginLeft: 20,
        color: grayedOut && '#FFF',
        cursor: readOnly && 'context-menu'
    }
    const btnStyle = {
        color: '#FFF', backgroundColor: '#144A94',
        borderRadius: '0 10px 10px 0',
        height: height - 2, width: 64,
        opacity: grayedOut && 0.5
    }
    const longInputStyle = {
        backgroundColor: '#F16774', color: '#FFF',
        marginInline: 30, borderRadius: 8, textAlign: 'center'
    }
    const iconStyle = { fontSize: 42 }
    const labelStyle = { color: '#000', fontSize: 16, fontFamily: 'Nunito', fontWeight: 600, fontStyle: 'italic' }
    // useEffect(() => onChangeState(state), [state])
    const onChangeState = (value, noSetTemp) => {
        // Manually close the autocomplete
        if (!multiple && value !== '' && nested) collapse()
        if (noSetTemp === undefined) {
            let output = ''
            output = actualOptions.find(o => o.id === value)?.option || input
            setInput(output)
        }
        if (count !== undefined && head !== undefined && target !== undefined) {
            // For redux autocompletes
            return setState(count, head, target, value)
        }
        if (count !== undefined && target !== undefined) {
            // For states in an array of objects
            return setState(count, target, value)
        }
        // For states in an array only, object only or by itself
        if (count !== undefined) setState(count, value)
        else if (target !== undefined) setState(target, value)
        else setState(value)
    }
    const onChangeInput = (e, value) => {
        // NOTE: Because for some reason, when using nested single select, it will automatically blur 
        // and change to option back to default if I don't explicitly state that 
        // this functions ONLY OCCURS ON CHANGE
        if (e && e.type === 'change') {
            setInput(value)
            // if (actualOptions.find(o => o.option === value)) {
            //     onChangeState(actualOptions.find(o => o.option === value)?.id)
            // }
            if (!noClear && value === '') onChangeState('')
        }
    }
    const onChangeStateMultiple = value => {
        if (readOnly) return
        let newState = [...state]
        if (value === 'all') {
            if (actualOptions.every(a => newState.includes(a.id))) newState = []
            else newState = actualOptions.map(a => a.id)
        } else {
            if (typeof value === 'object') {
                if (!value.length) newState = value
                else if (value.every(el => newState.includes(el))) value.forEach(v => newState = newState.filter(f => f !== v))
                else {
                    value.forEach(v => {
                        if (!newState.find(n => n === v)) newState.push(v)
                    })
                    // value.forEach(v => newState.push(v))
                }
            } else {
                if (newState.includes(value)) newState = newState.filter(f => f !== value)
                else newState.push(value)
            }
        }
        onChangeState(newState, true)
    }
    const loading = open && actualOptions.length === 0 && options.length !== 0
    useEffect(() => {
        let active = true
        if (!loading) return undefined
        if (active) {
            if (options.length > 200) setTimeout(() => setActualOptions([...options]), [300])
            else setActualOptions([...options])
        }
        return () => active = false
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading])
    useEffect(() => {
        if (!open) setActualOptions([])
    }, [open])
    const autoCompleteAttributes = {
        ref: selfRef,
        value: state,
        options: actualOptions.map(o => o.id),
        open: open, onClose: () => collapse(), onOpen: () => expand(),
        getOptionLabel: option => options.find(o => o.id === option)?.option || '',
        disableClearable: true, forcePopupIcon: false, freeSolo, disabled,
        style: autoCompleteStyle, loading: loading
    }
    const textFieldAttributes = {
        variant: 'standard', placeholder
    }
    const textFieldInputProps = params => ({
        disableUnderline: true, style: textFieldStyle,
        startAdornment: <>
            {multiple && params.InputProps.startAdornment}
            <SearchIcon style={searchIconStyle} />
        </>,
        endAdornment: <>
            {loading ? <CircularProgress disableShrink style={{ color: '#000' }} size={20} /> : null}
            {multiple && <IconButton onClick={() => onChangeStateMultiple([])} disabled={readOnly}>
                <ClearIcon style={{ backgroundColor: grayedOut ? '#707070' : bgColor }} />
            </IconButton>}
            <IconButton onClick={() => toggle()} style={btnStyle}>
                {open ? <ExpandLessIcon style={iconStyle} /> : <ExpandMoreIcon style={iconStyle} />}
            </IconButton>
        </>
    })
    if (multiple) {
        autoCompleteAttributes.multiple = true
        autoCompleteAttributes.renderOption = (object, option) => {
            // let allSelected = actualOptions.every(a => state.includes(a.id))
            // let someSelected = !actualOptions.every(a => state.includes(a.id)) && actualOptions.some(a => state.includes(a.id))
            return <Fragment key={option}>
                {/* {object.id.slice(-2) === '-0' && <Grid container alignItems='center' style={containerStyle(color, allSelected)} onClick={() => onChangeStateMultiple('all')}
                    onMouseEnter={() => setColor('#F2F2F2')} onMouseLeave={() => setColor('#FFF')}>
                    <Checkbox indeterminate={someSelected} checked={allSelected} style={{ color: '#000' }} />All
                </Grid>} */}
                <OptionForMultiple option={option} name={options.find(t => t.id === option)?.option}
                    isSelected={state.includes(option)} onChangeStateMultiple={onChangeStateMultiple} />
            </Fragment >
        }
        autoCompleteAttributes.renderTags = () => <MultipleItems state={state} dimensions={dimensions} passedRef={passedRef}
            elementWidth={elementWidth} options={OptionsForNested()} onChangeStateMultiple={onChangeStateMultiple}
            placeholder={placeholder} />
        autoCompleteAttributes.disableCloseOnSelect = true
    } else {
        autoCompleteAttributes.onInputChange = (e, n) => onChangeInput(e, n)
        autoCompleteAttributes.selectOnFocus = true
        autoCompleteAttributes.onChange = (e, n) => onChangeState(n)
        // if (inputValue) autoCompleteAttributes.inputValue = inputValue
        // else {
        if (freeSolo) autoCompleteAttributes.inputValue = input
        // }
    }
    if (nested) {
        const filteredNestedResult = (options, value) => {
            return options.filter(o => o.option.toLowerCase().includes(value.toLowerCase()) ||
                o.children.map(c => c.option.toLowerCase()).some(el => el.includes(value.toLowerCase())))
        }
        autoCompleteAttributes.filterOptions = (options, state) => filteredNestedResult(options, state.inputValue)
        if (multiple) {
            autoCompleteAttributes.options = actualOptions
            autoCompleteAttributes.getOptionLabel = option => option?.option || ''
            autoCompleteAttributes.renderOption = (props, option, s) => {
                return <GroupOptions key={option.id} id={option.id} group={option.option} children={option.children} state={state}
                    setState={onChangeStateMultiple} allowSelectHead={allowSelectHead} selectAll={selectAll} multiple
                    input={s.inputValue} />
            }
        } else {
            autoCompleteAttributes.options = actualOptions
            autoCompleteAttributes.getOptionLabel = option => OptionsForNested().find(o => o.id === option)?.option || ''
            autoCompleteAttributes.renderOption = (props, option, s) => {
                return <GroupOptions key={option.id} id={option.id} group={option.option} children={option.children} state={state}
                    setState={onChangeState} allowSelectHead={allowSelectHead} input={s.inputValue} />
            }
        }
    }
    const OptionsForNested = () => {
        if (nested) {
            let newArr = []
            options.forEach(o => {
                let { children, ...uwu } = o
                newArr.push(uwu)
                children.forEach(c => newArr.push(c))
            })
            return newArr
        }
        return options
    }
    useEffect(() => {
        if (passedRef.current) setElementWidth(passedRef.current.getBoundingClientRect().width)
    }, [state])
    switch (type) {
        case 'default':
            return (
                <Autocomplete {...autoCompleteAttributes} className='owo'
                    renderInput={params => <TextField {...params} {...textFieldAttributes}
                        InputProps={{
                            ...params.InputProps,
                            ...textFieldInputProps(params)
                        }}
                        inputProps={{ ...params.inputProps, style: inputStyle }}
                    />}
                />
            )
        case 'withLabel':
            return (
                <div style={withLabelDivStyle}>
                    <label style={labelStyle}>{placeholder}</label>
                    <Autocomplete {...autoCompleteAttributes} className='owo'
                        renderInput={params => <TextField {...params} {...textFieldAttributes}
                            InputProps={{
                                ...params.InputProps,
                                ...textFieldInputProps(params)
                            }}
                            inputProps={{ ...params.inputProps, style: inputStyle }}
                        />}
                    />
                </div>
            )
        case 'chip':
            return (<Autocomplete freeSolo {...autoCompleteAttributes}
                renderInput={params => <TextField {...params} {...textFieldAttributes}
                    InputProps={{
                        ...params.InputProps,
                        ...textFieldInputProps(params)
                    }}
                    inputProps={{
                        ...params.inputProps, style: options.map(o => o?.option).includes(input) ?
                            { ...inputStyle, ...longInputStyle } : inputStyle
                    }}
                />}
            />)
        default: return null
    }
}