import { getMethod } from './httpMethods'
// To populate options only
export const getCountries = () => {
   return new Promise((resolve, reject) => {
      getMethod('info/countrylist', '').then(d => resolve(d)).catch(e => reject(e))
   })
}
export const getRoles = () => {
   return new Promise((resolve, reject) => {
      getMethod('info/roles', '').then(d => resolve(d)).catch(e => reject(e))
   })
}
export const getLanguages = () => {
   return new Promise((resolve, reject) => {
      getMethod('info/languages', '').then(d => resolve(d)).catch(e => reject(e))
   })
}
// To actually display on the page, some of them is also used to populate options
// School
export const getSchools = (params, signal) => {
   return new Promise((resolve, reject) => {
      getMethod('school', params, signal).then(d => resolve(d)).catch(e => reject(e))
   })
}
// Organization
export const getOrganizations = (params, signal) => {
   return new Promise((resolve, reject) => {
      getMethod('organizations', params, signal).then(d => resolve(d)).catch(e => reject(e))
   })
}
// User
export const getUsers = (params, signal) => {
   return new Promise((resolve, reject) => {
      getMethod('user', params, signal).then(d => resolve(d)).catch(e => reject(e))
   })
}
// User Profile
export const getProfile = () => {
   return new Promise((resolve, reject) => {
      getMethod('user/profile', '').then(d => resolve(d)).catch(e => reject(e))
   })
}
// Participant
export const getParticipants = (params, signal) => {
   return new Promise((resolve, reject) => {
      getMethod('participant', params, signal).then(d => resolve(d)).catch(e => reject(e))
   })
}
// Domain and Tag
export const getDomains = (params, signal) => {
   return new Promise((resolve, reject) => {
      getMethod('tag', params, signal).then(d => resolve(d)).catch(e => reject(e))
   })
}
// Competition
export const getCompetitions = (params, signal) => {
   return new Promise((resolve, reject) => {
      getMethod('competition', params, signal).then(d => resolve(d)).catch(e => reject(e))
   })
}
export const getCompetitionsPartnerPending = (params, signal) => {
   return new Promise((resolve, reject) => {
      getMethod('competition/partners/pending', params, signal).then(d => resolve(d)).catch(e => reject(e))
   })
}
export const getMarkingPreparation = (params, signal) => {
   return new Promise((resolve, reject) => {
      getMethod('marking/preparation', params, signal).then(d => resolve(d)).catch(e => reject(e))
   })
}
export const getLevelCountries = (params, signal) => {
   return new Promise((resolve, reject) => {
      getMethod('info/levelCountyParticipantsList', params, signal).then(d => resolve(d)).catch(e => reject(e))
   })
}
// Competition Task
export const getDifficultyAndPoints = (params, signal) => {
   return new Promise((resolve, reject) => {
      getMethod('competition/difficultyandpoints', params, signal).then(d => resolve(d)).catch(e => reject(e))
   })
}
// Task
export const getTasks = (params, signal) => {
   return new Promise((resolve, reject) => {
      getMethod('tasks', params, signal).then(d => {resolve(d)
      }).catch(e => reject(e))
   })
}
// Difficulty Group
export const getDifficultyGroup = (params, signal) => {
   return new Promise((resolve, reject) => {
      getMethod('taskdifficultygroup', params, signal).then(d => resolve(d)).catch(e => reject(e))
   })
}
// Collection
export const getCollections = (params, signal) => {
   return new Promise((resolve, reject) => {
      getMethod('collection', params, signal).then(d => resolve(d)).catch(e => reject(e))
   })
}
//marking overview
export const getMarking = (params , signal)=>{
   return new Promise((resolve , reject)=>{
      getMethod('marking' , params , signal).then(d=> resolve(d)).catch(e=>reject(e))
   })
}
//editRoundmarking 
export const getEditRound = (params , signal)=>{
   return new Promise((resolve , reject)=>{
      getMethod('marking/edit' , params , signal).then(d=> resolve(d)).catch(e=>reject(e))
   })
}
export const getTask = (id) => getMethod('task', id)
export const getDifficulties = () => getMethod('difficulty', '')
export const getUser = (id) => getMethod('user', id).then(data => ({
   id: data.id,
   username: data.username,
   role: data.role,
   permissionOverridden: true,
   email: data.email,
   country: data.country,
   createdBy: data.createdBy,
   lastModifiedBy: data.lastModifiedBy,
   lastLogin: data.lastLogin,
   status: data.status,
   password: data.password
}))
export const getTranslations = () => {
   return new Promise((resolve, reject) => {
      getMethod('task', '')
         .then((data) => {
            if (data.length) {
               let a = Object.getOwnPropertyNames(data[0]).slice()
               let temp = a[3]
               a[3] = a[4]
               a[4] = temp
               resolve([a.filter(b => !['lastVerified', 'createdBy', 'displayOrder',
                  'answers', 'answerStructure', 'collections', 'competitions', 'recommendedDifficulty', 'tags'].includes(b)), data])
            } else {

            }
         })
   })
}