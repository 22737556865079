import React, { useState, useEffect, useMemo, useRef } from 'react'
import { Box, Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import { invalidNumber, invalidEmail } from '../../functions/general'
import { isEmpty, warningMessage, showWarningSwal } from '../../functions/alert'
import { addSchool } from '../../functions/postData'
import NunitoText from '../general/NunitoText'
import ReusableCheckbox from '../general/ReusableCheckbox'
import ReusableTextField from '../general/ReusableTextField'
import ReusableAutocompleteWithID from '../general/ReusableAutocompleteWithID'
import ReusableButton from '../general/ReusableButton'
import { isAdmin } from '../../functions/checkrole'
const boxStyle = { width: 1200, minHeight: 400, maxHeight: '90%' }
const titleContainerStyle = { marginTop: 20 }
const firstRowStyle = { marginTop: 20, marginBottom: 30 }
const rowStyle = { marginBottom: 30 }
const lastRowStyle = { marginBottom: 30, marginLeft: 33 }
const checkboxDivStyle = { display: 'flex', justifyContent: 'flex-end', marginTop: -42 }
const buttonsContainerStyle = { marginBottom: 30, paddingInline: 100 }
export default function AddSchoolModal({ setLoading, setOnAddSchool, afterAddingSchool }) {
    const isMounted = useRef(false)
    const countryOptions = useSelector(state => state.countryOptions)
    const [name, setName] = useState('')
    const [address, setAddress] = useState('')
    const [postalCode, setPostalCode] = useState('')
    const [province, setProvince] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [isTuitionCentre, setIsTuitionCentre] = useState(false)
    const [country, setCountry] = useState('')
    const [email, setEmail] = useState('')
    const controller = useMemo(() => new AbortController(), [])
    const signal = controller.signal
    const onSubmit = () => {
        let arr = [
            { name: 'School Name', state: name },
            { name: 'Phone Number', state: phoneNumber },
            { name: 'Email', state: email },
            { name: 'Province', state: province }
        ]
        if (isAdmin()) arr.splice(1, 0, { name: 'Country', state: country })
        if (isEmpty(arr.map(a => a.state))) return warningMessage(arr)
        let msg = ''
        // const invalidSchlName = value => !/^[\’\'\;\.\,\s\(\)\[\]\w-]*$/.test(value)
        // if (invalidSchlName(name)) msg += 'Invalid school name.<br>'
        if (invalidNumber(phoneNumber)) msg += 'Invalid phone number.<br>'
        if (invalidEmail(email)) msg += 'Invalid email<br>'
        if (msg) return showWarningSwal(msg)
        setLoading(true)
        let payload = {
            name: name.toUpperCase(), email, private: Number(isTuitionCentre),
            province: province.toUpperCase(), phone: phoneNumber
        }
        if (isAdmin()) payload.country_id = country
        if (address !== '') payload.address = address
        if (postalCode !== '') payload.postal = postalCode
        addSchool({ school: [payload] }, signal).then(d => {
            isMounted.current && setLoading(false)
            afterAddingSchool(() => setOnAddSchool(false), d)
        }).catch(e => console.log(e)).finally(() => isMounted.current && setLoading(false))
    }
    useEffect(() => {
        isMounted.current = true
        return () => {
            controller.abort()
            isMounted.current = false
        }
    }, [controller])
    return (
        <Box className='popUpModal horizontalScrollable' style={boxStyle}>
            <div>
                <Grid container justifyContent='center' alignItems='center' style={titleContainerStyle}>
                    <NunitoText value='ADD NEW SCHOOL' fontSize={30} fontWeight={600} color='#5E75C3' />
                </Grid>
                <div style={checkboxDivStyle}>
                    <ReusableCheckbox type='default' label='Tuition Centre' marginRight={100}
                        state={isTuitionCentre} setState={setIsTuitionCentre} fontSize={20} />
                </div>
                <Grid container justifyContent="space-evenly" style={firstRowStyle}>
                    <ReusableTextField type='withLabel' width={550} height={60} bgColor='#F2F2F2' placeholder='School Name*'
                        state={name} setState={setName} required />
                    <ReusableTextField type='withLabel' width={550} height={60} bgColor='#F2F2F2' placeholder='Address'
                        state={address} setState={setAddress} />
                </Grid>
                <Grid container justifyContent="space-evenly" style={rowStyle}>
                    <ReusableTextField type='withLabel' width={550} height={60} bgColor='#F2F2F2' placeholder='Postal Code'
                        state={postalCode} setState={setPostalCode} />
                    <ReusableTextField type='withLabel' width={550} height={60} bgColor='#F2F2F2' placeholder='Province*'
                        state={province} setState={setProvince} required />
                </Grid>
                <Grid container justifyContent="space-evenly" style={rowStyle}>
                    <ReusableTextField type='withLabel' width={550} height={60} bgColor='#F2F2F2' placeholder='Phone Number*'
                        state={phoneNumber} setState={setPhoneNumber} required />
                    {isAdmin() ? <ReusableAutocompleteWithID type='withLabel' width={550} height={60} placeholder='Country*'
                        state={country} setState={setCountry} btnBgColor='#144A94' borderColor='#707070' required
                        options={countryOptions.map(c => ({ id: c.id, option: c.display_name }))} /> :
                        <ReusableTextField type='withLabel' width={550} height={60} bgColor='#F2F2F2' placeholder='Email*'
                            state={email} setState={setEmail} required />}
                </Grid>
                {isAdmin() && <Grid container style={lastRowStyle}>
                    <ReusableTextField type='withLabel' width={550} height={60} bgColor='#F2F2F2' placeholder='Email*'
                        state={email} setState={setEmail} required />
                </Grid>}
            </div>
            <Grid container justifyContent='space-evenly' style={buttonsContainerStyle}>
                <ReusableButton text='Cancel' fontSize={22} height={60} width={300} bgColor='#8D8D8D'
                    onClick={() => setOnAddSchool(false)} />
                <ReusableButton text='Submit' fontSize={22} height={60} width={300} bgColor='#5E75C3'
                    onClick={() => onSubmit()} />
            </Grid>
            {/* {JSON.stringify({
                country_id: isAdmin() ? country : user.country_id, name, address, postal: postalCode, phone: phoneNumber,
                province, private: Number(isTuitionCentre), email
            })} */}
        </Box>
    )
}