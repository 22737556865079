import React, { useState, useEffect } from 'react'
import { Grid, ButtonGroup, Button } from '@mui/material'
import AnswerKey from './AnswerKey'
import TitleBar from '../general/TitleBar'
import AddPageWrapper from '../general/AddPageWrapper'
import ReusableButton from '../general/ReusableButton'
import { patchTaskAnswers } from '../../functions/patchData'
import { showNotification } from '../../functions/snackbar'
import { useSnackbar } from 'notistack'
import { afterEdit, isUnchanged, populateTask } from '../../functions/edit'
import { getTasks } from '../../functions/getData'

const typeOptions = [{ id: 'mcq', name: 'MCQ' }, { id: 'input', name: 'Input' }]
const structureOptions = [
    { id: 'default', name: 'Default' }, { id: 'group', name: 'Group' },
    { id: 'sequence', name: 'Sequence' }, { id: 'open', name: 'Open' }
]
const layoutOptions = [{ id: 'vertical', name: 'Vertical' }, { id: 'horizontal', name: 'Horizontal' }]
const sortingOptions = [{ id: 'fix', name: 'Fix Order' }, { id: 'random', name: 'Random' }]

const containerStyle = { paddingBottom: 20 }
const divStyle = { margin: 36, userSelect: 'none' }
const flexStyle = { display: 'flex' }
const buttonGroupWrapperStyle = {
    border: '1px solid', borderRadius: 5,
    backgroundColor: '#F3F0F4', width: 240, marginRight: 60
}
const buttonGroupStyle = { paddingInline: 8, paddingBottom: 10 }
const titleWrapperStyle = { display: 'flex', justifyContent: 'space-around' }
const titleUnderlineStyle = { borderBottom: '1px solid' }
const normalStyle = { textTransform: 'none' }
const selectedStyle = { textTransform: 'none', color: '#E83D4D' }
export default function AnswerSettings({ original, setOriginal, task, setTask }) {
    const [expand, setExpand] = useState(false)
    const { answer_type, answer_structure, answer_layout, answer_sorting } = task
    const [settingOptions, setSettingOptions] = useState([{ target: 'answer_type', options: typeOptions }])
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const getAnswerTypeId=(type)=>{
        let id =0;
        if(type==='mcq'){
            id = 1
        }else{
            id=2
        }
        return id
    }
    const getAnswerStracture = (type)=>{
        let id = 0;
        if(type ==='default'){
            id= 1;
        }else if(type==="group"){
            id = 2;
        }else if(type==="sequence"){
            id = 3;
        }else{
            id = 4;
        }
        return id
    }
    const getAnswerSortingId = (type)=>{
        let id = 0;
        if(type==='fix'){
            id = 1;
        }else{
            id = 2;
        }
        return id
    }
    const getAnswerLayoutId = (type)=>{
        let id = 0;
        if(type==="vertical"){
            id = 1;
        }else{
            id = 2;
        }
        return id
    }
    useEffect(() => {
        if (answer_type === 'mcq') {
            setSettingOptions([
                { target: 'answer_type', options: typeOptions }, { target: 'answer_layout', options: layoutOptions },
                { target: 'answer_structure', options: structureOptions.filter(s => s.name !== 'Open') },
                { target: 'answer_sorting', options: sortingOptions }
            ])
        } else if (answer_type === 'input') {
            setSettingOptions([
                { target: 'answer_type', options: typeOptions },
                { target: 'answer_structure', options: structureOptions.filter(s => s.name !== 'Sequence') }
            ])
        } else setSettingOptions([{ target: 'answer_type', options: typeOptions }])
    }, [answer_type])
    const updateTask = (identifier, value) => {
        let newTask = window.structuredClone(task)
        newTask[identifier] = value
        if (identifier === 'answer_type') {
            newTask.answer_structure = 'default'
            newTask.answer_layout = 'vertical'
            newTask.answer_sorting = 'fix'
        }
        setTask(newTask)
        
    }
    const update = ()=>{
        let labels = []
        let answers = []
        task!==undefined&&task.task_answers.map((el)=>{
            if(el.task_labels.length>0){
                   labels.push(el.task_labels[0].content)
            }else{
                    labels.push('')
                }
                if(task.answer_type_id===1){
                    if(el.correct){
                        answers.push('1')
                    }else{
                        answers.push(null)
                    }
                }else{
                    answers.push(el.answer)
                }
                console.log(el,"in loop")
            
        })
        let pay = {
            id : 410,
            answer_type: 2,
            answer_structure: 1,
            answer_sorting: 1,
            answer_layout: 1,
            labels: [
                "-"
            ],
            answers: [
                "0019"
            ]
        }
        let payload = {
            id:task.id,
            answer_type:getAnswerTypeId(task.answer_type),
            answer_structure:getAnswerStracture(task.answer_structure),
            answer_sorting:getAnswerSortingId(task.answer_sorting),
            answer_layout:getAnswerLayoutId(task.answer_layout),
            labels:labels,
            answers:answers

        }
       
       patchTaskAnswers(payload).then(d=>{
        if (d.status === 200) showNotification('success', d.message, enqueueSnackbar, closeSnackbar)
    }).catch(e => console.log(e))
      console.log(payload)
    }
    console.log(task)
    return (
        <Grid container style={containerStyle}>
            <TitleBar title='Answer Setting' state={expand} setState={setExpand} />
            {expand && <AddPageWrapper>
                <div style={divStyle}>
                    <div style={flexStyle}>
                        {settingOptions.map(({ target, options }, index) => (
                            <div key={index} style={buttonGroupWrapperStyle}>
                                <div style={titleWrapperStyle}>
                                    <h2 style={titleUnderlineStyle}>{target.charAt(7).toUpperCase() + target.slice(8)}</h2>
                                </div>
                                <ButtonGroup fullWidth variant="outlined" style={buttonGroupStyle}>
                                    {options.map(({ id, name }, i) => (
                                        <Button key={i} style={task[target] === id ? selectedStyle : normalStyle}
                                            onClick={() => updateTask(target, id)}>{name}</Button>
                                    ))}
                                </ButtonGroup>
                            </div>
                        ))}
                    </div>
                    {(answer_type === 'mcq' && answer_layout !== '' && answer_sorting !== '' && answer_structure !== '') ||
                        (answer_type === 'input' && answer_structure !== '')
                        ? <><AnswerKey original={original} setOriginal={setOriginal} task={task} setTask={setTask} /> <ReusableButton text='Save' bgColor='#5E75C3'  width={140} height={50} fontSize={16} marginTop={50} onClick={()=>update()}
                        /></>
                        : null}
                </div>
            </AddPageWrapper>}
        </Grid>
    )
}