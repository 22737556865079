import { patchMethod } from "./httpMethods"
// School
export const patchSchool = data => {
   return new Promise((resolve, reject) => {
      patchMethod('school', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
export const approveSchool = data => {
   return new Promise((resolve, reject) => {
      patchMethod('school/approve', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
export const rejectSchool = data => {
   return new Promise((resolve, reject) => {
      patchMethod('school/reject', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
export const restoreSchool = data => {
   return new Promise((resolve, reject) => {
      patchMethod('school/undelete', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
// Organization
export const patchOrganization = data => {
   return new Promise((resolve, reject) => {
      patchMethod('organizations', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
// User
export const patchUser = data => {
   return new Promise((resolve, reject) => {
      patchMethod('user', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
export const enableUser = data => {
   return new Promise((resolve, reject) => {
      patchMethod('user/undisable', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
export const disableUser = data => {
   return new Promise((resolve, reject) => {
      patchMethod('user/disable', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
// Participant
export const patchParticipant = data => {
   return new Promise((resolve, reject) => {
      patchMethod('participant', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
export const swapIndex = data => {
   return new Promise((resolve, reject) => {
      patchMethod('participant/swapIndex', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
// Domain and Tag
export const patchDomain = data => {
   return new Promise((resolve, reject) => {
      patchMethod('tag', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
export const approveDomain = data => {
   return new Promise((resolve, reject) => {
      patchMethod('tag/approve', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
// Competition
export const patchCompetition = data => {
   return new Promise((resolve, reject) => {
      patchMethod('competition', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
export const patchCompetitionOrganization = data => {
   return new Promise((resolve, reject) => {
      patchMethod('competition/organization', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
export const patchCompetitionRound = data => {
   return new Promise((resolve, reject) => {
      patchMethod('competition/rounds', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
export const patchCompetitionRoundAward = data => {
   return new Promise((resolve, reject) => {
      patchMethod('competition/awards', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
export const patchCompetitionOverallAward = data => {
   return new Promise((resolve, reject) => {
      patchMethod('competition/overall_awards', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
export const patchMarkingPreparation = (params, signal) => {
   return new Promise((resolve, reject) => {
      patchMethod('competition/marking/preparation', params, signal).then(d => resolve(d)).catch(e => reject(e))
   })
}
// TODO: Remove these 2 methods later on, legacy codes
export const approveCompetitionPartner = data => {
   return new Promise((resolve, reject) => {
      patchMethod('competition/partners/approve', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
export const rejectCompetitionPartner = data => {
   return new Promise((resolve, reject) => {
      patchMethod('competition/partners/reject', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
// Competition Task
export const patchDifficultyAndPoints = (data, signal) => {
   return new Promise((resolve, reject) => {
      patchMethod('competition/difficultyandpoints', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
// Task
export const patchTaskSettings = data => {
   return new Promise((resolve, reject) => {
      patchMethod('tasks/settings', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
export const patchTaskRecommendations = data => {
   return new Promise((resolve, reject) => {
      patchMethod('tasks/recommendation', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
export const patchTaskContent = data => {
   return new Promise((resolve, reject) => {
      patchMethod('tasks/content', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
export const patchTaskAnswers = data => {
   return new Promise((resolve, reject) => {
      patchMethod('tasks/answer', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
// Difficulty Group
export const patchDifficultyGroup = data => {
   return new Promise((resolve, reject) => {
      patchMethod('taskdifficultygroup', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
// Collection
export const patchCollectionSettings = data => {
   return new Promise((resolve, reject) => {
      patchMethod('collection/settings', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
export const patchCollectionRecommendations = data => {
   return new Promise((resolve, reject) => {
      patchMethod('collection/recommendations', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
export const patchCollectionSections = data => {
   return new Promise((resolve, reject) => {
      patchMethod('collection/sections', data).then(d => resolve(d)).catch(e => reject(e))
   })
}
export const patchEditMarkRound = data =>{
   return new Promise((resolve , reject) =>{
      patchMethod('marking/edit' , data).then(d=> resolve(d)).catch(e=> reject(e))
   })
}

export const patchMarkingOverview = data =>{
   return new Promise((resolve , reject)=>{
      patchMethod('marking',data).then(d=>resolve(d)).catch(e=>reject(e))
   })
}